import { API_ENDPOINTS } from "./apiEndpoints";


const ENVIRONMENTS = {
    apiUrl:process.env.REACT_APP_API_URL,
    apiUrlMerchant: process.env.REACT_APP_API_URL_MERCHANT
}


export const CONSTANTS = {
    env:ENVIRONMENTS,
    apiEndPoints:API_ENDPOINTS
}