import * as yup from "yup";



const contactUsSchema = yup.object().shape({
  firstName: yup.string().matches(/^[A-Za-z]+$/, "First name can only contain letters").required("First name is required"),
  lastName: yup.string().matches(/^[A-Za-z]+$/, "Last name can only contain letters").required("Last name is required"),
  email: yup.string().email("Invalid Email").required("Email is required"),
  phone: yup.string().required("Phone Number is required").matches(/^\d{10}$/, 'Phone number must be 10 digits and contain only numbers'),
  purpose: yup.string().required("Purpose is required"),
  companyName: yup.string().required("Company Name is required"),
  description: yup.string().required("Message is required"),
});


const newsletterSchema = yup.object().shape({
  email: yup.string().email("Invalid Email").required("Email is required")
});


export const Validations = {
  contactUs: contactUsSchema,
  newsletter: newsletterSchema
}