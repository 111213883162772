import React from "react";
import "./FrequentlyAsked.scss";
import { Collapse } from "antd";

const { Panel } = Collapse;

function FrequentlyAsked() {
  return (
    <div className="frequentlyAsked container">
      <h2>Frequently Asked Questions</h2>
      <Collapse bordered={false} defaultActiveKey={["1"]}>
        <Panel
          header={"1.  How secure is Triskel Wallet for my crypto assets?"}
          key="1"
        >
          <p>
            Triskel Wallet is incredibly resilient when it comes to security. As
            a non-custodial decentralized crypto wallet, it is armed with the
            latest security technology. This means your assets are locked down
            tight - no unauthorized access, no worries.
          </p>
        </Panel>
        <Panel header={"2. Is Triskel a non-custodial wallet?"} key="2">
          <p>
            Absolutely! Triskel is a non-custodial cryptocurrency wallet to the
            core. You hold all the power over your private keys and assets—no
            middlemen, just direct, hands-on management. The entire setup amps
            up your security and hands you total control over your assets,
            perfectly capturing the bold spirit of decentralization.
          </p>
        </Panel>
        <Panel
          header={"3.  Why choose Triskel’s non-custodial wallet?"}
          key="3"
        >
          <p>
            With Triskel Wallet, you get full control of your keys and assets.
            And its decentralized system, lets you dodge the risk of hacks
            associated with centralized storage. Triskel wallet operates in a
            completely transparent ecosystem, owing to the inherent nature of
            blockchain.
          </p>
        </Panel>
        <Panel
          header={"4. Do non-custodial wallets need KYC verification?"}
          key="4"
        >
          <p>
            Non-custodial wallets generally let you stay anonymous, but KYC
            kicks in with exchanges, fiat services, etc. For instance, Triskel
            Wallet goes an extra mile, mandating KYC for OTC trades to keep
            things secure and trustworthy.
          </p>
        </Panel>
        <Panel
          header={"5. How do custodial wallets differ from non-custodial ones?"}
          key="5"
        >
          <p>
            Custodial wallets put someone else in charge of your keys and
            assets. With a non-custodial decentralized wallet, you keep your
            private keys and control everything yourself. No one else gets a
            say—just you and your assets.
          </p>
        </Panel>
      </Collapse>
    </div>
  );
}

export default FrequentlyAsked;
