import PublicRoute from './Public/Public';
import PAGES from './Pages';
const routes = [
    {
        path: '/',
        element: <PublicRoute />,
        children: PAGES().PUBLIC_PAGES,
    },
];

export default routes;
