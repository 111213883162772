import React, { useEffect, useState } from "react";
import { CloseBtn, RightArrow } from "../SvgIcons/SvgIcons";
import { Link } from "react-router-dom";
import "./ApplicantHeader.scss";

function ApplicantHeader() {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add("info_header");
    }
    return () => {
      document.body.classList.remove("info_header");
    };
  }, [isVisible]);

  return (
    isVisible && (
      <div className="applicantHeader">
        <div className="applicantHeader_content">
          <div style={{ flexFlow: "wrap" }} className="applicantHeader_content">
            <p>
              Apply for Triskel Debit Card! Early Access to First 10000
              Applicants!
            </p>
            <Link to="https://tally.so/r/waEzdb" target="_blank">
              Join the Waitlist NOW!
              <span>
                <RightArrow />
              </span>
            </Link>
          </div>
          <button onClick={handleClose}>
            <CloseBtn />
          </button>
        </div>
      </div>
    )
  );
}

export default ApplicantHeader;
