import { createAsyncThunk } from "@reduxjs/toolkit";
import {CONSTANTS} from '../../../constants'

const {apiEndPoints, env}  = CONSTANTS
const API_URL = env.apiUrl

const contactUsApi = createAsyncThunk("contactUs/submit", async (data, {rejectWithValue}) => {
  try {
    const response = await fetch(API_URL+apiEndPoints.CONTACT_US, {
      method:"POST",
      headers:{"Content-Type": "application/json"},
      body:JSON.stringify(data)
      
    });
    if (!response.ok) {
      throw new Error("Failed");
    }
    return await response.json();  
  } catch (error) {
    return rejectWithValue(error); 
  } 
  
});

const subscribeNewsletter = createAsyncThunk("contactUs/subscribe" , async (data, {rejectWithValue}) => {
  try {
    const response = await fetch(API_URL+apiEndPoints.SUBSCRIBE_NEWSLETTER, {
      method:"POST",
      headers:{"Content-Type": "application/json"},
      body:JSON.stringify(data)
    })

    if (!response.ok) {
      throw new Error("Failed");
    }
    return await response.json();  
  } catch (error) {
    return rejectWithValue(error); 
  }
})

export const ContactUsActions = {
    contactUsApi,
    subscribeNewsletter
};
