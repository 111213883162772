import React, { useEffect } from "react";
import InputCustom from "../../Common/InputCustom/InputCustom.jsx";
import "./ContactUs.scss";
import { Row, Col } from "antd";
import { ButtonCustom } from "../../Common/ButtonCustom/ButtonCustom.jsx";
import CustomSelect from "../../Common/CustomSelect/CustomSelect.jsx";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Validations } from "../../validations/index.js";
import { useDispatch } from "react-redux";
import { ContactUsActions } from "../../redux/features/contactUs/contactUsActions.js";
import { message } from "antd";
import {
  startLoading,
  finishLoading,
} from "../../redux/features/loading/loadingSlice";
import ErrorMessage from "../../Common/ErrorMessage/ErrorMessage.jsx";
import PageTitle from "../../Common/PageTitle/PageTitle.jsx";

function ContactUs() {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(Validations.contactUs),
    mode: "all",
  });

  const dispatch = useDispatch();

  const options = [
    { value: "General query", label: "General query" },
    { value: "App related", label: "App related" },
    { value: "Request for Partnership", label: "Request for Partnership" },
  ];

  useEffect(() => {
    document.body.classList.add("contactUsBg");
    return () => {
      document.body.classList.remove("contactUsBg");
    };
  }, []);
  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };
  const handleSearch = (value) => {
    console.log("Search:", value);
  };

  const submitForm = async (values) => {
    try {
      dispatch(startLoading());
      let res = await dispatch(ContactUsActions.contactUsApi(values)).unwrap();
      console.log(res);
      message.success("Your query has been submitted successfully");
      reset({
        description: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        purpose: "",
      });

      window.location.reload();
    } catch (error) {
      console.log(error);
      const errorMessage = error.message || "An error occurred.";
      message.error(errorMessage);
    } finally {
      dispatch(finishLoading());
    }
  };
  return (
    <div className="contactUsMain">
      <PageTitle title="Contact Us" />
      <div className="contactUs">
        <h2>Contact Us</h2>
        <p>
          If you have any questions, please don’t hesitate to contact us. Our
          team is here to assist you and offer the support you require. Fill in
          the details in the form below, and our experts will get back to you
          shortly.
        </p>
        <form className="contactUs_form" onSubmit={handleSubmit(submitForm)}>
          <Row gutter={[18, 23]}>
            <Col xs={24} md={8}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <InputCustom
                    regularInput
                    label="First Name"
                    placeholder="Enter Name"
                    required
                    {...field}
                    customClass="asteriskLeft"
                  />
                )}
              />

              <ErrorMessage message={errors?.firstName?.message} />
            </Col>
            <Col xs={24} md={8}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <InputCustom
                    regularInput
                    label="Last Name"
                    placeholder="Enter Name"
                    required
                    customClass="asteriskLeft"
                    {...field}
                  />
                )}
              />
              <ErrorMessage message={errors?.lastName?.message} />
            </Col>
            <Col xs={24} md={8}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <InputCustom
                    regularInput
                    label="Email Address"
                    placeholder="Enter email"
                    required
                    customClass="asteriskLeft"
                    {...field}
                  />
                )}
              />

              <ErrorMessage message={errors?.email?.message} />
            </Col>
            <Col xs={24} md={8}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputCustom
                    regularInput
                    label="Phone Number"
                    customClass="asteriskLeft"
                    placeholder="Enter number"
                    required
                    {...field}
                  />
                )}
              />
              <ErrorMessage message={errors?.phone?.message} />
            </Col>
            <Col xs={24} md={8}>
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <InputCustom
                    regularInput
                    label="Company Name"
                    customClass="asteriskLeft"
                    placeholder="Enter Company Name"
                    required
                    {...field}
                  />
                )}
              />
              <ErrorMessage message={errors?.companyName?.message} />
            </Col>
            <Col xs={24} md={8}>
              <Controller
                name="purpose"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CustomSelect
                    required
                    label="Purpose"
                    options={options}
                    onSearch={handleSearch}
                    placeholder="Select"
                    customClass="purposeSelect"
                    {...field}
                  />
                )}
              />

              <ErrorMessage message={errors?.purpose?.message} />
            </Col>
            <Col xs={24}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <InputCustom
                    label="Message"
                    placeholder="Enter here"
                    textareaInput
                    customClass="asteriskLeft"
                    required
                    {...field}
                  />
                )}
              />

              <ErrorMessage message={errors?.description?.message} />
            </Col>
          </Row>
          <div className="contactUs_form_btn">
            <ButtonCustom
              greenbutton
              label="Submit"
              htmlType="submit"
              btnBorder={true}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
