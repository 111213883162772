import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./PolicyMain.scss";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import Header from "../../Common/Header/Header";

const PolicyMain = () => {
  const [scrollTarget, setScrollTarget] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const handleScroll = useCallback(
    (event, targetId) => {
      event.preventDefault();
      if (location.pathname === "/privacy-policy") {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
          setTimeout(() => {
            window.scrollBy(0, -120); // Scrolls 120px above the target element
          }, 900);
        }
      } else {
        setScrollTarget(targetId);
        navigate("/");
      }
    },
    [location.pathname, navigate]
  );

  useEffect(() => {
    if (scrollTarget && location.pathname === "/privacy-policy") {
      const targetElement = document.getElementById(scrollTarget);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
      setScrollTarget(null);
    }
  }, [scrollTarget, location.pathname]);

  return (
    <>
      <Header />
      <div className="policyLayout">
        <div className="container">
          <div className="policyLayout_main">
            <div class="sidebar">
              <NavLink
                onClick={(e) => handleScroll(e, "introduction")}
                class="active"
                to="/"
              >
                <span>1. Introduction</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "definitions")} to="/">
                <span>2. Definitions</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "eligibilityCriteria")}
                to="/"
              >
                <span>
                  3. Eligibility Criteria for Accessing Our Products and
                  Service
                </span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "collectionOfpersonalData")}
                to="/"
              >
                <span>4. Collection Of Personal Data</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "dataCollectionOverview")}
                to="/"
              >
                <span>5. How Data is Collected</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "thirdPartyService")}
                to="/"
              >
                <span>6. Third-Party Services</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) =>
                  handleScroll(e, "informationCollectionCardService")
                }
                to="/"
              >
                <span>7. Information Collection for Prepaid Card Service:</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "legalbasicForProcessingData")}
                to="/"
              >
                <span>8. Legal Basis for Processing Data</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "useofData")} to="/">
                <span>9. Use of Data  </span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "Consent")} to="/">
                <span>10. Consent </span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "rightsOfDataSubject")}
                to="/"
              >
                <span>11. Rights of Data Subjects  </span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "security ")} to="/">
                <span>12. Security </span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "dataRetention")} to="/">
                <span>13. Data Retention </span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "changesofprivacyPolicy")}
                to="/"
              >
                <span>14.  Changes to the Privacy Policy</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "complaint ")} to="/">
                <span>15.  If you have a complaint </span>
                <hr />
              </NavLink>
            </div>
            <div class="contentSide">
              <PrivacyPolicy />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PolicyMain;
