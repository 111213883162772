import { configureStore } from "@reduxjs/toolkit";
import contactUsSlice from "./features/contactUs/contactUsSlice";
import { combineReducers } from "redux";
import loadingSlice from "./features/loading/loadingSlice";


const appReducer = combineReducers({
  contactUs:contactUsSlice,
  loading:loadingSlice
})


export const store = configureStore({
  reducer:appReducer
});
