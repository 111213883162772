import "./PolicyMain.scss";
import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import TermofUse from "./TermUse/TermofUse";
import Header from "../../Common/Header/Header";

const TermuseMain = () => {
  const [scrollTarget, setScrollTarget] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const handleScroll = useCallback(
    (event, targetId) => {
      event.preventDefault();
      if (location.pathname === "/term-of-use") {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
          setTimeout(() => {
            window.scrollBy(0, -120); // Scrolls 120px above the target element
          }, 900);
        }
      } else {
        setScrollTarget(targetId);
        navigate("/");
      }
    },
    [location.pathname, navigate]
  );

  useEffect(() => {
    if (scrollTarget && location.pathname === "/term-of-use") {
      const targetElement = document.getElementById(scrollTarget);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
      setScrollTarget(null);
    }
  }, [scrollTarget, location.pathname]);

  return (
    <>
      <Header />
      <div className="policyLayout">
        <div className="container">
          <div className="policyLayout_main">
            <div class="sidebar">
              <NavLink
                onClick={(e) => handleScroll(e, "introduction")}
                class="active"
                to=""
              >
                <span>1. INTRODUCTION</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "definitions")} to="">
                <span>2. DEFINITIONS</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "triskelService")} to="">
                <span>3. TRISKEL SERVICES</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "specificTermforService")}
                to=""
              >
                <span>4. SPECIFIC TERMS FOR SERVICES</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "ReferalProgram")} to="">
                <span>5. REFERRAL PROGRAM</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "eligibilityToUseService")}
                to=""
              >
                <span>6. ELIGIBILITY TO USE THE SERVICE</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "genralConditions")}
                to=""
              >
                <span>7. GENERAL CONDITIONS</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "prohabitedUser")} to="">
                <span>8. PROHIBITED USE</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "personalInformation")}
                to=""
              >
                <span>9. PERSONAL INFORMATION</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "riskDisclousure")}
                to=""
              >
                <span>10. RISK DISCLOSURE</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "Disclaimer")} to="">
                <span>11. DISCLAIMERS</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "limitionOflibility")}
                to=""
              >
                <span>12. LIMITATION OF LIABILITY</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "indermnification")}
                to=""
              >
                <span>13. INDEMNIFICATION</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "restrictedJurisdictions")}
                to=""
              >
                <span>14. RESTRICTED JURISDICTIONS</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "copyRightIntellectual")}
                to=""
              >
                <span>15. COPYRIGHTS AND INTELLECTUAL PROPERTY</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "licenceRestrictions")}
                class="active"
                to=""
              >
                <span>16. LICENSE & RESTRICTIONS</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "thirdPartyService")}
                to=""
              >
                <span>17. THIRD-PARTY SERVICES</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "discontinuamceService")}
                to=""
              >
                <span>18. DISCONTINUANCE OF SERVICES</span>
                <hr />
              </NavLink>
              <NavLink
                onClick={(e) => handleScroll(e, "warrantyDisclimer")}
                to=""
              >
                <span>19. WARRANTY DISCLAIMER</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "transitionFees")} to="">
                <span>20. TRANSACTION FEES</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "taxMatter")} to="">
                <span>21. TAX MATTERS</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "forceMajeure")} to="">
                <span>22. FORCE MAJEURE</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "termination")} to="">
                <span>23. TERMINATION</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "termOrservice")} to="">
                <span>24. UPDATES TO THE TERMS OR SERVICE</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "errorOmission")} to="">
                <span>25. ERRORS, INACCURACIES AND OMISSIONS</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "governingLaw")} to="">
                <span>26. GOVERNING LAW AND DISPUTES</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "miscellaneous")} to="">
                <span>27. MISCELLANEOUS</span>
                <hr />
              </NavLink>
              <NavLink onClick={(e) => handleScroll(e, "contactInfo")} to="">
                <span>28. CONTACT INFORMATION</span>
                <hr />
              </NavLink>
            </div>
            <div class="contentSide">
              <TermofUse />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermuseMain;
