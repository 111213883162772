import { createSlice } from "@reduxjs/toolkit";
import { ContactUsActions } from "./contactUsActions";

const initialState = {
  data: null,
};

export const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ContactUsActions.contactUsApi.fulfilled, (state, action) => {
        state.data = action.payload;
      })

      .addCase(ContactUsActions.contactUsApi.rejected, (state, action) => {
        state.data = null
      });
  },
});


export default contactUsSlice.reducer;
