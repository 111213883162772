import React from "react";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";

const CustomDropdown = ({
  placement,
  overlayClassName,
  items,
  title = "Download",
}) => {
  const handleMenuClick = (e) => {
    console.log("Clicked on menu item:", e.key);
    // Perform actions based on the clicked menu item
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {items.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      placement={placement}
      overlay={menu}
      overlayClassName={overlayClassName}
    >
      <span style={{ cursor: "pointer" }}>
        {title} <DownOutlined />
      </span>
    </Dropdown>
  );
};

export default CustomDropdown;
