import React from "react";
import "../PolicyMain.scss";
import PageTitle from "../../../Common/PageTitle/PageTitle";

const points = [
  <React.Fragment>
    This Privacy Policy applies to Triskel Technologies International S.A., an
    International Sociedad Anónima formed and registered in Panama, (hereinafter
    collectively referred to as 'Triskel,' 'Company,' 'we,' 'us,' or 'our').,
    <React.Fragment>
      This Privacy Policy describes the policies and procedures of Triskel
      pertaining to the collection, use, and disclosure of user’s information.
      This includes but is not limited to the Triskel website, all its
      subdomains, and associated applications the{" "}
      <strong className="strong">"App"</strong>, together with the website{" "}
      <a
        style={{ color: "#CFFE25" }}
        href="https://www.triskel-wallet.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.triskel-wallet.com/
      </a>{" "}
      (the <strong className="strong">"Website"</strong>), collectively referred
      to as the
      <strong className="strong"> "Platform .</strong>
    </React.Fragment>
  </React.Fragment>,

  "Triskel is a non-custodial decentralized wallet, that aims for greater web3 adoption and establishment of a DeFi economy, where billions of people can access a wide range of financial opportunities and related services.",
  <React.Fragment>
    We utilize{" "}
    <a
      style={{ color: "#CFFE25" }}
      href="https://web3auth.io/index.html"
      target="_blank"
      rel="noopener noreferrer"
    >
      https://web3auth.io/index.html{" "}
    </a>{" "}
    <strong className="strong">(Web3auth)</strong> for non-custodial wallet
    creation on our platform. Users maintain custody of their wallets by linking
    them to their social login accounts. Certain third-party API providers may
    require you to provide them with Personal Data, in order to use their
    services through the Triskel Wallet. We prioritize security by deliberately
    not having access to your private keys and secret phrases.
  </React.Fragment>,

  "At Triskel, we empower users with unparalleled control over their digital assets, fostering financial autonomy and security in a rapidly evolving landscape. Leveraging blockchain technology, we ensure fully secure transactions without storing sensitive data, setting a new standard for privacy and reliability. Our platform facilitates seamless trade between fiat and cryptocurrencies, integrating decentralized technologies with traditional banking practices for a dynamic hybrid ecosystem. With global acceptance through the MasterCard Network, Triskel supports transactions in both digital and physical marketplaces. Whether you're managing non-custodial digital assets, exploring comprehensive financial services, or benefiting from advanced security measures, Triskel provides a user-friendly interface and robust features designed to meet the diverse needs of all users, from novices to seasoned investors.",
  "We believe that privacy is a fundamental right, and we are committed to protecting the privacy and security of our users’ personal information. Triskel is committed to protecting the privacy of our clients, customers, and website visitors. As such, we have implemented policies and procedures to ensure that we comply with the European Union - General Data Protection Regulation (EU-GDPR).",
  "We understand that your personal data is important to you, and we take our responsibility to protect it seriously. We are committed to being transparent about our data processing practices, and we strive to provide clear and concise information to help you understand how we use your personal data.",
  "This Privacy Policy outlines our commitment to privacy, including the types of information we collect, how we use the information, and the steps we take to protect user privacy.",
  "By using our platform, you consent to the practices outlined in this Privacy Policy.",
];

const terms = [
  {
    term: <>"App"</>,
    definition:
      "signifies the mobile application accessible via the Website or listed on App Store or Google Play store",
  },
  {
    term: <>"Consent"</>,
    definition:
      "refers to any freely given, specific, informed, and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.",
  },
  {
    term: <>"Data Subject"</>,
    definition:
      "refers to the natural person to whom the Personal Data refers, using the OTC, STO and Physical card service on the Platform.",
  },
  {
    term: <> "Personal Information"</>,
    definition:
      " or “Personal Data” or “your data” refers to any information relating to you, as an identified or identifiable natural person, including your name, an identification number, location data, or an online identifier or to one or more factors specific to the physical, economic, cultural or social identity of you as a natural person.",
  },
  {
    term: <>"Service"</>,
    definition:
      "refers to the service provided by Triskel as described in the Terms of Use (if available) and on the Platform.",
  },
  {
    term: <>"Sensitive Personal Data or Information"</>,
    definition:
      "refers to & encompasses distinct categories of personal details about an individual, which may include: (i) any data provided to us in connection with the Service; (ii) data received for processing, whether stored, managed under a legal contract, or otherwise; (iii) biometric identifiers; (iv) financial details, including bank accounts, credit and debit card details, and other payment data; (v) medical history and records; (vi) passwords; (vii) information related to an individual's physical, physiological, and mental health; (viii) sexual orientation.",
  },
  {
    // term: <> "User"</>,
    definition:
      '  "User" refers to the individual using Triskel who, unless otherwise specified, coincides with the Data Subject.',
  },
];

const personalDataDefinition = [
  "Personal data refers to any information relating to an identified or identifiable natural person, directly or indirectly. This can include a person's name, identification number, location data, online identifier, physical, physiological, genetic, mental, economic, cultural or social identity, or any other factor that is specific to the individual.",
  // "An identified natural person is someone who can be identified, directly or indirectly, by reference to an identifier such as a name, identification number, location data, online identifier, or other factors that are specific to that person.",
  "An identified natural person is someone who can be identified, directly or indirectly, by reference to an identifier such as a name, identification number, location data, online identifier, or other factors that are specific to that person. An identifiable natural person is someone who can be identified, directly or indirectly, in particular by reference to an identifier or to one or more factors specific to their physical, physiological, genetic, mental, economic, cultural or social identity.",
  "Personal data can include a wide range of information, such as an individual's name, address, email address, phone number, date of birth, social security number, financial information, health information, and biometric data. It can also include information about an individual's preferences, behavior, and interactions with others.",
];

const personalDataCollectionSecond = [
  "We do not collect any personal information or data from its users for creating a wallet on Triskel. However, Triskel collects and processes various types of personal data about you when you use the OTC, STO, Prepaid Card Services on the Platform. The types of personal data we may collect and the purposes for which we use them are described below.",
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc" }}>
      {" "}
      <strong>&#8226;</strong> Information you provide to us directly :
    </span>{" "}
    When you access the Over the Counter (OTC), Security Token Offering &
    Prepaid Card Services on the Platform, you may provide us with personal data
    such as your name, email address, phone number, date of birth, and other
    contact or profile information. We may use this information to verify your
    identity, communicate with you about the Services, and personalize your
    experience.
  </React.Fragment>,
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc" }}>
      {" "}
      <strong>&#8226;</strong> Information we collect automatically :
    </span>{" "}
    We may also collect certain information automatically when you use our
    Services, such as your IP address, device type, browser type, operating
    system, and other technical information. We may use this information to
    improve the performance and functionality of our Services, analyze usage
    trends, and troubleshoot issues.
  </React.Fragment>,
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc" }}>
      {" "}
      <strong>&#8226;</strong> Usage information :
    </span>{" "}
    We may collect information about how you use our Services, such as the
    features you use, the pages you visit, the content you view, and the actions
    you take. We may use this information to personalize your experience,
    understand your preferences, and improve our Services.
  </React.Fragment>,
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc" }}>
      <strong>&#8226;</strong> Location data:
    </span>
    We may collect and process information about your location if you give us
    permission to do so. We may use this information to provide you with
    location-based services, such as personalized content and advertising.
  </React.Fragment>,
  "WE OFFER A SECURE ENVIRONMENT FOR CONDUCTING SENSITIVE DATA TRANSACTIONS AS THERE IS THE ADOPTION OF DECENTRALISED ARCHITECTURE AND STRONG SECURITY FEATURES.",
  "WE WILL ONLY USE YOUR PERSONAL DATA FOR THE PURPOSES FOR WHICH WE COLLECTED IT, UNLESS WE REASONABLY CONSIDER THAT WE NEED TO USE IT FOR ANOTHER REASON THAT IS COMPATIBLE WITH THE ORIGINAL PURPOSE. IF WE NEED TO USE YOUR PERSONAL DATA FOR AN UNRELATED PURPOSE, WE WILL NOTIFY YOU AND EXPLAIN THE LEGAL BASIS WHICH ALLOWS US TO DO SO.",
];

const automaticallyCollectedInfo = [
  "Information collected automatically refers to the data that is collected by the website or application without the user explicitly providing it. This data is collected through various means such as cookies, log files, web beacons, and other tracking technologies. This information is used to improve the user experience, provide relevant content, and analyze trends.",
  "Common examples of information collected automatically include the user's IP address, browser type and version, operating system, device type, screen resolution, language preference, and the website or application that referred the user to the website or application. This information is collected by the website or application and stored in log files.",
  "Log files contain information about the user's activity on the website or application, such as the pages viewed, the time spent on each page, the links clicked, and the date and time of each visit. This information is used to analyze trends, monitor the performance of the website or application, and improve the user experience.",
  "Cookies are small text files that are stored on the user's device when they visit a website or application. Cookies contain information about the user's activity on the website or application, such as their preferences and settings. This information is used to personalize the user experience, provide relevant content, and analyze trends.",
  "Web beacons are small images that are embedded in web pages and emails. They are used to track the user's activity on the website or application, such as the pages viewed and the links clicked. This information is used to analyze trends and improve the user experience.",
  "Overall, the information collected automatically is used to improve the user experience, provide relevant content, and analyze trends. It is important to note that this information is collected in a non-intrusive manner and is used solely for the purposes of improving the website or application.",
];

const dataCollectionOverview = [
  "We do not collect any personal information or data from its users for creating a wallet on Triskel. Triskel uses Web3auth for creation of wallet login for the users. Users maintain custody of the wallets by using their social login accounts and Triskel does not collect any personal data from the User for login. Certain Personal Data is collected automatically from the User through their use of the Platform or from third party sources as detailed below. We only store and process data collected as per the automatically collected or process any user data.",
  "Triskel demonstrates a commitment to user privacy and data protection as follows -",
];

const privacyCommitments = [
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> No Personal Information Collection for login or
      use of Wallet :
    </span>
    By not collecting any personal information or data from users for creation &
    use of wallet, we eliminate the risk of potential data breaches or misuse of
    sensitive information.
  </React.Fragment>,
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> Anonymity and Privacy :
    </span>
    By not requesting personal information during registration or use, Triskel
    fosters a level of anonymity for users.
  </React.Fragment>,
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> Data Protection :
    </span>
    Triskel's decision not to store or process user data reduces the attack
    surface for potential cyber threats. With no user data to target, malicious
    actors are less likely to attempt data breaches or cyberattacks on the
    platform .
  </React.Fragment>,
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> User Trust :
    </span>
    By prioritizing user privacy and demonstrating a commitment to not
    collecting personal information, Triskel establishes a relationship of trust
    with its users. Users are more likely to feel confident in using the App,
    knowing that their privacy is respected.
  </React.Fragment>,
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> Transparency and Communication :
    </span>
    It's essential for Triskel to communicate its privacy practices clearly to
    users. A Privacy Policy should be made available to users, explaining the
    platform's data handling approach and the reasons for not collecting
    personal information.
  </React.Fragment>,
  <React.Fragment>
    <strong style={{ color: "#D1D1D1", fontWeight: "400" }}>
      We collect Data in the following in either of the following ways:
    </strong>
  </React.Fragment>,
];

const dataCollectionMethods = [
  {
    title: "a) Direct Collection",
    description:
      "We may collect personal data directly from our users when they access OTC, STO & Prepaid Card Service on the Platform, fill out a contact form, or engage with us through our website, social media, or other channels. The personal data we collect may include name, email address, phone number, address, job title, company name, and other information relevant to the service we provide.",
  },
  {
    title: "b) Indirect Collection",
    description:
      "We may also collect personal data indirectly through our partners and service providers, such as when we use their services to process payments, analyze user behavior, or manage our marketing campaigns. The personal data we collect indirectly may include IP address, location data, browser type, device type, and other information relevant to the service we provide.",
  },
  {
    title: "c) Collection from Third Parties",
    description: (
      <>
        We may collect personal data from publicly available sources, such as
        social media platforms, to enrich our marketing database or to verify
        the accuracy of the data we already have. The personal data we collect
        from third parties may include name, email address, phone number,
        address, job title, company name, and other information relevant to the
        service we provide.
        <br /> <br />
        WE ONLY COLLECT DATA THAT IS NECESSARY FOR THE PURPOSES FOR WHICH IT IS
        PROCESSED, AND WE TAKE APPROPRIATE MEASURES TO ENSURE THAT THE DATA WE
        COLLECT IS ACCURATE, UP-TO-DATE, AND RELEVANT TO THE SERVICE WE PROVIDE.
        WE ALSO ENSURE THAT ALL DATA IS COLLECTED AND PROCESSED IN COMPLIANCE
        WITH APPLICABLE DATA PROTECTION LAWS"
      </>
    ),
  },
];

const thirdPartyIntegrationsOverview = [
  "Triskel Wallet may provide information about third-party products, services, activities or events, or may allow third parties to make their content and information available on or through the services (collectively, 'Third-Party Integrations').",
  "Your dealings or correspondence with third parties and your use of or interaction with any Third-Party Content are solely between you and the third party.",
];

const kycVerificationDetails = [
  <>
    For Triskel's Over the Counter (OTC) and Security Token Offering (STO)
    services, KYC (Know Your Customer) verification is facilitated through
    Ondato, a KYC vendor.", When users access these services, they are directed
    to the Ondato platform to complete the KYC process. This involves filling
    out the designated KYC form on Ondato's platform to authenticate and
    validate their identity. Triskel utilizes Ondato's services to ensure
    compliance with regulatory requirements and to verify the authenticity of
    users engaging in OTC trades and participating in STO offerings.
  </>,
];

const thirdPartyPrivacyPractices = [
  "It is essential to understand that this Privacy Policy specifically governs the data collection, use, and protection practices within the Platform. Any information provided or actions taken within the Platform are subject to the terms outlined in this Privacy Policy.",

  <>
    However, when users access links or interact with integrations that redirect
    them to external third-party services, Triskel no longer retains control
    over the privacy practices or content of those services. Each third-party
    service operates under its own privacy policy, terms of service, and data
    handling practices.
  </>,
  "Triskel strongly advises users to exercise caution and be well-informed about the privacy policies and practices of any third-party services they engage with through the Platform. Users bear the responsibility of reviewing and comprehending the privacy policies and terms of service of these external services to ensure their comfort with data collection, usage, and protection procedures.",
  "While Triskel strives to integrate with reputable and secure third-party services, it cannot guarantee the privacy practices of these external entities. Consequently, users should exercise prudence and take appropriate precautions while interacting with third-party services linked to or integrated within the App.",
];

const prepaidCardApplication = [
  "To utilize our Prepaid Card Services, users must complete the card application process on our platform. During this process, users are required to provide the following details:",
  <React.Fragment>
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong>
    </span>
    First Name
  </React.Fragment>,
  <React.Fragment>
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong>
    </span>
    Last Name
  </React.Fragment>,
  <React.Fragment>
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong>
    </span>
    Phone Number
  </React.Fragment>,
  <React.Fragment>
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong>
    </span>
    Email
  </React.Fragment>,
  "Upon submission of these details, users will proceed to payment for the card application fee. Following successful payment, users must complete the next step by filling out a KYC (Know Your Customer) form, which includes providing:",

  <React.Fragment>
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong>
    </span>
    Nationality
  </React.Fragment>,
  <React.Fragment>
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong>
    </span>
    Type of KYC document (Only Passport is acceptable)
  </React.Fragment>,
  <React.Fragment>
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong>
    </span>
    KYC document Number
  </React.Fragment>,
  <React.Fragment>
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong>
    </span>
    Uploaded images of the front and back sides of the passport
  </React.Fragment>,
  <React.Fragment>
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong>
    </span>
    Selfie with the Passport
  </React.Fragment>,
  <React.Fragment>
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong>
    </span>
    Picture of signature
  </React.Fragment>,
  <React.Fragment>
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong>
    </span>
    Emergency contact details
  </React.Fragment>,
  "The information collected through these steps is processed by us for the purpose of issuing Prepaid Cards to the user.",
];

const privacyAndSecurityNote = [
  "Please note that the privacy and security of your personal information are of utmost importance to us. We handle your data in accordance with our privacy policy to ensure compliance with applicable laws and regulations. ",
  // "We handle your data in accordance with our privacy policy to ensure compliance with applicable laws and regulations.",
];

const consentAndProcessing = [
  <React.Fragment key="consent">
    <h6>a) Consent</h6>
    <p>
      When individuals give their consent to the processing of their personal
      data, it means they have given their explicit and informed agreement to
      the use of their data for specific purposes. For instance, if Triskel
      intends to collect personal data for marketing purposes, it will obtain
      explicit consent from individuals before using their data for this
      purpose. Individuals have the right to withdraw their consent at any time.
    </p>
  </React.Fragment>,

  <React.Fragment key="contractual-necessity">
    <h6>b) Contractual necessity</h6>
    <p>
      Triskel may need to process personal data that is necessary for the
      performance of a contract with an individual, or to take steps at the
      request of the individual prior to entering into a contract. For instance,
      if Triskel provides an online service, it may need to process personal
      data to ensure the service is provided to the individual. This legal basis
      applies only to the extent necessary to perform the contract or take steps
      at the request of the individual.
    </p>
  </React.Fragment>,

  <React.Fragment key="legal-obligation">
    <h6>c) Legal obligation</h6>
    <p>
      Triskel may need to process personal data to comply with a legal
      obligation to which it is subject. For instance, if Triskel is required by
      law to retain certain personal data for a specified period, it will
      process that data for that purpose. This legal basis applies only to the
      extent necessary to comply with the legal obligation.
    </p>
  </React.Fragment>,

  <React.Fragment key="vital-interests">
    <h6>d) Vital interests</h6>
    <p>
      Triskel may process personal data when it is necessary to protect the
      vital interests of the data subject or another person. For instance, if
      there is a medical emergency involving an individual, Triskel may need to
      process personal data to provide medical assistance. This legal basis
      applies only to the extent necessary to protect the vital interests of the
      data subject or another person.
    </p>
  </React.Fragment>,

  <React.Fragment key="legitimate-interests">
    <h6>e) Legitimate interests</h6>
    <p>
      Triskel may process personal data when it is necessary for the legitimate
      interests of Triskel or a third party, except where such interests are
      overridden by the interests, rights, or freedoms of the data subject. For
      instance, if Triskel wants to process personal data for the purposes of
      fraud prevention, it will need to demonstrate that its legitimate interest
      in fraud prevention outweighs the privacy rights of the data subjects.
      This legal basis applies only to the extent necessary to achieve the
      legitimate interest and requires a balancing of interests test.
    </p>
  </React.Fragment>,

  <React.Fragment key="note">
    <p>
      IT IS IMPORTANT TO NOTE THAT TRISKEL WILL ONLY PROCESS PERSONAL DATA FOR
      THE PURPOSES FOR WHICH IT WAS COLLECTED, AND WILL NOT USE THE DATA FOR ANY
      OTHER PURPOSE WITHOUT OBTAINING ADDITIONAL CONSENT FROM THE DATA SUBJECT
      OR UNLESS PERMITTED BY LAW. ADDITIONALLY, IF TRISKEL INTENDS TO PROCESS
      PERSONAL DATA FOR A NEW PURPOSE THAT IS NOT COMPATIBLE WITH THE ORIGINAL
      PURPOSE, IT WILL PROVIDE DATA SUBJECTS WITH INFORMATION ABOUT THE NEW
      PURPOSE AND OBTAIN THEIR CONSENT IF NECESSARY.
    </p>
  </React.Fragment>,
];

const purposeOfDataProcessing = [
  <React.Fragment key="provide-services">
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> {/* This is a bullet point */}
    </span>
    To provide our services and products: We process personal data in order to
    provide our products and services to our users. This includes processing
    personal data for enabling users to access and use our OTC, STO, and
    Physical card services, providing customer support, and improving our
    products and services.
  </React.Fragment>,

  <React.Fragment key="communicate-with-users">
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> {/* This is a bullet point */}
    </span>
    To communicate with users: We may process personal data to communicate with
    our users, including responding to their requests, sending newsletters or
    promotional messages, and providing updates about our products and services.
  </React.Fragment>,

  <React.Fragment key="personalize-experience">
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> {/* This is a bullet point */}
    </span>
    To personalize user experience: We may process personal data to tailor our
    products and services to individual users' interests, preferences, and usage
    patterns. This includes analyzing data about user behavior, preferences, and
    interactions with our products and services.
  </React.Fragment>,

  <React.Fragment key="comply-with-legal-obligations">
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> {/* This is a bullet point */}
    </span>
    To comply with legal obligations: We may process personal data to comply
    with legal obligations, such as responding to legal requests or orders,
    preventing fraud or other illegal activities, and enforcing our terms of
    service.
  </React.Fragment>,

  <React.Fragment key="improve-products-services">
    <span style={{ listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> {/* This is a bullet point */}
    </span>
    To improve our products and services: We may process personal data for
    research and development purposes, including testing new features and
    functionality, analyzing usage patterns, and improving the quality and
    performance of our products and services.
  </React.Fragment>,
];

const sharingAndTransfers = [
  "We may share personal data with third-party service providers, such as payment processors or hosting providers, in order to provide our products and services. We may also share personal data with business partners or other third parties for marketing or advertising purposes, subject to the user's consent where required by law.",
  "We may transfer personal data to international countries outside the jurisdiction where we are based, including to countries that do not provide an adequate level of data protection. In such cases, we will ensure that appropriate safeguards are in place to protect personal data, such as using standard contractual clauses or relying on other legal mechanisms for data transfers.",
  "Users have the right to object to the processing of their personal data for direct marketing purposes, and can exercise their other data subject rights as provided for under GDPR. If you have any questions or concerns about our use of personal data, please contact us at the contact information provided.",
];

const consentRequirements = [
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> Freely given :
    </span>
    Consent must be voluntary and not obtained through coercion. Users should
    not feel compelled to consent in order to access a service, nor should
    consent be a contractual requirement.
  </React.Fragment>,
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> Specific :
    </span>
    Consent must be tied to a specific purpose for which it is sought. Users
    must be fully informed about how their data will be used and must explicitly
    agree to this purpose.
  </React.Fragment>,
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> Informed :
    </span>
    Consent requires clear and understandable information about the processing
    of personal data. This includes details such as who controls the data, the
    purposes of processing, and any third parties who may receive the data.
  </React.Fragment>,
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> Unambiguous :
    </span>
    Consent must be given through a clear affirmative action. Actions like
    pre-checked boxes or inactivity do not constitute valid consent.
  </React.Fragment>,
  <React.Fragment>
    <span style={{ color: "#CFFE25", listStyle: "disc", marginRight: "5px" }}>
      <strong>&#8226;</strong> Revocable :
    </span>
    Users must have the right to withdraw consent at any time, and withdrawing
    consent should be as easy as giving it initially.
  </React.Fragment>,
];

const consentObtainingProcess = [
  "We will obtain consent from users in a transparent and straightforward manner, ensuring it meets all the above requirements. We will provide a simple and accessible means for users to withdraw their consent, and we will promptly honor all requests to do so.",
  "By using our services, you consent to complying with the practices outlined in this policy. If you have any questions or wish to withdraw your consent, please contact us using the provided contact information.",
];

const userRights = [
  {
    title: "a) Right to Access",
    description:
      "Users using the OTC, STO, and Physical Card Service have the right to obtain confirmation of whether we are processing their personal data and to access a copy of that data. You can request details about the categories of data being processed, the purposes of processing, any recipients of the data, and how long the data will be retained. You also have the right to receive a copy of your data in a commonly used electronic format.",
  },
  {
    title: "b) Right to Rectification",
    description:
      "If you believe that any of your personal data processed by us is incorrect or incomplete, you can request that we rectify it. We will take reasonable steps to correct any inaccuracies and complete any incomplete data, considering the purposes for which it was collected.",
  },
  {
    title: "c) Right to Erasure",
    description:
      "Also known as the 'right to be forgotten,' you can request the deletion of your personal data in certain situations. This includes cases where the data is no longer needed for its original purpose, you withdraw your consent, or you object to its processing. However, there may be legal or other reasons why we need to retain your data, and we will inform you if this applies.",
  },
  {
    title: "d) Right to Restrict Processing",
    description:
      "In some circumstances, you may wish to limit how we process your personal data. You can request that we restrict the processing under certain conditions, such as when you dispute the accuracy of the data or object to its processing. We will continue to store your data but will not process it further without your consent, except for legal reasons or to protect someone else's rights.",
  },
  {
    title: "e) Right to Data Portability",
    description:
      "You have the right to receive a copy of your personal data in a structured, commonly used, and machine-readable format, and to transmit that data to another data controller. This right applies only to data you have provided to us, and only when processing is based on your consent or a contract.",
  },
  {
    title: "f) Right to Object",
    description:
      "You can object to the processing of your personal data in certain situations, such as for direct marketing or statistical purposes. We will stop processing your data unless we have compelling legitimate reasons that override your rights or the processing is necessary for legal purposes.",
  },
  {
    title: "g) Right to Withdraw Consent",
    description:
      "If we process your personal data based on your consent, you have the right to withdraw that consent at any time. This will not affect the legality of any processing done before you withdrew your consent.",
  },
];

const securityMeasures = [
  {
    category: "a) In App Security Measures",

    measures: [
      {
        title: (
          <React.Fragment>
            Triskel has implemented robust security features to protect your
            wallet and ensure the safety of your data: 
            <br />
            <span
              style={{
                color: "#CFFE25",
                listStyle: "disc",
                marginRight: "5px",
              }}
            >
              <strong>&#8226;</strong> Biometric Authentication :
            </span>
          </React.Fragment>
        ),
        description:
          "Users have the option to enable biometric authentication, such as facial recognition, providing convenient yet secure access to their wallet.",
      },
      {
        title: (
          <React.Fragment>
            <span
              style={{
                color: "#CFFE25",
                listStyle: "disc",
                marginRight: "5px",
              }}
            >
              <strong>&#8226;</strong> PIN Protection :
            </span>
          </React.Fragment>
        ),
        description:
          "Setting up a PIN is mandatory during the wallet onboarding process. This PIN adds an additional layer of security, ensuring that only authorized users can access the wallet.",
      },
      {
        title: (
          <React.Fragment>
            <span
              style={{
                color: "#CFFE25",
                listStyle: "disc",
                marginRight: "5px",
              }}
            >
              <strong>&#8226;</strong> Screen Lock Timeout :
            </span>
          </React.Fragment>
        ),
        description:
          "Users can configure the wallet to automatically lock after a specified period of inactivity. This feature enhances security by preventing unauthorized access when the device is left unattended.",
      },
    ],
  },
  {
    category: "b) Organizational Measures",

    measures: [
      {
        title: (
          <React.Fragment key="access-limitation">
            To safeguard personal data and ensure data protection, Triskel
            implements stringent organizational measures:
            <br />
            <span style={{ color: "#CFFE25", marginRight: "5px" }}>
              <strong>&#8226;</strong> {/* Bullet point */}
              Access Limitation:
            </span>
          </React.Fragment>
        ),
        description:
          "We restrict access to personal data to authorized employees, contractors, and third parties who require it for legitimate business purposes only.",
      },
      {
        title: (
          <React.Fragment key="training-awareness">
            <span style={{ color: "#CFFE25", marginRight: "5px" }}>
              <strong>&#8226;</strong> {/* Bullet point */}
              Training and Awareness:
            </span>
          </React.Fragment>
        ),
        description:
          "Regular training sessions are conducted for our employees on data protection and privacy practices to enhance awareness and compliance.",
      },
      {
        title: (
          <React.Fragment key="access-controls-password-policies">
            <span style={{ color: "#CFFE25", marginRight: "5px" }}>
              <strong>&#8226;</strong> {/* Bullet point */}
              Access Controls and Password Policies :
            </span>
          </React.Fragment>
        ),
        description:
          "We enforce strict access controls and maintain robust password policies to ensure that personal data remains accessible only to authorized personnel.",
      },
      {
        title: (
          <React.Fragment key="incident-response-procedures">
            <span style={{ color: "#CFFE25", marginRight: "5px" }}>
              <strong>&#8226;</strong> {/* Bullet point */}
              Incident Response Procedures :
            </span>
          </React.Fragment>
        ),
        description:
          "In the event of a data breach, we have established incident response procedures, including detection and response, investigation, notification, mitigation, and follow-up.",
      },
      {
        title: (
          <React.Fragment key="incident-response-procedures">
            <span style={{ color: "#CFFE25", marginRight: "5px" }}>
              <strong>&#8226;</strong> {/* Bullet point */}
              Detection and Response :
            </span>
          </React.Fragment>
        ),
        description:
          "Swift detection of security breaches through monitoring systems, followed by immediate response actions to contain and mitigate the impact.",
      },
      {
        title: (
          <React.Fragment key="incident-response-procedures">
            <span style={{ color: "#CFFE25", marginRight: "5px" }}>
              <strong>&#8226;</strong> {/* Bullet point */}
              Investigation :
            </span>
          </React.Fragment>
        ),
        description:
          "Thorough investigation to determine the cause and extent of the breach, involving analysis of system activity and interviews with involved personnel.",
      },
      {
        title: (
          <React.Fragment key="incident-response-procedures">
            <span style={{ color: "#CFFE25", marginRight: "5px" }}>
              <strong>&#8226;</strong> {/* Bullet point */}
              Notification :
            </span>
          </React.Fragment>
        ),
        description:
          " Prompt notification to relevant supervisory authorities and affected individuals within 72 hours of identifying a breach, providing details on the nature of the breach and mitigation steps.",
      },
      {
        title: (
          <React.Fragment key="incident-response-procedures">
            <span style={{ color: "#CFFE25", marginRight: "5px" }}>
              <strong>&#8226;</strong> {/* Bullet point */}
              Mitigation :
            </span>
          </React.Fragment>
        ),
        description:
          "Implementation of measures to mitigate the impact, such as restoring data from backups, patching vulnerabilities, and enhancing security controls.",
      },
      {
        title: (
          <React.Fragment key="incident-response-procedures">
            <span style={{ color: "#CFFE25", marginRight: "5px" }}>
              <strong>&#8226;</strong> {/* Bullet point */}
              Follow-up :
            </span>
          </React.Fragment>
        ),
        description:
          "Conducting post-incident reviews to identify improvements and update incident response protocols accordingly.",
      },
    ],
  },
  {
    category: "c) Third Party Security Obligations",
    measures: [
      {
        title: (
          <>
            <p>
              To ensure comprehensive security across all operations involving
              personal data: 
            </p>
            <React.Fragment key="access-limitation">
              <span>
                <strong>&#8226;</strong> {/* Bullet point */}
                Third-party service
              </span>
            </React.Fragment>
          </>
        ),
        description:
          " providers processing personal data on our behalf are required to adhere to the same stringent security standards we uphold. ",
      },
      {
        title: (
          <React.Fragment key="training-awareness">
            <span>
              <strong>&#8226;</strong> {/* Bullet point */}
              These providers
            </span>
          </React.Fragment>
        ),
        description:
          " must implement appropriate security measures to protect personal data and comply with data protection regulations. ",
      },
    ],
  },
];
const retentionPeriods = [
  <React.Fragment key="1">
    <strong>&#8226;</strong> {/* Bullet point */}
    Personal data collected for contractual purposes is retained for the
    duration of the contract and for a period afterward as required by
    applicable legal obligations.
  </React.Fragment>,
  <React.Fragment key="2">
    <strong>&#8226; </strong>
    Personal data collected for marketing purposes remains until consent is
    withdrawn or the right to object is exercised.
  </React.Fragment>,
  <React.Fragment key="3">
    <strong>&#8226; </strong>
    Personal data collected to comply with legal obligations is retained for the
    period stipulated by relevant laws.
  </React.Fragment>,
];

const criteriaForRetention = [
  <React.Fragment key="1">
    <strong>&#8226;</strong> {/* Bullet point */}
    The specific purpose for which the data was collected.
  </React.Fragment>,
  <React.Fragment key="2">
    <strong>&#8226;</strong> {/* Bullet point */}
    The nature of the personal data involved.
  </React.Fragment>,
  <React.Fragment key="3">
    <strong>&#8226;</strong> {/* Bullet point */}
    Legal or regulatory requirements necessitating retention.
  </React.Fragment>,
  <React.Fragment key="4">
    <strong>&#8226;</strong> {/* Bullet point */}
    Our legitimate interests in retaining the data.
  </React.Fragment>,
];

const exceptions = [
  " In exceptional cases, personal data may be retained for longer periods than specified above to fulfill legal obligations or safeguard our legitimate interests. This includes situations such as resolving disputes, preventing fraud, or enforcing contractual agreements.",
  "When personal data is no longer needed for its intended purpose and retention is not required by law, we ensure its secure deletion or destruction in accordance with our established data retention and disposal policies.",
  " We are committed to managing personal data responsibly and securely throughout its lifecycle, upholding the trust placed in us by our users and complying with relevant data protection regulations.",
];

const privacyPolicySections = [
  "Triskel recognizes the importance of keeping its Privacy Policy current and aligned with evolving data protection standards and legal requirements. Therefore, we may update or amend this Privacy Policy from time to time. Whenever modifications are made, the revised version of the Privacy Policy will be published on this page.",
  "Once the changes are implemented and the revised Privacy Policy is posted, it becomes effective immediately. Continued use of the App after the changes take effect constitutes your acceptance of the revised Privacy Policy.",
  "As a responsible user of the App, it is incumbent upon you to periodically review this Privacy Policy to stay informed about any updates or alterations to our data practices. Regularly checking this page ensures that you are aware of how your information is collected, used, and protected.",
  "In the event of significant changes to the Privacy Policy that may impact your rights or data processing practices, we may also provide additional notification through email or within the App to ensure you are adequately informed.",
  "By adhering to this requirement, you can stay informed about how your personal information is handled within the App and make informed decisions about your data privacy.",
];

const cookiePolicySections = [
  {
    content: (
      <React.Fragment>
        Any questions, complaints, comments and requests regarding this Privacy
        Statement are welcome and should be addressed at{" "}
        <span style={{ color: "#CFFE25" }}>
          https://www.triskel-wallet.com/contactUs
        </span>{" "}
        ,
      </React.Fragment>
    ),
  },
  {
    title: "COOKIE POLICY ",
    content:
      "We respect your privacy and we are committed to ensuring that you are adequately informed on how you can manage your cookies.",
  },
  {
    title: "What are Cookies?",
    content: (
      <React.Fragment>
        Cookies are small data files. When you visit a website, the website
        sends the cookie to your computer. Your computer stores it in a file
        located inside your web browser. 
        <br />
        <br />
        Cookies do not transfer viruses or malware to your computer, because the
        data in a cookie does not change when it travels back and forth, it has
        no way to affect how your computer runs. Instead they act more like logs
        (i.e. they record user activity and remember stateful information), and
        get updated every time you visit a website. 
        <br />
        <br />
        We may obtain information about you by accessing cookies sent by our
        website. Different types of cookies keep track of different activities.
        For example, session cookies are used only when a person is actively
        navigating a website. Once you leave the website, the session cookie
        disappears. For a more detailed list of cookies we use, please see the
        relevant section below.
      </React.Fragment>
    ),
  },
  {
    title: "Why are Cookies Useful?",
    content: (
      <React.Fragment>
        We use functional cookies to analyze how visitors and clients use our
        website, as well as track and improve our website’s performance and
        function. This allows us to provide a high-quality customer experience
        by quickly identifying and fixing any issues that may arise. For
        example, we might use cookies to keep track of which website pages are
        most popular and which method of linking between website pages is most
        effective.  <br /> <br />
        Another use of cookies is to store your login sessions, meaning that
        when you log in to your account to deposit funds, a “session cookie” is
        set so that the website remembers that you have already logged in. If
        the website did not set this cookie, you will be asked for your login
        details on each new page as your progress through the funding process. 
        <br /> <br />
        In addition, functional cookies for example, are used to allow us to
        remember your preferences and identify you as a user, ensure your
        information is secure and operate more reliably and efficiently. For
        example, cookies save you the trouble of typing in your username every
        time you access our trading platform, and recall your preferences.{" "}
        <br />
        <br />
        Here are some of the functions our cookies provide us with:  <br />{" "}
        <br />
        <strong>&#8226;</strong> {/* Bullet point */}
        Verifying your identity and detecting the country you are currently
        visiting from ;<br /> <br />
        <strong>&#8226;</strong>
        Checking browser type and device, and  <br /> <br />
        <strong>&#8226;</strong>
        Tracking which site the user was referred from. <br /> <br />
      </React.Fragment>
    ),
  },
  {
    title: "Types of Cookies We Use",
    content: (
      <React.Fragment>
        <span style={{ color: "#CFFE25" }}>1. Functional cookies :</span> These
        cookies are essential for the running of our website. Without these
        cookies our website would not function properly. These are saved
        temporarily as login information and expire once the browser is
        closed. . <br />{" "}
        <span style={{ color: "#CFFE25" }}>2. Analytical cookies : </span> The
        information provided by analytical cookies allows us to analyze patterns
        of visitor and client behavior and we use that information to enhance
        the overall experience or identify areas of the website which may
        require maintenance. The information is anonymous (i.e. it cannot be
        used to identify you and does not contain personal information such as
        your name and email address), and it is only used for statistical
        purposes. Behavioral cookies are similar to analytics and remember that
        you have visited the website and use that information to provide you
        with content which is tailored to your interests. 
      </React.Fragment>
    ),
  },
  {
    title: "Manage Cookies",
    content: (
      <React.Fragment>
         You can delete cookies any time you want by using the settings in your
        web browser. You can also choose to disable cookies from your web
        browser, but this would mean that our website and other websites that
        you access may not function properly. If you do this, a potential result
        is that you may not be able to sign in. Further information on deleting
        or controlling cookies can be found at{" "}
        <span style={{ color: "#CFFE25" }}> www.aboutcookies.org</span>
      </React.Fragment>
    ),
  },
];

const EligibilityCriteria = [
  "Our products and services are exclusively designed for users who are 18 years of age or older. We do not deliberately gather personal data from individuals recognized as children. By utilizing our services and products, you affirm that you are 18 years old or older. Use of our services by anyone younger is considered unlicensed and a violation of our terms of service.",
  "In the event that personal information from minors is inadvertently collected, we will take appropriate and legally permissible measures to remove such information from our databases. Consequently, any accounts linked to this information will be terminated and access to our products and services will be withdrawn.",
  <React.Fragment>
    If you are a parent or guardian and discover that your child has provided us
    with personal information without your consent, please contact us
    immediately at{" "}
    <span>
      <a style={{ color: "#CFFE25" }} href="/contactUs">
        https://www.triskel-wallet.com/
      </a>
    </span>{" "}
    contactUs. You can request to access, amend, or remove your child's
    information and exercise any additional rights you might have, such as
    opting out of the processing of this information.
  </React.Fragment>,
];

const PrivacyPolicy = () => {
  const Introduction = points.map((item) => <p>{item}</p>);
  const Definitions = terms.map((item) => (
    <p>
      <strong className="strong">{item.term}</strong> {item.definition}
    </p>
  ));
  const PersonalData = personalDataDefinition.map((item) => <p>{item}</p>);
  const personalDataSecond = personalDataCollectionSecond.map((item) => (
    <p> {item}</p>
  ));
  const CollectedInfo = automaticallyCollectedInfo.map((item) => <p>{item}</p>);
  const dataCollection = dataCollectionOverview.map((item) => <p>{item}</p>);
  const privacyCommitment = privacyCommitments.map((item) => <p>{item}</p>);
  const dataCollectionMethod = dataCollectionMethods.map((item) => (
    <div>
      <h6>{item.title}</h6>
      <p>{item.description}</p>
    </div>
  ));
  const thirdPartyIntegrations = thirdPartyIntegrationsOverview.map((item) => (
    <p>{item}</p>
  ));
  const kycVerification = kycVerificationDetails.map((item) => <p>{item}</p>);
  const thirdPartyPrivacy = thirdPartyPrivacyPractices.map((item) => (
    <p>{item}</p>
  ));
  const prepaidCard = prepaidCardApplication.map((item) => <p>{item}</p>);
  const privacyAndSecurity = privacyAndSecurityNote.map((item) => (
    <p>{item}</p>
  ));
  const consentProcessing = consentAndProcessing.map((item) => (
    <div>{item}</div>
  ));
  const purposeDataProcessing = purposeOfDataProcessing.map((item) => (
    <p>{item}</p>
  ));
  const sharingTransfers = sharingAndTransfers.map((item) => <p>{item}</p>);
  const consentRequirement = consentRequirements.map((item) => <p>{item}</p>);
  const consentObtaining = consentObtainingProcess.map((item) => <p>{item}</p>);
  const EligibilityCriteriaService = EligibilityCriteria.map((item) => (
    <p>{item}</p>
  ));
  const userRight = userRights.map((item) => (
    <div>
      <h6>{item.title}</h6>
      <p>{item.description}</p>
    </div>
  ));
  const retentionPeriod = retentionPeriods.map((item) => <p>{item}</p>);

  const criteriaRetention = criteriaForRetention.map((item) => <p>{item}</p>);

  const exception = exceptions.map((item) => <p>{item}</p>);

  const privacyPolicySection = privacyPolicySections.map((item) => (
    <p>{item}</p>
  ));

  const cookiePolicySection = cookiePolicySections.map((section, index) => (
    <div key={index}>
      <h6>{section.title}</h6>
      <p>{section.content}</p>
    </div>
  ));
  return (
    <>
      <div className="privacyPolicy">
        <PageTitle title="Privacy Policy" />
        <h2 className="heading">Privacy Policy</h2>
        <section id="introduction">
          <h4>Introduction</h4>
          {Introduction}
        </section>
        <section id="definitions">
          <h4>Definitions</h4>
          <h6>
            a) As used herein, the following terms are defined as follows:
          </h6>
          {Definitions}
        </section>
        <section id="eligibilityCriteria">
          <h4>Eligibility Criteria for Accessing Our Products and Service</h4>
          {EligibilityCriteriaService}
        </section>
        <section id="collectionOfpersonalData">
          <h4> Collection Of Personal Data</h4>
          <h6>a) Definition of personal data</h6>
          {PersonalData}
          <h6>b) Definition of personal data</h6>
          {personalDataSecond}
          <h6>c) Information collected automatically</h6>
          {CollectedInfo}
        </section>
        <section id="dataCollectionOverview">
          {/* <h4>Data Collection Overview</h4> */}
          <h4>How Data is Collected </h4>
          {dataCollection}
          {privacyCommitment}
          {dataCollectionMethod}
        </section>
        <section id="thirdPartyService">
          <h4>Third-Party Services</h4>
          {thirdPartyIntegrations}
          {kycVerification}
          {thirdPartyPrivacy}
        </section>
        <section id="informationCollectionCardService">
          <h4>Information Collection for Prepaid Card Service </h4>
          {prepaidCard}
          {privacyAndSecurity}
        </section>
        <section id="legalbasicForProcessingData">
          <h4>Legal Basis for Processing Data</h4>
          {consentProcessing}
        </section>
        <section id="useofData">
          <h4> Use of Data</h4>
          <h6>a) Purpose of Data Processing </h6>
          {purposeDataProcessing}
          <h6>b) Sharing of Data and International Data Transfers</h6>
          {sharingTransfers}
        </section>
        <section id="Consent">
          <h4> Consent</h4>
          <h6>a)Requirements</h6>
          <p>
            Consent serves as a legal basis for processing personal data under
            this Policy. For consent to be valid, it must meet the following
            criteria: 
          </p>
          {consentRequirement}
          <h6>b)How consent will be obtained</h6>
          {consentObtaining}
        </section>
        <section id="rightsOfDataSubject">
          <h4> Rights of Data Subjects </h4>
          {userRight}
          <p>
            To exercise any of these rights, please contact us using the
            information provided in the "Contact Information" section below. We
            may need to verify your identity before responding to your request.
            We will respond as quickly as possible, within one month of
            receiving your request. If your request is complex or we receive
            multiple requests from you, we may extend this period by up to two
            months and will inform you accordingly. If we cannot fulfill your
            request, we will explain why. 
          </p>
        </section>
        <section id="security ">
          <h4> Security </h4>
          <p>
            Triskel is committed to ensuring the security and protection of user
            information within the Platform. We employ industry-standard
            security practices and take appropriate precautions to safeguard
            your data against unauthorized access, disclosure, or alteration.
            These measures include strong encryption, access controls, regular
            security assessments, and other relevant technologies. 
          </p>
          <p>
            Despite our best efforts, it is essential to recognize that no
            online service can guarantee absolute security. As technology
            evolves, so do the methods and tactics used by malicious actors
            seeking to exploit vulnerabilities. Therefore, there is always a
            residual risk of data breaches, cyberattacks, or other security
            incidents.
          </p>
          {securityMeasures.map((section, index) => (
            <div key={index}>
              <h6>{section.category}</h6>
              {section.measures.map((measure, idx) => (
                <div key={idx}>
                  <p>
                    <span>{measure.title}</span>
                    {measure.description}
                  </p>
                </div>
              ))}
            </div>
          ))}

          <p>
            Triskel encourages users to be vigilant and proactive in protecting
            their information, such as using strong passwords, regularly
            updating software, and being cautious about sharing sensitive data.
            <br /> <br />
            While we strive to maintain the confidentiality and integrity of
            your information, Triskel cannot be held liable for any security
            breaches or unauthorized access beyond our control. In the event of
            a security incident, we will make every effort to promptly respond,
            investigate, and take appropriate actions to mitigate the impact and
            prevent future occurrences. <br />
            <br /> These measures are designed to safeguard your wallet and
            personal data, offering both convenience and peace of mind. For any
            security-related concerns or inquiries, please contact us using the
            information provided in our support section. 
          </p>
        </section>
        <section id="dataRetention">
          <h4> Data Retention</h4>
          <p>
            We adhere to strict guidelines for retaining personal data, ensuring
            it is kept only for as long as necessary to fulfill its intended
            purposes, unless extended retention is mandated by law or our
            legitimate interests.
          </p>
          <h6>a) Retention Periods</h6>
          <p>Our retention periods for personal data are as follows: </p>
          {retentionPeriod}
          <h6>b) Criteria for Determining Retention Periods</h6>
          <p>
            The duration for which personal data is retained is determined based
            on: 
          </p>
          {criteriaRetention}
          <h6>c) Exceptions to Retention Periods</h6>
          {exception}
        </section>
        <section id="changesofprivacyPolicy">
          <h4> Changes to the Privacy Policy</h4>
          {privacyPolicySection}
        </section>
        <section id="complaint ">
          <h4> If you have a complaint </h4>
          {cookiePolicySection}
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;
