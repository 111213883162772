import * as yup from "yup";
const restrictedDomains = ["yopmail.com", "mailinator.com"];

export const registerSchema = yup.object().shape({
  merchantName: yup
    .string()
    // .trim()
    .required("Merchant Name is required")
    .min(3, "Merchant Name must be at least 3 characters")
    .max(20, "Merchant Name can't be more than 20 characters") // Only keep one max
    .matches(
      /^[A-Za-z][A-Za-z\s]*$/,
      "Merchant Name can only contain letters and spaces, and cannot start with a space"
    ),

  emailAddress: yup
    .string()
    // .trim()
    .required("Email Address is required")
    .matches(
      /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i,
      "Please enter a valid email address"
    )
    .test(
      "is-restricted-domain", // Custom test name
      "This email domain is not allowed", // Error message
      (value) => {
        if (!value) return true; // Skip this validation if the value is not provided (handled by `required`)
        const domain = value.split("@")[1].toLowerCase();
        return !restrictedDomains.includes(domain); // Return true if the domain is not restricted
      }
    ),
  phoneNumber: yup
    .string()
    .trim()
    // .nullable()
    .matches(/^\d+$/, "Phone Number can only contain digits")
    .min(6, "Phone Number must be at least 6 digits")
    .max(15, "Phone Number can't be more than 15 digits")
    .required("Phone Number is required"),
  platformUrl: yup
    .string()
    // .trim()
    // .matches(
    //   /^[A-Za-z][A-Za-z\s]*$/,
    //   "PlatformUrl can only contain letters and special characters, and cannot start with a space"
    // )
    // .max(100, "Phone Number can't be more than 50 digits")
    .required("Platform URL is required")
    .url("Please enter a valid URL"),
  platformName: yup
    .string()
    // .trim()
    .required("Platform Name is required")
    .min(3, "Platform Name must be at least 3 characters")
    .matches(
      /^[A-Za-z][A-Za-z\s]*$/,
      "Platform Name can only contain letters and spaces, and cannot start with a space"
    )
    .max(50, "Platform Name can't be more than 50 characters"),

  logo: yup.string().required("Merchant logo is required"),

  frontIdImage: yup.string().required("Front ID image is required"),

  backIdImage: yup.string().required("Back ID image is required"),
});
