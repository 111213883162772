import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import "./Layout.scss";
import ApplicantHeader from "../ApplicantHeader/ApplicantHeader";

function Layout() {
  return (
    <div className="main-wallet">
      <Header />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
