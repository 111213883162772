import React, { useEffect } from "react";
import "../PolicyMain.scss";
import PageTitle from "../../../Common/PageTitle/PageTitle";

const Faq = () => {
  useEffect(() => {
    document.body.classList.add("faqPage");

    return () => {
      document.body.classList.remove("faqPage");
    };
  }, []);

  const resources = [
    {
      title: "Terms of Use",
      href: "https://d2j1mnvck8397w.cloudfront.net/triskelcap-terms-of-use.pdf",
    },
    {
      title: "Privacy Policy",
      href: "https://d2j1mnvck8397w.cloudfront.net/triskelcap-privacy-policy.pdf",
    },
    {
      title: "Cookie Policy",
      href: "https://d2j1mnvck8397w.cloudfront.net/triskelcap-cookie-policy.pdf",
    },
    {
      title: "IP Notice",
      href: "https://d2j1mnvck8397w.cloudfront.net/triskelcap-ip-notice.pdf",
    },
    {
      title: "Terms of Exchange Operations",
      href: "https://d2j1mnvck8397w.cloudfront.net/triskelcap-terms-of-exchange-operations.pdf",
    },
    {
      title: "Anti-Fraud Policy",
      href: "https://d2j1mnvck8397w.cloudfront.net/triskelcap-anti-fraud-policy.pdf",
    },
    {
      title: "KYC & AML Policy",
      href: "https://d2j1mnvck8397w.cloudfront.net/triskelcap-kyc-aml-policy.pdf",
    },
    {
      title: "Apple Pay Terms and Conditions",
      href: "https://d2j1mnvck8397w.cloudfront.net/applepay-terms-and-conditions.pdf",
    },
    {
      title: "Google Pay Terms and Conditions",
      href: "https://d2j1mnvck8397w.cloudfront.net/googlepay-terms-and-conditions.pdf",
    },
    {
      title: "Terms and Conditions of Payment Cards",
      href: "https://d2j1mnvck8397w.cloudfront.net/triskelcap-terms-and-conditions-of-payment-cards.pdf",
    },
  ];

  const mappedResources = resources.map((resource, index) => (
    <li key={index}>
      <a
        style={{ color: "#1e293b" }}
        href={resource.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {resource.title}
      </a>
    </li>
  ));

  return (
    <div className="privacyPolicy">
      <PageTitle title="Help" />

      <h2 className="heading">Help And Faq</h2>
      <iframe
        src="https://admin.vault.ist/iframe/faq/13049632192156/13109635465116"
        frameborder="0"
        width="100%"
        height="600px"
        title="Help FAQ"
      />

      <h2 className="heading">Additional Resources</h2>

      <ul style={{ color: "#FFFF" }}>{mappedResources}</ul>
    </div>
  );
};

export default Faq;
