import React from "react";
import { Select } from "antd";
import "./CustomSelect.scss";

const { Option } = Select;

function CustomSelect(props) {
  const { label, options, onChange, onSearch, placeholder,customClass,required,error, defaultValue } = props;

  return (
    <div
      className={`customSelect ${customClass} ${
        error ? "customSelect-error" : ""
      }`}
    >
      <label>
        {required && <span>*</span>}
        {label}
      </label>
      <Select
        showSearch
        placeholder={placeholder}
        optionFilterProp="label"
        onChange={onChange}
        onSearch={onSearch}
        className="customSelect-dropdown"
        options={options}
        defaultValue={defaultValue}
      >
        
      </Select>
      {error && <p>{error}</p>}
    </div>
  );
}

export default CustomSelect;
