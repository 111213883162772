import React from "react";
import "./Balancing.scss";
import { StoreImages } from "../../../../assets/StoreAsset/StoreImages";
function Balancing() {
  const { dao } = StoreImages;
  return (
    <div className="container balancing">
      <div className="balancing_left">
        <img src={dao} alt="dao" />
      </div>
      <div className="balancing_right">
        <p className="greentext">Triskel DAO</p>
        <h2>Balancing Team and Community</h2>
        <p className="balancing_right_text">
          Triskel embraces the DAO spirit by empowering its token holders and
          encouraging their active participation in the decision-making process.
          A significant portion of tokens are allocated to users and advisors to
          let them vote on partnerships and influence Triskel's future plans for
          collective success.
       </p>
      </div>
    </div>
  );
}

export default Balancing;
