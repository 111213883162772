import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./PolicyMain.scss";
import RiskDisclaimer from "./RiskDisclaimer/RiskDisclaimer";
import Header from "../../Common/Header/Header";

const RiskMain = () => {
  const [scrollTarget, setScrollTarget] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const handleScroll = useCallback(
    (event, targetId) => {
      event.preventDefault();
      if (location.pathname === "/risk-disclaimer") {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
          setTimeout(() => {
            window.scrollBy(0, -120); // Scrolls 120px above the target element
          }, 900);
        }
      } else {
        setScrollTarget(targetId);
        navigate("/");
      }
    },
    [location.pathname, navigate]
  );

  useEffect(() => {
    if (scrollTarget && location.pathname === "/risk-disclaimer") {
      const targetElement = document.getElementById(scrollTarget);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
      setScrollTarget(null);
    }
  }, [scrollTarget, location.pathname]);

  return (
    <>
      <Header />
      <div className="policyLayout">
        <div className="container ">
          <div className="policyLayout_main">
            <div class="sidebar">
              <NavLink
                class="active"
                to="/"
                onClick={(e) => handleScroll(e, "riskDisclaimer")}
              >
                <span>1. INTRODUCTION</span>
              </NavLink>
              <NavLink
                to="/"
                onClick={(e) => handleScroll(e, "riskAssociated")}
              >
                <span>2. GENERAL RISK</span>
              </NavLink>
              <NavLink
                to="/"
                onClick={(e) => handleScroll(e, "riskAssociated")}
              >
                <span>3. RISKS ASSOCIATED WITH CRYPTOCURRENCIES</span>
              </NavLink>
              <NavLink
                to="/"
                onClick={(e) => handleScroll(e, "nonCustoDialWallet")}
              >
                <span>4. RISK ASSOCIATED WITH NON-CUSTODIAL WALLETS</span>
              </NavLink>
              <NavLink
                to="/"
                onClick={(e) => handleScroll(e, "cyberSecurityRisk")}
              >
                <span>5. CYBER SECURITY RISKS </span>
              </NavLink>
              <NavLink to="/" onClick={(e) => handleScroll(e, "oversightRisk")}>
                <span>
                  6. RISK OF VOLATILITY, MARKET VARIABILITY, AND THE IMPORTANCE
                  OF OVERSIGHT RISK
                </span>
              </NavLink>
              <NavLink to="/" onClick={(e) => handleScroll(e, "liquidityRisk")}>
                <span>7. LIQUIDITY RISK</span>
              </NavLink>
              <NavLink to="/" onClick={(e) => handleScroll(e, "smartContact")}>
                <span>8. SMART CONTRACT RISK</span>
              </NavLink>
              <NavLink to="/" onClick={(e) => handleScroll(e, "thirdPartRisk")}>
                <span>9. THIRD-PARTY RISKS </span>
              </NavLink>
              <NavLink
                to="/"
                onClick={(e) => handleScroll(e, "ragulatoryRisk")}
              >
                <span>10. LEGAL AND REGULATORY RISKS</span>
              </NavLink>
              <NavLink to="/" onClick={(e) => handleScroll(e, "technicalRisk")}>
                <span>11. TECHNICAL RISKS</span>
              </NavLink>
              <NavLink to="/" onClick={(e) => handleScroll(e, "pureBusiness")}>
                <span>12. PURE BUSINESS RISKS</span>
              </NavLink>
              <NavLink to="/" onClick={(e) => handleScroll(e, "economicRisk")}>
                <span>13. ECONOMIC RISKS </span>
              </NavLink>
              <NavLink to="/" onClick={(e) => handleScroll(e, "disclaimer")}>
                <span>14. DISCLAIMER</span>
              </NavLink>
              <NavLink to="/" onClick={(e) => handleScroll(e, "consclusion")}>
                <span>15. CONCLUSION</span>
              </NavLink>
            </div>
            <div class="contentSide">
              <RiskDisclaimer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiskMain;
