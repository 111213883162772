import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./PolicyMain.scss";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import Header from "../../Common/Header/Header";
import Faq from "./Faq/Faq";

const FaqMain = () => {
    const [scrollTarget, setScrollTarget] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const handleScroll = useCallback(
        (event, targetId) => {
            event.preventDefault();
            if (location.pathname === "/help.com") {
                const targetElement = document.getElementById(targetId);
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: "smooth" });
                    setTimeout(() => {
                        window.scrollBy(0, -120); // Scrolls 120px above the target element
                    }, 900);
                }
            } else {
                setScrollTarget(targetId);
                navigate("/");
            }
        },
        [location.pathname, navigate]
    );

    useEffect(() => {
        if (scrollTarget && location.pathname === "/help.com") {
            const targetElement = document.getElementById(scrollTarget);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: "smooth" });
            }
            setScrollTarget(null);
        }
    }, [scrollTarget, location.pathname]);

    return (
        <>
            <Header />
            <div className="policyLayout">
                <div className="container">
                    <div className="policyLayout_main">
                        {/* <div class="sidebar">
                            <NavLink
                                onClick={(e) => handleScroll(e, "introduction")}
                                class="active"
                                to="/"
                            >
                                <span>1. Introduction</span>
                            </NavLink>
                            <NavLink onClick={(e) => handleScroll(e, "definitions")} to="/">
                                <span>2. Definitions</span>
                            </NavLink>
                            <NavLink
                                onClick={(e) => handleScroll(e, "eligibilityCriteria")}
                                to="/"
                            >
                                <span>
                                    3. Eligibility Criteria for for Accessing Our Products and
                                    Service
                                </span>
                            </NavLink>
                            <NavLink
                                onClick={(e) => handleScroll(e, "collectionOfpersonalData")}
                                to="/"
                            >
                                <span>4. Collection Of Personal Data</span>
                            </NavLink>
                            <NavLink
                                onClick={(e) => handleScroll(e, "dataCollectionOverview")}
                                to="/"
                            >
                                <span>5. How Data is Collected</span>
                            </NavLink>
                            <NavLink
                                onClick={(e) => handleScroll(e, "thirdPartyService")}
                                to="/"
                            >
                                <span>6. Third-Party Services</span>
                            </NavLink>
                            <NavLink
                                onClick={(e) =>
                                    handleScroll(e, "informationCollectionCardService")
                                }
                                to="/"
                            >
                                <span>7. Information Collection for Prepaid Card Service:</span>

                            </NavLink>
                            <NavLink
                                onClick={(e) => handleScroll(e, "legalbasicForProcessingData")}
                                to="/"
                            >
                                <span>8. Legal Basis for Processing Data</span>
                            </NavLink>
                            <NavLink onClick={(e) => handleScroll(e, "useofData")} to="/">
                                <span>9. Use of Data  </span>
                            </NavLink>
                            <NavLink onClick={(e) => handleScroll(e, "Consent")} to="/">
                                <span>10. Consent </span>
                            </NavLink>
                            <NavLink
                                onClick={(e) => handleScroll(e, "rightsOfDataSubject")}
                                to="/"
                            >
                                <span>11. Rights of Data Subjects  </span>
                            </NavLink>
                            <NavLink onClick={(e) => handleScroll(e, "security ")} to="/">
                                <span>12. Security </span>
                            </NavLink>
                            <NavLink onClick={(e) => handleScroll(e, "dataRetention")} to="/">
                                <span>13. Data Retention </span>
                            </NavLink>
                            <NavLink
                                onClick={(e) => handleScroll(e, "changesofprivacyPolicy")}
                                to="/"
                            >
                                <span>14.  Changes to the Privacy Policy </span>
                            </NavLink>
                            <NavLink onClick={(e) => handleScroll(e, "complaint ")} to="/">
                                <span>15.  If you have a complaint </span>
                            </NavLink>
                        </div> */}
                        <div class="contentSide">
                            <Faq />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FaqMain;
