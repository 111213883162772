import "./App.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./Routing/Routes";
import "./index.scss";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import FranchiseePartner from "./pages/FranchiseePartner/FranchiseePartner";
import PrivacyPolicy from "./pages/PolicyMain/PrivacyPolicy/PrivacyPolicy.jsx";
import PolicyMain from "./pages/PolicyMain/PolicyMain.jsx";
import RiskDisclaimer from "./pages/PolicyMain/RiskDisclaimer/RiskDisclaimer.jsx";
import TermofUse from "./pages/PolicyMain/TermUse/TermofUse.jsx";
import TermuseMain from "./pages/PolicyMain/TermuseMain.jsx";
import RiskMain from "./pages/PolicyMain/RiskMain.jsx";
// import Faq from "./pages/PolicyMain/Faq/Faq.jsx";
import FaqMain from "./pages/PolicyMain/FaqMain.jsx";
import RegisterMerchant from "./pages/RegisterMerchant/RegisterMerchant.jsx";

function App() {
  const { isLoading } = useSelector((state) => state.loading);

  const loaderStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  };
  const router = createBrowserRouter([
    {
      path: "/",
      children: routes,
      errorElement: <div style={{ color: "#fff" }}>Error</div>,
    },
    {
      path: "/franchisee-partner",
      element: <FranchiseePartner />,
    },
    {
      path: "/privacy-policy",
      element: <PolicyMain />,
    },
    // {
    //   path: '/risk-disclaimer',
    //   element: <RiskDisclaimer/>,
    // },
    // {
    //   path: '/term-ofuse',
    //   element: <TermofUse/>,
    // },
    {
      path: "/risk-disclaimer",
      element: <RiskMain />,
    },
    {
      path: "/term-of-use",
      element: <TermuseMain />,
    },
    {
      path: "/help.com",
      element: <FaqMain />,
    },
    {
      path: "/registerMerchant",
      element: <RegisterMerchant />,
    },
  ]);
  return (
    <div className="App">
      {isLoading && <Spin size="large" />}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
