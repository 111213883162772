import React from "react";
import "./FranchiseePartner.scss";
import { Link } from "react-router-dom";
import { GreenApp, GreenPlay, PlayBtn } from "../../Common/SvgIcons/SvgIcons";
import { Flex } from "antd";
import { ButtonCustom } from "../../Common/ButtonCustom/ButtonCustom";
import Footer from "../../Common/Footer/Footer";
import Header from "../../Common/Header/Header";

const FranchiseePartner = () => {
  const handleClick = () => {
    window.location.href = "https://www.user.trillab0608.xyz/rpIHHf6kQeOn20d5PbtqmJ9YGZpyzf5d";
  };
  return (
    <>
      <Header />
      <section>
        <div className="franchiseeBanner">
          <h1>
            Become a Triskel <br /> Franchisee Partner
          </h1>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="stepBlock">
            <div className="stepPoints">
              <h2>Steps to become a Franchisee partner</h2>
              <ul>
                <li>
                  <span>Download the Triskel app.</span>
                </li>
                <li>
                  <span className="d-flex">
                    Go to the ‘Referral and Rewards’ section in Settings.
                    <PlayBtn />
                  </span>
                </li>
                <li>
                  <span>Refer at least 100 users.</span>
                </li>
                <li>
                  <span>Earn rewards from these referrals.</span>
                </li>
                <li>
                  <span>
                    Become a franchisee upon successful card applications from
                    referred users.
                  </span>
                </li>
                <li>
                  <span>
                    {" "}
                    Earn royalty as higher reward percentages on becoming a
                    franchisee.
                  </span>
                </li>
                <li>
                  <span>
                    {" "}
                    Upgrade to Premium or Master franchisee to enjoy enhanced
                    reward percentages.
                  </span>
                </li>
                <li>
                  <span>
                    {" "}
                    Gain access to franchise portal and track the performance of
                    referred users.
                  </span>
                </li>
              </ul>
              <Flex gap={16} wrap>
                <Link to="https://play.google.com/store/search?q=triskel+wallet&c=apps">
                  <GreenPlay />
                </Link>
                <Link to="https://apps.apple.com/in/app/triskel-wallet/id6449494626">
                  <GreenApp />
                </Link>
              </Flex>
            </div>
            <div className="cardBlock franchiseeCard">
              <div className="partnerCard">
                <h2>
                  Already a Franchisee <br />
                  Partner
                </h2>
                <p>Expand your business horizons with our trusted support.</p>
                <ButtonCustom
                  // style={{maxWidth:"318px", width:'100%'}}
                  greenborder
                  label="Explore Franchisee Portal"
                    onClick={handleClick}
                  btnBorder={true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
};

export default FranchiseePartner;
