import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Row, Col, message, Tooltip } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import "./Footer.scss";
import { Input } from "antd";
import {
  Discord,
  Dotsocial,
  GreenArrow,
  Insta,
  Radix,
  Telegram,
  LinkedIn,
  Twitter,
} from "../SvgIcons/SvgIcons";
import { useForm, Controller } from "react-hook-form";
import { Validations } from "../../validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import {
  finishLoading,
  startLoading,
} from "../../redux/features/loading/loadingSlice";
import { ContactUsActions } from "../../redux/features/contactUs/contactUsActions";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { YoutubeOutlined } from "@ant-design/icons";
import TriskelWhitepaper from '../../assets/Pdf/TriskelWhitepaper.pdf'
import TriskelSpanishWhitepaper from '../../assets/Pdf/TriskelSpanishWhitepaper.pdf'


function Footer() {
  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(Validations.newsletter),
    mode: "all",
  });
  const [scrollTarget, setScrollTarget] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleScroll = useCallback(
    (event, targetId) => {
      event.preventDefault();
      if (location.pathname === "/") {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        setScrollTarget(targetId);
        navigate("/");
      }
    },
    [location.pathname, navigate]
  );

  const submitForm = async (values) => {
    console.log({ values });
    let msg = "";
    dispatch(startLoading());
    try {
      const res = await dispatch(
        ContactUsActions.subscribeNewsletter(values)
      ).unwrap();
      console.log(res);
      msg = res.message;
      message.success(msg);
    } catch (error) {
      console.error(error);
      msg = error.message || "An error occured";
      message.error(msg);
    } finally {
      dispatch(finishLoading());
    }
    reset();
  };
  const [arrow, setArrow] = useState("Show");
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }
    if (arrow === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  const handleDownload = async () => {
    // const pdfUrl = 'https://d2j1mnvck8397w.cloudfront.net/Triskel+Whitepaper.pdf';

    try {
        // Fetch the PDF file as a blob
        const response = await fetch(TriskelWhitepaper, { mode: 'cors' });
        if (!response.ok) throw new Error('Network response was not ok');

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create a link element to download the blob
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Triskel_Whitepaper.pdf';
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading PDF:', error);
    }
};
const handleDownloadESP = async () => {
  // const pdfUrl = 'https://d2j1mnvck8397w.cloudfront.net/Triskel+Whitepaper.pdf';

  try {
      // Fetch the PDF file as a blob
      const response = await fetch(TriskelSpanishWhitepaper, { mode: 'cors' });
      if (!response.ok) throw new Error('Network response was not ok');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a link element to download the blob
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Triskel_Whitepaper.pdf';
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
  } catch (error) {
      console.error('Error downloading PDF:', error);
  }
};
  return (
    <form noValidate>
      <div className="footernew">
        <div className="container">
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={24} md={12} lg={12}>
              <span className="footerlogo">
                <img src={logo} alt="logo" />
              </span>
              <p>
                Designed to serve globetrotters, savvy investors, remote
                workers, and the unbanked populace alike, Triskel unlocks your
                way to financial freedom.
              </p>
              <div className="footerInput">
                {/* <h4>Subscribe to our Newsletter</h4> */}
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter Email for Newsletter Signup"
                      suffix={
                        <span
                          className="greenArrow"
                          onClick={handleSubmit(submitForm)}
                        >
                          <GreenArrow />
                        </span>
                      }
                      {...field}
                    />
                  )}
                />
                <ErrorMessage message={errors?.email?.message} />
              </div>
              <p className="footerYear">2024 Triskel / All rights reserved</p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="footerLegal">
                <div>
                  <h3>LEGAL</h3>
                  <Link to="/privacy-policy" target="_blank">
                    Privacy Policy
                  </Link>
                  <Link to="/term-of-use" target="_blank">
                    Terms of Use
                  </Link>
                  <Link to="/risk-disclaimer" target="_blank">
                    Risk Disclaimer
                  </Link>
                  {/* <Link to="/term-of-use" target="_blank">
                    Terms and Conditions
                  </Link> */}
                  {/* <Link to="/help.com" target="_blank">
                    Virtual Cards Help
                  </Link> */}
                </div>

                <div>
                  <h3>COMPANY</h3>
                  {/* <Link
                    to="/"
                    target="_blank"
                    onClick={(e) => handleScroll(e, "banner")}
                  >
                    Home
                  </Link>
                  <Link
                    to="/"
                    target="_blank"
                    onClick={(e) => handleScroll(e, "whyTriskel")}
                  >
                    Why Triskel
                  </Link>
                  <Link
                    to="/features"
                    target="_blank"
                    onClick={(e) => handleScroll(e, "feature")}
                  >
                    Features
                  </Link>
                  <Link
                    to="/useCases"
                    target="_blank"
                    onClick={(e) => handleScroll(e, "usecases")}
                  >
                    Use Cases
                  </Link>
                  <Link
                    to="/triskelToken"
                    target="_blank"
                    onClick={(e) => handleScroll(e, "token")}
                  >
                    Triskel Token
                  </Link>{" "}
                  <Link
                    to="/roadmap"
                    target="_blank"
                    onClick={(e) => handleScroll(e, "roadmap")}
                  >
                    Roadmap
                  </Link> */}
                  {/* <Link
                    to="https://www.en.triskel-group.com/Whitepaper-Triskel-2024-EN.pdf"
                    target="_blank"
                    // onClick={(e) => handleScroll(e, "deepdive")}
                  >
                    Whitepaper
                  </Link> */}
                  <Link to="/contactUs" target="_blank">
                    Contact Us
                  </Link>
                  <Link to="">Referral program</Link>

                  <Link to="/registerMerchant">Register Merchant</Link>
                  <Link
                    to={TriskelWhitepaper}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleDownload}
                  >
                    Whitepaper - ENG
                  </Link>
                  <Link
                    to={TriskelSpanishWhitepaper}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleDownloadESP}
                  >
                    Whitepaper - ESP
                  </Link>
                </div>
              </div>
            </Col>

            <Col xs={24} lg={4}>
              <h3 className="followus">FOLLOW US</h3>
              <div className="socialIcons" style={{ paddingTop: "10px" }}>
                <Tooltip placement="top" title="Follow us" arrow={mergedArrow}>
                  <Link to="" target="_blank">
                    <Radix />
                  </Link>
                </Tooltip>
                <Tooltip placement="top" title="Follow us" arrow={mergedArrow}>
                  <Link to="https://x.com/TriskelWallet" target="_blank">
                    <Twitter />
                  </Link>
                </Tooltip>

                <Tooltip placement="top" title="Follow us" arrow={mergedArrow}>
                  <Link to="https://t.me/+_uYfCfmy9YRmZGFl" target="_blank">
                    <Telegram />
                  </Link>
                </Tooltip>
                <Tooltip placement="top" title="Follow us" arrow={mergedArrow}>
                  <Link
                    to="https://discord.com/invite/triskelwallet"
                    target="_blank"
                  >
                    <Discord />
                  </Link>
                </Tooltip>
              </div>

              <div className="socialIcons">
                <Tooltip placement="top" title="Follow us" arrow={mergedArrow}>
                  <Link
                    to="https://www.instagram.com/triskel_wallet/"
                    target="_blank"
                  >
                    <Insta />
                  </Link>
                </Tooltip>
                <Tooltip placement="top" title="Follow us" arrow={mergedArrow}>
                  <Link
                    to="https://www.linkedin.com/company/grupo-triskel/"
                    target="_blank"
                  >
                    <LinkedIn />
                  </Link>
                </Tooltip>
                <Tooltip placement="top" title="Follow us" arrow={mergedArrow}>
                  <Link to="https://medium.com/@triskelwallet" target="_blank">
                    <Dotsocial />
                  </Link>
                </Tooltip>
                {/* <Tooltip placement="top" title="Follow us" arrow={mergedArrow}>
                  <Link
                    className="YoutubeIcon"
                    to="https://www.youtube.com/@triskelgroup"
                    target="_blank"
                  >
                    <YoutubeOutlined />
                  </Link>
                </Tooltip> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </form>
  );
}

export default Footer;
