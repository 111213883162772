import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CountryPhoneInput = ({ value, onChange, error }) => {
  return (
    <div className="phoneNumber" style={{borderRadius: "5px"}} >
      <label>
        Phone Number <span style={{ color: "red", marginLeft: "5px" }}> * </span>
      </label>
      <PhoneInput
        style={error ? { outline: "1px solid var(--error)", borderRadius: "5px" } : {}}
        country={"us"}
        value={value}
        onChange={onChange}
        enableSearch={true}
      />
      {error && <p style={{ color: "red", marginTop: 8}}>{error}</p>}
    </div>
  );
};

export default CountryPhoneInput;
