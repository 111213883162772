import React from "react";
import "../../Home.scss";
import{StoreImages} from "../../../../assets/StoreAsset/StoreImages.jsx"

function OurPartner() {
  const{swft,webauth,transank,alchemy,liminal,uniswap}= StoreImages;
  return (
    <div className=" ourPartner">
      <div className=" ourPartner_inner container">
        <h2 className="PartHead">Our Partners</h2>
        <div className="ourPartner_inner_logos">
          <img src={swft} alt="swft" />
          <img src={webauth} alt="webauth" />
          <img src={transank} alt="transank" />
          <img src={alchemy} alt="alchemy" />
          <img src={liminal} alt="liminal" />
          <img src={uniswap} alt="uniswap" />
        </div>
      </div>
    </div>
  );
}

export default OurPartner;
