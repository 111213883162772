// CustomModal.jsx
import React from 'react';
import { Modal, Button } from 'antd';

const CustomModal = ({ visible, title, content, onCancel, onOk }) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      centered
      onOk={onOk}
      footer={[
        <Button key="back" onClick={onCancel}>
          Return
        </Button>,
        <Button key="submit" type="primary" onClick={onOk}>
          Submit
        </Button>,
      ]}
    >
      {content}
    </Modal>
  );
};

export default CustomModal;
