import { createAsyncThunk } from "@reduxjs/toolkit";
import {CONSTANTS} from '../../../constants'

const {apiEndPoints, env}  = CONSTANTS
const API_URL = env.apiUrlMerchant

const registerMerchentInfoapi = createAsyncThunk("registerMerchent/registerInfo", async (data, {rejectWithValue}) => {
  try {
    const response = await fetch(API_URL+apiEndPoints.REGISTER_MERCHENT_INFO, {
      method:"POST",
      headers:{"Content-Type": "application/json"},
      body:JSON.stringify(data)    
    });
    // if (!response.ok) {
    //   throw new Error("response");
    // }
    return await response.json();  
  } catch (error) {
    return rejectWithValue(error); 
  } 
  
});


const registerMerchentImageapi = createAsyncThunk(
    "registerMerchent/registerImage",
    async (formData, { rejectWithValue }) => {
      try {
        const response = await fetch(API_URL + apiEndPoints.REGISTER_MERCHENT_IMAGE, {
          method: "POST",
          body: formData, // Use FormData directly
        });
  
        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`Upload failed: ${errorMessage}`);
        }
  
        return await response.json();  
      } catch (error) {
        return rejectWithValue(error.message); 
      }
    }
  );
  

export const RegisterMerchent = {
    registerMerchentInfoapi,
    registerMerchentImageapi
};