import tstutility from "../tstutility.svg";
import tututility from "../tututility.svg";
export const StoreImages = {
  swft: require("../swft.png"),
  webauth: require("../webauth.png"),
  transank: require("../transak.png"),
  snapusdt: require("../snapusdt.png"),
  alchemy: require("../alchemy.png"),
  liminal: require("../liminal.png"),
  uniswap: require("../uniswap.png"),
  snaponoff: require("../snaponoff.png"),
  snaptut: require("../snaptut.png"),
  snaptst: require("../snaptst.png"),
  snaptoken: require("../snaptoken.png"),
  snapsto: require("../snapsto.png"),
  snapcross: require("../snapcross.png"),
  snapsend: require("../snapsend.png"),
  snapover: require("../snapover.png"),
  snapreferal: require("../snapreferal.png"),
  tokenright: require("../tokenright.png"),
  whytriskel: require("../whytriskel.png"),
  certificatedubai: require("../Triskel-Global-Holding-Dubai-COI.png"),
  certificateWyoming: require("../Triskel-Capital-LLC-Wyoming-COI.png"),
  certificateisland: require("../certificado-marshall-islands.png"),
  certificatesaint: require("../Triskel-Capital-Ltd-COI.png"),
  certificatepanama: require("../CERTIFICADO-DE-TRISKEL-PANAMA.png"),

  mastercard: require("../mastercard.png"),
  craouselfirst: require("../caraouselFirst.png"),
  craouselsecond: require("../craouselsecond.png"),
  dao: require("../dao.png"),
  craouselthird: require("../craouselthird.png"),
  craouselfourth: require("../caraouselFourth.png"),
  craouselFifth: require("../caraouselFifth..png"),
  shopping: require("../shoppin.png"),
  travel: require("../travel.png"),
  crypto: require("../crypto.png"),
  donation: require("../donation.png"),
  crossborder: require("../crossborder.png"),
  instantpay: require("../instantpay.png"),
  frame: require("../frame.png"),
  tututility: tututility,
  tstutility: tstutility,
};
