import React from "react";
import { Link } from "react-router-dom";
import { GreenApp, GreenPlay } from "../../../../Common/SvgIcons/SvgIcons";
import "./Banner.scss";
function Banner() {
  return (
    <div className="banner" id="banner">
      <video
        src={"https://d2j1mnvck8397w.cloudfront.net/mainbanner.mp4"}
        muted
        autoPlay
        loop
        playsInline
        controls={false}
      />
      <div className="banner_data">
        <h1>
          Web3 Superapp
          <br /> for Next-gen Investors
        </h1>
        {/* <h1>
          Non-Custodial Wallet: Superapp <br /> for New-Age Investors
        </h1> */}
        <p>All-in-One Non-Custodial wallet for DeFi Services.</p>
        <div className="bannerbuttons">
          <Link to="https://play.google.com/store/search?q=triskel+wallet&c=apps"> 
            <GreenPlay />
          </Link>
          <Link to="https://apps.apple.com/in/app/triskel-wallet/id6449494626">
            <GreenApp />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Banner;
