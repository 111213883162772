import React, { useState } from "react";
import "./RegisterMerchant.scss";
import Logo from "../../assets/logoR.png";
import WelcomeTriskel from "../../assets/WelcomeTriskel.png";
import InputCustom from "../../Common/InputCustom/InputCustom";
import CountryPhoneInput from "./CountryPhoneInput";
import Uploader from "../../Common/Uploder/Uploder";
import { ButtonCustom } from "../../Common/ButtonCustom/ButtonCustom";
import { registerSchema } from "../../validations/registerSchema";
import PhoneInput from "react-phone-input-2";
import { message } from "antd";
import { RegisterMerchent } from "../../redux/features/registerMerchent/register";
import { Link, useNavigate } from "react-router-dom";
import ImageUploader from "../../Common/Uploder/Uploder";
import { useDispatch } from "react-redux";

function RegisterMerchant() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    merchantName: null,
    emailAddress: null,
    phoneNumber: null,
    platformUrl: null,
    logo: null,
    frontIdImage: null,
    backIdImage: null,
  });

  const handlePhoneChange = async (value) => {
    setFormValues({ ...formValues, phoneNumber: value });
    try {
      await registerSchema.validateAt("phoneNumber", { phoneNumber: value });
      setFormErrors({ ...formErrors, phoneNumber: "" });
    } catch (err) {
      setFormErrors({ ...formErrors, phoneNumber: err.message });
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    try {
      await registerSchema.validateAt(name, { [name]: value });
      setFormErrors({ ...formErrors, [name]: "" });
    } catch (err) {
      setFormErrors({ ...formErrors, [name]: err.message });
    }
  };

  const validateForm = async () => {
    try {
      await registerSchema.validate(formValues, { abortEarly: false });
      setFormErrors({}); // Clear errors if validation passes
      return true; // Validate the form
    } catch (err) {
      const validationErrors = err.inner.reduce((acc, error) => {
        return { ...acc, [error.path]: error.message };
      }, {});
      setFormErrors(validationErrors); // Set validation errors
      return false; // Validation failed
    }
  };

  const handleSubmit = async () => {
    const isValid = await validateForm(); // Validate the form

    if (!isValid) return; // Stop if validation fails

    try {
      let payloadData = {
        email: formValues.emailAddress,
        platformName: formValues.platformName,
        platformUrl: formValues.platformUrl,
        name: formValues.merchantName,
        phoneNumber: formValues.phoneNumber,
        logo: formValues.logo,
        idFrontImage: formValues.frontIdImage,
        idBackImage: formValues.baaTWcUnKaKzDckLEgXPx8ckIdImage,
      };

      const result = await dispatch(
        RegisterMerchent.registerMerchentInfoapi(payloadData)
      );
      if (result?.payload?.status === true) {
        message.success(result?.payload?.message);
        message.config({
          duration: 5
        })
        navigate("/");
        setFormValues({
          merchantName: null,
          emailAddress: null,
          phoneNumber: null,
          platformUrl: null,
          logo: null,
          frontIdImage: null,
          backIdImage: null,
        });
      }
      if (result?.payload?.status === false) {
        message.error(result?.payload?.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUploadChange = async (file, fieldName) => {
    const originFile = file.file.originFileObj;
    if (!originFile) {
      return;
    }

    if (originFile.size > 1000000) {
      message.error("Image size should be less than 1MB");
      return;
    }

    const formData = new FormData();
    formData.append("image", originFile);

    try {
      const response = await dispatch(
        RegisterMerchent.registerMerchentImageapi(formData)
      ).unwrap();
      setFormValues((prevState) => ({
        ...prevState,
        [fieldName]: response.data.image_name,
      }));
      setFormErrors((prevState) => ({
        ...prevState,
        [fieldName]: "", // Clear error for the current field
      }));
      message.success("Image uploaded successfully");
    } catch (error) {
      console.error("Upload error:", error);
      message.error("Failed to upload image");
    }
  };

  return (
    <div className="registerMerchant">
      <div className="registerMerchant_main">
        <div className="registerMerchant_left">
          <div className="registerMerchant_left_logo">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="registerMerchant_left_content">
            <img src={WelcomeTriskel} alt="WelcomeTriskel" />
            <h2>
              Welcome to Triskel <br />
              Payment gateway
            </h2>
            <p className="textPayment">
              Merchant Payment gateway is a solution to enable a service for
              crypto enthusiasts to use their funds in day-to-day expenses.
              Crypto is no longer limited to the exchange market, but also
              available for the exchange in the physical market digitally.
            </p>
            <p>
              <strong>
                Signup today and enable crypto payments for your platform with
                Triskel Pay!
              </strong>
            </p>
          </div>
        </div>
        <div className="registerMerchant_right">
          <h3>Register as a Merchant</h3>
          <div className="registerMerchant_right_form">
            <InputCustom
              regularInput
              label="Merchant Name"
              placeholder="Enter Name"
              name="merchantName"
              value={formValues.merchantName}
              onChange={handleChange}
              error={formErrors.merchantName}
              required
            />
            {/* {formErrors.merchantName && (
              <div className="error-message">{formErrors.merchantName}</div>
            )} */}
            <InputCustom
              regularInput
              label="Email Address"
              placeholder="Email Address"
              name="emailAddress"
              value={formValues.emailAddress}
              onChange={handleChange}
              error={formErrors.emailAddress}
              required
            />
            {/* {formErrors.emailAddress && (
              <div className="error-message">{formErrors.emailAddress}</div>
            )} */}

            <CountryPhoneInput
              name="phoneNumber"
              value={formValues.phoneNumber}
              onChange={handlePhoneChange}
              error={formErrors.phoneNumber}
              required
            />
            {/* {formErrors.phoneNumber && (
              <div className="error-message">{formErrors.phoneNumber}</div>
            )} */}

            <InputCustom
              regularInput
              label="Platform URL"
              placeholder="Enter URL"
              name="platformUrl"
              value={formValues.platformUrl}
              onChange={handleChange}
              error={formErrors.platformUrl}
              required
            />
            {/* {formErrors.platformUrl && (
              <div className="error-message">{formErrors.platformUrl}</div>
            )} */}
            <InputCustom
              regularInput
              label="Platform Name"
              placeholder="Enter Name"
              name="platformName"
              value={formValues.platformName}
              onChange={handleChange}
              error={formErrors.platformName}
              required
            />
            {/* {formErrors.platformName && (
              <div className="error-message">{formErrors.platformName}</div>
            )} */}
            <div className="uploderMerchant">
              <div>
                <Uploader
                  label="Merchant Logo"
                  handleUploadChange={(info) =>
                    handleUploadChange(info, "logo")
                  }
                  text="Logo"
                  error={formErrors.logo}
                />
                {/* {formErrors.logo && (
                  <div className="error-message">{formErrors.logo}</div>
                )} */}
              </div>
              <div>
                <ImageUploader
                  label="Upload ID"
                  uploadStyle="picture-card"
                  handleUploadChange={(info) =>
                    handleUploadChange(info, "frontIdImage")
                  }
                  text="Front Id"
                  error={formErrors.frontIdImage}
                />
                {/* {formErrors.frontIdImage && (
                  <div className="error-message">{formErrors.frontIdImage}</div>
                )} */}
              </div>
              <div>
                <Uploader
                  handleUploadChange={(info) =>
                    handleUploadChange(info, "backIdImage")
                  }
                  text="Back Id"
                  error={formErrors.backIdImage}
                />
                {/* {formErrors.backIdImage && (
                  <div className="error-message">{formErrors.backIdImage}</div>
                )} */}
              </div>
            </div>
            <ButtonCustom
              greenbutton
              label="Register"
              htmlType="Register"
              btnBorder={true}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
      <div className="registerMerchant_footer">
        <p>©2024 TRISKEL - All rights reserved.</p>
      </div>
    </div>
  );
}

export default RegisterMerchant;
