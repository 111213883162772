import React from "react";
import "./Home.scss";
import Banner from "./HomeComponets/Banner/Banner";
import DeepDive from "./HomeComponets/DeepDive/DeepDive";
import Explore from "./HomeComponets/Explore/Explore";
import KeyFeature from "./HomeComponets/Feature/KeyFeature";
import OurPartner from "./HomeComponets/OurPartner/OurPartner";
import Roadmap from "./HomeComponets/Roadmap/Roadmap";
import SuperApp from "./HomeComponets/SuperApp/SuperApp";
import Token from "./HomeComponets/Token/Token";
import Transactions from "./HomeComponets/Transactions/Transactions";
import UseCases from "./HomeComponets/UseCases/UseCases";
import WhyTriskel from "./HomeComponets/WhyTriskel/WhyTriskel";
import DownloadSuperApp from "./HomeComponets/Download/DownloadSuperApp";
import Balancing from "./HomeComponets/Balancing/Balancing";
import OurCertificate from "./HomeComponets/OurCertificate/OurCertificate";
import FrequentlyAsked from "./HomeComponets/FrequentlyAsked/FrequentlyAsked";
function Home() {
  return (
    <div className="home">
      <Banner />
      <OurPartner />
      <KeyFeature />
      <OurCertificate />
      <SuperApp />
      <Explore />
      <Token />
      <WhyTriskel />
      <DeepDive />
      <Balancing />
      <Transactions />
      <UseCases />
      <Roadmap />
      <FrequentlyAsked />
      <DownloadSuperApp />
    </div>
  );
}

export default Home;
