import React from "react";
import "./SuperApp.scss";
import { Carousel } from "antd";
import { StoreImages } from "../../../../assets/StoreAsset/StoreImages";
function SuperApp() {
  const {craouselthird,craouselfirst,craouselsecond,craouselfourth,craouselFifth,frame}=StoreImages
  return (
    <div className="superApp">
      <h2>
        Superapp for Web3
        <span> Superapp for Everyone</span>
      </h2>
      <p>
        Designed to serve globetrotters, savvy investors, remote workers, and
        the unbanked populace alike, Triskel unlocks your way to financial
        freedom.
      </p>
      <div className="superApp_mobile">
        <video src={'https://d2j1mnvck8397w.cloudfront.net/superapp.mp4'} muted autoPlay loop playsInline controls={false} />
        <div className="superApp_mobile_data">
          <div className="frame-container">
            <img src={frame} alt="imgs" className="frame-image" />
            <div className="carousel-overlay">
              <Carousel autoplay>
                <div>
                  <img src={craouselfirst} alt="craousel" />
                </div>{" "}
                <div>
                  <img src={craouselsecond} alt="craousel" />
                </div>{" "}
                <div>
                  <img src={craouselthird} alt="craousel" />
                </div>{" "}
                <div>
                  <img src={craouselfourth} alt="craousel" />
                </div>
                <div>
                  <img src={craouselFifth} alt="craousel" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperApp;
