import React from "react";
import "../PolicyMain.scss";
import PageTitle from "../../../Common/PageTitle/PageTitle";
import { spaceChildren } from "antd/es/button";

const welcome = [
  <React.Fragment>
    Triskel Technologies International S.A., an International Sociedad Anónima
    formed and registered in Panama, (hereinafter collectively referred to as
    "Triskel," “Company”, "we," "us," or "our"). provides this Terms of Service
    (the “Terms”) to govern the User (hereinafter collectively referred to the
    “User” or “You” or “Your”) accessing the Website
    https://www.triskel-wallet.com/ (the “Website” or “Site”) and the all
    related tools, Mobile Applications (the “App”), data, software, and the
    Services including Triskel Wallet, OTC platform, STO platform, Prepaid
    Crypto Cards, Payment Gateway (hereinafter collectively referred to as the
    “Services”) provided by us. All such Policies are incorporated by reference
    into these Terms and constitute a legally binding agreement between you and
    Company in relation to your use of the Services. By visiting our Site and/
    or using our Services you agree to be bound by the following Terms,
    including those additional Terms and policies referenced herein and/or
    available on the Site. These Terms apply to all users of the Site, including
    without limitation users who are browsers, vendors, customers, merchants,
    and/ or contributors of content. Please read these Terms carefully and keep
    a copy of them for your reference. Please refer to our Privacy Policy,
    available at [link], for information about how we collect, use, share and
    otherwise process information about you. If you do not agree to all the
    Terms of this agreement, then you may not access the Site or use any of our
    Services. Any new features or tools which are added shall also be subject to
    the Terms of Service. You can review the most current version of the Terms
    at any time on this page. We reserve the right to update, change or replace
    any part of these Terms by posting updates and/or changes to our Site. It is
    your responsibility to check this page periodically for changes. Your
    continued use of or access to the Site following the posting of any changes
    constitutes acceptance of those changes.
  </React.Fragment>,
  <React.Fragment>
    <span className="strong">
      TRISKEL EXPRESSLY DISCLAIMS ANY AND ALL RESPONSIBILITY FOR ANY DIRECT OR
      CONSEQUENTIAL LOSS OR DAMAGE OF ANY KIND WHATSOEVER ARISING DIRECTLY OR
      INDIRECTLY FORM: I) RELYING ON ANY INFORMATION CONTAINED IN THIS DOCUMENT;
      II) ANY ERROR, OMISSION OR INACCURACY IN ANY SUCH INFORMATION; III) ANY
      ACTION RESULTING THEREFROM; IV) USAGE OR ACQUISITION OF PRODUCTS AVAILABLE
      THROUGH THE SITE.
    </span>
  </React.Fragment>,
];

const features = [
  <React.Fragment key="1">
    <strong className="strong">Peer to Peer</strong> - Through the free charge
    of Triskel Wallet’s App, you can find your contacts, easily invite friends,
    and send/receive money internationally from user to user.
  </React.Fragment>,

  <React.Fragment key="2">
    <span className="strong">Lend and Borrow </span>- Lend and Borrow money
    directly to/from another Triskel user, set your own interest rate (even
    zero), with or without digital collateral, through Triskel Capital's unique
    blockchain utilizing smart contracts.
  </React.Fragment>,
  <React.Fragment key="3">
    <span className="strong">Joint Accounts </span>
    -Create and secure personalized Triskel joint accounts together with your
    loved ones and friends around the world.
  </React.Fragment>,
  <React.Fragment key="4">
    <span className="strong">Swap and Deposit </span>- Swap (micro exchange),
    deposit, and withdraw both digital assets and fiat currencies with many
    supported currencies.
  </React.Fragment>,
  <React.Fragment key="5">
    <span className="strong">No delays </span>- With Triskel, you can send or
    receive money, lend or borrow money, and make transactions within a couple
    of seconds, 24/7.
  </React.Fragment>,
];

const BenefitsofRisk = [
  "Triskel users are provided with various features meticulously crafted to deliver a spectrum of benefits, ensuring a seamless and empowering cryptocurrency experience. Triskel’s comprehensive features not only empower users with advanced financial tools and security measures but also foster a user-centric environment where accessibility, convenience, and innovation converge to redefine digital asset management and financial empowerment.",
  <React.Fragment key="1">
    These features are intricately designed to enable users to:
  </React.Fragment>,
  <React.Fragment key="2">
    <span className="strong">Send and receive crypto assets :</span>
    Easily transfer and receive cryptocurrencies with fast, secure transactions.
  </React.Fragment>,
  <React.Fragment key="3">
    <span className="strong">On ramp and Off ramp Services :</span>
    Seamlessly convert between fiat currencies and cryptocurrencies for smooth
    market entry and exit.
  </React.Fragment>,
  <React.Fragment key="4">
    <span className="strong">Swap Crypto Assets :</span>
    Instantly trade one cryptocurrency for another within the platform.
  </React.Fragment>,
  <React.Fragment key="5">
    <span className="strong">OTC Platform :</span>
    Access over-the-counter trading for large-volume cryptocurrency
    transactions.
  </React.Fragment>,
  <React.Fragment key="6">
    <span className="strong">Tokenization platform :</span>
    Create and manage digital tokens representing various assets.
  </React.Fragment>,
  <React.Fragment key="7">
    <span className="strong">
      Digital and Physical Crypto Visa Card collateralized with USDT :
    </span>
    Use a Crypto Visa Card backed by USDT for both online and in-person
    purchases.
  </React.Fragment>,
  <React.Fragment key="8">
    <span className="strong">DeFi Program:</span>
    Participate in decentralized finance opportunities directly through the
    wallet.
  </React.Fragment>,
  <React.Fragment key="9">
    <span className="strong">Referral Program :</span>
    Earn rewards by inviting friends and family to join the platform.
  </React.Fragment>,
  <React.Fragment key="10">
    <span className="strong">Multiple Accounts :</span>
    Manage several cryptocurrency accounts within a single wallet interface.
  </React.Fragment>,
  <React.Fragment key="11">
    <span className="strong">Multi-language support :</span>
    Navigate the platform in multiple languages for a better user experience.
  </React.Fragment>,
  <React.Fragment key="12">
    <span className="strong">Currency Preference :</span>
    Set your preferred currency for displaying balances and transaction values.
  </React.Fragment>,
  <React.Fragment key="13">
    <span className="strong">Price Alert :</span>
    Get real-time notifications on significant price changes for selected
    cryptocurrencies.
  </React.Fragment>,
  <React.Fragment key="14">
    <span className="strong">Address Book :</span>
    Store and manage frequently used crypto addresses for quick and easy
    transactions.
  </React.Fragment>,
  <p className="strong">
    WHEN UTILIZING TRISKEL PLATFORMS OR SERVICES, YOU ACKNOWLEDGE AND ASSUME
    FULL RESPONSIBILITY FOR ANY POTENTIAL RISKS OR CONSEQUENCES. WE DO NOT HOLD
    ANY LIABILITY FOR ANY HARM, LOSSES, OR DAMAGES THAT MAY RESULT FROM YOUR USE
    OF OUR SERVICES, INCLUDING, BUT NOT LIMITED TO, MISUSE, TRANSACTION
    PROBLEMS, TECHNICAL ISSUES, UNAUTHORIZED ACCESS TO USER WALLETS, LEGAL
    MATTERS, OR FRAUD COMMITTED BY THIRD PARTIES. <br />
    <br />
    ADDITIONALLY, YOU AGREE NOT TO EMPLOY OUR PLATFORMS OR SERVICES IN A MANNER
    THAT VIOLATES ANY RELEVANT LAWS OR REGULATIONS. THIS INCLUDES REFRAINING
    FROM USING OUR SERVICES TO FINANCE TERRORISM OR ENGAGE IN ANY CRIMINAL
    ACTIVITIES, BYPASS EXPORT RESTRICTIONS OR ECONOMIC SANCTIONS, OR PARTICIPATE
    IN UNLAWFUL MONEY TRANSMISSION, CURRENCY EXCHANGE, OR MONEY LAUNDERING. IT'S
    IMPORTANT TO NOTE THAT IF YOU ARE PROHIBITED OR RESTRICTED FROM USING SUCH
    SERVICES DUE TO APPLICABLE LAWS AND REGULATIONS, YOU MAY NOT BE ELIGIBLE TO
    USE TRISKEL'S SERVICES.
    <br />
    <br />
    PLEASE MAKE SURE TO ADHERE TO ALL APPLICABLE LAWS AND UTILIZE OUR SERVICES
    RESPONSIBLY. YOUR COOPERATION IS VITAL IN HELPING US MAINTAIN A SECURE AND
    COMPLIANT ENVIRONMENT FOR ALL OF OUR USERS.",
  </p>,
];

const definitions = [
  <React.Fragment key="1">
    <span className="strong">2.1. "Account" </span>
    Refers to the user account established by an individual who has downloaded
    the App or accessed the Site and registered with the Company to utilize the
    Site and the Platform.
  </React.Fragment>,
  <React.Fragment key="2">
    <span className="strong">2.2. "App" </span>
    Signifies the mobile application accessible via the Site or listed on the
    App Store or Google Play store.
  </React.Fragment>,
  <React.Fragment key="3">
    <span className="strong">2.3. “Blockchain” </span>
    Means the public transaction ledger of a given network on which Digital
    Asset transactions are recorded.
  </React.Fragment>,
  <React.Fragment key="4">
    <span className="strong">2.4. “Digital Assets”</span>
    or “Tokens” or “Cryptocurrency” Means blockchain-based software ledger data
    entries such as bitcoins, ripples, Non-Fungible Tokens, and other Digital
    Assets that may be used on the Platform via the Services.
  </React.Fragment>,
  <React.Fragment key="5">
    <span className="strong">2.5. "Funds" </span>
    Means Digital Asset and/or Legal Tender.
  </React.Fragment>,
  <React.Fragment key="6">
    <span className="strong">2.6. “KYC/AML” </span>
    Refers to a set of activities as a result of which information about a
    particular User is obtained. These activities are performed in order to
    determine the scope of financial security measures appropriate for a given
    business relationship or transaction and to assess the risk related to
    counteracting money laundering and terrorist financing, taking into account
    specific types of risk. This set is also regularly updated by Triskel to the
    extent required by law.
  </React.Fragment>,
  <React.Fragment key="7">
    <span className="strong">2.7. "Legal Tender" </span>
    Means any national currency, such as USD, EURO or any other currency, that
    may be used, in connection with a purchase or sale of Digital Assets via the
    Services, and does not include any Digital Asset.
  </React.Fragment>,
  <React.Fragment key="8">
    <span className="strong">2.8. “Private Key” </span>
    Means a cryptographic variable that is used in conjunction with an algorithm
    to encrypt and decrypt data.
  </React.Fragment>,
  <React.Fragment key="9">
    <span className="strong">2.9. “Token” </span>
    Means a unit of value that is created and managed on a blockchain. Tokens
    can represent a wide range of assets, such as cryptocurrency, Digital
    Assets, or even real-world assets like property or arts and it also includes
    Triskel Utility Tokens (TUT) and Triskel Security Tokens (TST).
  </React.Fragment>,
  <React.Fragment key="10">
    <span className="strong">2.10. “User”</span>
    “You” and “Your” Refers to you, the natural person (individual) accessing
    the Site or Software, using our Services and accepting these Terms.
  </React.Fragment>,
  <React.Fragment key="11">
    <span className="strong">2.11. “Wallet” </span>
    Refers to a cryptocurrency wallet created within your Device upon the
    initialization thereof. We use an account structure to organize transactions
    within your wallet.
  </React.Fragment>,
];

const serviceLists = [
  <React.Fragment key="1">
    <span className="strong">Triskel Wallet </span>
    -Triskel is a non-custodial wallet that aims for greater Web 3 adoption and
    the establishment of a DeFi economy, where billions of people can access a
    wide range of financial opportunities and related services. Triskel delivers
    a dependable and customizable non-custodial wallet solution, enabling users
    to oversee, store, and engage in cryptocurrency asset transactions while
    retaining absolute control and ownership of their private keys. Triskel has
    also implemented a biometric 2-layer security and authentication system as
    additional layers of security besides your Wallet private password.
  </React.Fragment>,
  <React.Fragment key="2">
    <span className="strong">OTC Platform </span>
    -Through our strategic partnership with Liminal, we prioritize the
    safeguarding of assets and the efficient management of over-the-counter
    (OTC) transactions. This collaboration bolsters financial stability by
    implementing robust asset protection measures and optimizing transactional
    processes.
  </React.Fragment>,
  <React.Fragment key="3">
    <span className="strong">Prepaid Crypto Cards </span>
    -Triskel elevates global spending with hypercard-backed crypto prepaid
    cards. By integrating hypercard technology, Triskel ensures swift and secure
    payments that are supported by cryptocurrency balances. This innovative
    solution merges the convenience of traditional prepaid cards with the
    versatility of cryptocurrencies, facilitating seamless transactions
    worldwide.
  </React.Fragment>,
  <React.Fragment key="4">
    <span className="strong">STO Platform </span>
    -Triskel launches a secure STO platform, enabling instant access to a
    diverse spectrum of real-world assets. This innovative platform leverages
    blockchain for tokenizing real-world assets and democratizing investment
    opportunities globally while ensuring compliance and security.
  </React.Fragment>,
  <React.Fragment key="5">
    <span className="strong">Payment Gateway </span>-Triskel facilitates
    effortless payment solutions designed for swift and convenient transactions.
    By leveraging advanced fintech capabilities, Triskel offers a seamless
    payment ecosystem for convenient transactions with enhanced speed and
    security, allowing it to conduct transactions easily across global borders.
    Triskel stands at the forefront of innovation, empowering individuals and
    businesses with efficient, reliable payment solutions.
  </React.Fragment>,
];

const WalletserviceList = [
  <React.Fragment key="1">
    <span className="strong">Multi-Chain Storage:</span>
    Securely store your assets across various blockchains.
  </React.Fragment>,
  <React.Fragment key="2">
    <span className="strong">Inbuilt Cross-Chain DEX:</span>
    Easily swap tokens across different chains.
  </React.Fragment>,
  <React.Fragment key="3">
    <span className="strong">Full Fiat Integration:</span>
    Conveniently buy and sell tokens with fiat currencies.
  </React.Fragment>,
  <React.Fragment key="4">
    <span className="strong">Unique Price Alerts:</span>
    Stay informed about significant price changes.
  </React.Fragment>,
  <React.Fragment key="5">
    <span className="strong">dApp Integration:</span>
    Access a wide range of decentralized applications.
  </React.Fragment>,
  <React.Fragment key="6">
    <span className="strong">Contact List:</span>
    Maintain and manage connections for effortless transactions.
  </React.Fragment>,
  <React.Fragment key="7">
    <span className="strong">Enhanced Security:</span>
    Biometric two-layer authentication ensures the utmost protection for your
    assets.
  </React.Fragment>,
];

const signupProcess = [
  "To sign up for Triskel Wallet, users will get access using Web3Auth (https://web3auth.io/index.html) on the platform.",
  "Users agree to provide credentials through Social Media (Facebook, Google, Apple, LinkedIn) or Email Address.",
  "Upon successful signup, a wallet associated with the provided social media handle will be created, and the private key will be sent to us to generate wallet addresses for ETH, BSC, and Matic.",
  "Bitcoin (BTC) and Litecoin (LTC) addresses will be generated using Web3Auth methods.",
  "Users are responsible for remembering their signup details for future access to their wallet. Triskel Wallet is not liable for any issues arising from forgotten credentials.",
];

const setupProcess = [
  "When setting up the Triskel Wallet, users must create a 6-digit 2 Factor Authentication PIN for secure logins, with optional biometric authentication based on device support.",
  "Common number patterns are discouraged for security reasons.After entering the PIN, users will confirm it on a verification screen, and a confirmation popup will ensure the PIN is securely saved.",
  "Users changing devices or reinstalling the app must create a new PIN. If a PIN is forgotten, users can reset it by confirming their password or logging in through a social media platform via Web3Auth.",
  "",
];

const featureDescription = [
  "Users can send cryptocurrencies to any wallet address through various methods, such as manually entering the address, pasting it via long-press, or scanning a QR code..",
  "Additionally, users can access saved contacts in their address book. Users must review and authorize all transactions before submitting them, using either a PIN or biometric verification as applicable.",
  "All transaction details will be recorded in the Transaction History and the coin-specific history.",
  "To receive funds, users can share their wallet address by displaying a QR code, manually copying the address, or using the share icon.",
  "The shared information will include the app and blockchain name with the wallet address.",
  "User responsibilities: Users are responsible for ensuring the accuracy of the wallet addresses they enter or select for transactions.",
  "Users are responsible for maintaining the confidentiality of their account information, including PINs and biometric data. Unauthorized use of your account is prohibited.",
  "Users must comply with all KYC (Know Your Customer) and AML (Anti-Money Laundering) regulations as required by the third-party vendors. Failure to complete these requirements may result in the inability to use the services.",
  "Users are responsible for reviewing and accepting all the fees, including network fees, platform fees, and any additional charges before confirming your transactions.",
  "We disclaim any liability for errors or losses resulting from incorrect addresses or unauthorized transactions. Users are advised to carefully verify all transaction details before authorization",
];

const cryptoOnOffRampFeature = [
  "This feature utilizes a third-party vendor, Alchemy Pay and Transak for crypto on/off ramp services.",
  "Users can buy cryptocurrencies using fiat or sell crypto for fiat currency.",
  "The fiat list displays supported currencies, country names, and payment methods, such as 'US - USD (Apple Pay)' as listed by the third-party vendor you choose.",
  "Upon completing the purchase, users receive crypto deposited into their wallet.",
  "Minimum and maximum values for all currencies are displayed.",
  "The same process applies for selling, where users specify the withdrawal amount to receive fiat in their designated account.",
  "User responsibilities:",
  "Users must comply with all KYC (Know Your Customer) and AML (Anti-Money Laundering) regulations as required by the third-party vendors. Failure to complete these requirements may result in the inability to use the services.",
  "Users are responsible for reviewing and accepting all the fees, including network fees, platform fees, and any additional charges before confirming your transactions.",
  "We are not responsible for any unauthorized access to user accounts due to negligence or failure to adhere to security recommendations.",
];

const swapFeatures = [
  "Cross-chain swaps are facilitated by SWFT.pro, enabling users to exchange cryptocurrencies across different blockchains like ETH to BNB and vice versa, as well as other supported chains.",
  "On-chain swaps via Uniswap (ETH) and PancakeSwap (BNB) enable users to trade tokens within each blockchain network.",
  "User responsibilities:",
  "Users are responsible for ensuring the accuracy of the details they provide, including wallet addresses and conversion amounts.",
  "Users must comply with all applicable regulatory requirements as specified by SWFT.pro. Failure to comply may result in suspension or termination of access to the cross-chain swap services.",
  "Users must adhere to all relevant regulatory requirements as specified by Uniswap, PancakeSwap, and any other service providers involved in on-chain swaps. Non-compliance may result in restrictions on your use of these services.",
  "Users must comply with all regulatory requirements, as specified by the service providers.",
];
const transactionAndPortfolio = [
  "Transaction: Users can initiate a swap by clicking the ‘Swap’ button, which will direct them to the ‘Review Transaction’ screen.",
  "Users can review the transaction by examining all relevant details, including but not limited to: Transaction Amount, Applicable Fees (both in cryptocurrency and fiat), Converted Values, Swap Type, and Network Fees.",
  "Accessing the information icon provides detailed explanations about network fees and transaction methods.",
  "After reviewing the transaction details, users can execute the transaction by clicking the confirm button. Once confirmed, the transaction is final and cannot be reversed, canceled, or altered under any circumstances. You will not be able to undo or modify the transaction once it has been submitted for processing.",
  "Once confirmed, the transaction will be recorded in your transaction history and on the specific coin’s page.",
  "Portfolio: The wallet portfolio consists of two primary tabs:",
  "Wallet Stats: This tab provides a detailed breakdown of your assets, including analytics and a pie chart displaying asset allocation.",
  "Watchlist: This tab tracks market-listed coins and includes features such as search, market prices, trends, and a delete option.",
  "User Responsibilities: It is the user's responsibility to ensure the accuracy of all transaction and portfolio details before proceeding with any action. Users are strongly advised to review all transaction details before confirmation.",
];
const dAppBrowserDetails = [
  "The DApp browser within Triskel Wallet serves as a gateway to decentralized applications (DApps) and decentralized exchanges (DEXs) that operate outside of the wallet environment. It provides an interface for interacting with various DApps running on the Ethereum (ETH), Binance Smart Chain (BSC), and Polygon networks.",
  "Users can seamlessly swap tokens across multiple platforms within the DApp browser. By default, the DApp browser includes listings such as Uniswap, PancakeSwap, Compound, SushiSwap, 1inch, and DYD.",
  "The DApp browser features a search bar that enables global internet searches to help you find and interact with various decentralized applications.",
  "User Responsibilities: You agree that when performing transactions through DApps or DEXs accessed via the DApp browser, you are fully responsible for: Ensuring the accuracy of transaction details, including wallet addresses and amounts, and addressing any issues that arise, such as sending funds to an incorrect address or encountering problems related to the node servers you choose to use.",
];
const addressBookAndCurrencyPreferenceDetails = [
  "The Address Book feature allows you to save and organize wallet addresses by name for efficient access during cryptocurrency transactions. This feature is designed to eliminate the need for repetitive copying and pasting of wallet addresses.",
  "The Currency Preference feature allows users to select the preferred fiat currency from a list, including USD, EUR, NZD, AUD, CAD, MXN, GBP, JPY, CNY, and INR. USD is set as the default currency for all app displays, but you can modify this default to your preferred currency.",
  "Users may customize various wallet settings through the available options on the settings web page.",
  "User Responsibilities: Ensuring that all entries in your Address Book are accurate and up-to-date. Triskel Wallet is not liable for any losses or issues resulting from incorrect or outdated wallet addresses.",
  "Selecting the correct wallet addresses when initiating transactions. Any errors related to address selection are your responsibility.",
  "Users are responsible for reviewing transaction details to ensure that values and amounts are displayed in your selected currency. Triskel Wallet is not liable for any issues arising from incorrect currency settings or misunderstandings related to currency conversion.",
];

const otcPlatformDetails = [
  "Users can purchase TUT (Triskel Utility Token) and USDT using either cryptocurrency or fiat currency.",
  "Before completing any transactions on the OTC platform, users must undergo a verification process managed by the third-party vendor 'Ondato.' This verification is essential for regulatory compliance and secure transactions.",
  "Once a purchase amount is entered, users bind with the Know Your Customer (KYC) process, which redirects the users to the third-party KYC platform for identity confirmation. If users are already verified through the STO or banking platform, their existing verification details can be utilized for OTC transactions.",
  "User Responsibilities: Users are responsible for ensuring the accuracy of their verification information and keeping their KYC details up to date.",
  "You must complete the KYC process to comply with regulatory requirements. Failure to complete the KYC process may prevent you from completing your transaction.",
  "The platform disclaims any liability for issues resulting from incomplete or incorrect verification.",
];

const buySellPageDetails = [
  "When accessing the Buy/Sell page, users will see their available balance for the selected token. They must enter the TUT receiving address, with the default decentralized wallet address shown; however, they can opt for a different address if preferred. This field is optional, and after clicking 'Confirm,' a payment screen will display transaction details, including the USDT amount, TUT value, and fees.",
  "Users can update the Liminal wallet address, choose from payment options such as sending from the Triskel decentralized wallet, copying the address for external payments, or scanning a QR code.",
  "Payments are collected in the Liminal user wallet and consolidated later. After clicking ‘Done,’ users will receive a notification confirming that utility tokens will appear in their OTC wallet once payment is confirmed. Once payment is confirmed, the payment is final and cannot be reversed, canceled, or altered under any circumstances. You will not be able to undo or modify the payment once it has been submitted for processing.",
  "The transaction status will be visible on the history page, and TUTs will be sent from the Admin Cold Wallet to the user's specified wallet.",
  "For ‘Buy with fiat,’ a pop-up will prompt users to select or add a bank account or card. Users must enter bank details or card information and choose to save it if desired.",
  "After clicking ‘Add’ and ‘Proceed,’ the users will be redirected to the Address Confirmation page, followed by a third-party payment gateway to complete the fiat transaction. Once the transaction is confirmed, it is considered to be final and the transaction cannot be reversed or altered.",
  "Upon return, a ‘Payment successful’ pop-up will appear, and users will be redirected to the OTC home screen.",
  "User Responsibilities: Users are responsible for providing accurate information, and the platform is not liable for issues arising from incorrect details. The platform disclaims liability for any issues from incomplete or incorrect payment details.",
];

const assetSaleFeatureDetails = [
  "By using the asset sale feature, users agree to receive proceeds in either crypto or fiat.",
  "The decentralized wallet balance will be displayed, allowing users to specify the TUT amount for sale, with applicable trade limits and dollar values shown.",
  "For crypto sales, users must complete KYC verification to choose between USDT (ERC20) or USDT (TRC20) and provide a receiving address, either default or an alternative. Upon confirmation, users will be redirected to a payment screen with the Liminal address for TUT, with options to pay via the Triskel wallet, external address, or QR code.",
  "Payments are collected in the Liminal wallet and consolidated later, with notifications sent once payment is confirmed and utility tokens appear in the OTC wallet. Once the payment is confirmed, it is considered to be final and the payment and transaction shall not be altered or changed after confirmation.",
  "For fiat transactions, users must select or add a bank account or card and then send the crypto to be sold. Fiat withdrawals are processed to the selected bank account upon receiving a webhook from Liminal.",
  "User Responsibilities: Users are responsible for ensuring the accuracy of their information; including KYC details, receiving addresses, and bank account or card information, is accurate and up to date.",
  "Users must verify and confirm all transaction details before proceeding. Triskel is not liable for any errors or issues resulting from incorrect or incomplete details.",
];

const bankAccountManagementDetails = [
  "Users can view and manage their added bank accounts through the Settings > Bank Details section of the application.",
  "To add a new bank account, you must provide the following details: Banking Name, Routing Number, and Account Number. After entering these details, users can save them by clicking ‘Save.’ This will add the account to the list.",
  "Users can edit an existing bank account by selecting the account and making changes through the editable form.",
  "To delete a bank account, select the account and confirm the deletion via a pop-up message asking: 'Are you sure you want to delete the account details?' Confirming the deletion will remove the account from your list. Before deleting the account, users must verify the account details and move forward with the deletion. Once confirmation is provided, the deleted account cannot be changed or altered.",
  "Users can manage security settings to require a PIN for OTC transactions or when adding new bank accounts. This feature is designed to enhance the security of your transactions and account details.",
  "User Responsibilities: You are responsible for ensuring that all bank account details and security settings are accurate and up to date. This includes providing correct banking information and managing security settings effectively.",
  "Users must ensure the security of the PIN and other access credentials. Triskel is not liable for any unauthorized access or issues resulting from the disclosure or misuse of your PIN or other security details.",
];

const stoVerificationAndInvestmentDetails = [
  "Users must complete the verification process before starting to invest in the STOs.",
  "Users are required to fill out the KYC form and, after clicking 'Save and Continue,' the KYC vendor Ondato provides a form to be filled and validated for authenticity. It is imperative for users to provide accurate information.",
  "Selfie Verification should be completed. After completing the selfie verification, the form will be submitted for verification. This step is crucial for validating the authenticity of the user’s identity.",
  "When a user expresses interest in investing on the Triskel platform for the first time, they will encounter a questionnaire designed to assess their eligibility. Once a user qualifies for their first investment, they will be considered eligible for all future investments. Users must accurately complete this questionnaire to determine their investment eligibility.",
  "User Responsibilities: Users are required to provide accurate and complete information when filling out the KYC form. Inaccurate or incomplete information may lead to delays or rejection of the verification process.",
  "Users are responsible for reviewing all information provided during the verification and investment processes. They must ensure that all details are correct and confirm their actions before proceeding.",
];

const investmentProcessDetails = [
  "Users will see the per unit token price calculated as: Investment Amount / Quantity of tokens to be minted.",
  "The asset name displayed will correspond to the information provided in the proposal form when the STO was published.",
  "Users will input the amount in TUT (Triskel Utility Tokens) they wish to invest in an STO. The minimum investment amount specified in the proposal form will be shown.",
  "Users will also see the equivalent amount in TST (Triskel Security Tokens) converted from the entered TUT value. To initiate the payment process, users must tap 'Proceed to Pay.'",
  "User Responsibilities: The payment action is final and irrevocable once confirmed. Users are responsible for ensuring that they are ready to proceed with the investment before initiating payment.",
  "Users must provide accurate and complete information when entering the investment amount in Triskel Utility Tokens (TUT). It is the user’s responsibility to ensure that all details are correct before proceeding with the investment.",
  "Users must thoroughly review the asset name, token price, and any other relevant details before committing to an investment. It is the user’s responsibility to verify the information and proceed to the next step.",
  "Users should review and verify the equivalent amount of Triskel Security Tokens (TST) displayed as a result of the conversion from TUT. The conversion rate provided is based on current platform rates, and users are responsible for understanding how this rate affects their investment.",
];

const paymentProcessDetails = [
  "Users will be presented with a payment screen displaying all transaction details, including:",
  "The amount to be paid in Triskel Utility Tokens (TUT), the value of receiving Triskel Security Tokens (TST), and the corresponding TUT value. The screen will also show a breakdown of the conversion amount and any applicable fees, as specified in the STO proposal form.",
  "The Liminal wallet address for TUT transactions.",
  "Users have three options for making the payment:",
  "Send: Transfer funds using the Triskel decentralized wallet.",
  "Copy: Copy the wallet address and make the payment through any external wallet.",
  "QR: Tap the QR icon to open a QR code that can be scanned using any compatible wallet.",
  "Before confirming the payment, users are requested to verify the transaction details. Once the payment is confirmed, it cannot be altered or changed. The payment amount will be collected in the Liminal user wallet and can be consolidated at a later stage. After completing the payment, users will see a confirmation pop-up.",
  "User Responsibilities:",
  "Users must carefully review all transaction details on the payment screen, including the amount to be paid, the value of receiving tokens, and any applicable fees, before proceeding with the payment.",
  "Users are responsible for selecting the correct payment method and ensuring that the payment is made to the specified Liminal wallet address.",
  "Users must complete the payment promptly to ensure that their investment is processed without delays.",
  "Users are responsible for the security of their payment information and ensuring the accuracy of the payment transaction. Any issues or discrepancies should be reported to the platform immediately.",
];

const secondaryMarketProcess = [
  "Users can sell their tokens to other users at their desired price. They will be able to view other sale prices listed when setting their own sale price.",
  "When a user decides to sell their tokens, a new position is created for other users to invest in the related proposal. The proposal will be relisted under the ‘Secondary Marketplace’ section of the application.",
  "New buyers can purchase these tokens by following the same workflow as in the primary market. Payments made in TUTs (Triskel Utility Tokens) by the new buyers will be sent to the seller’s decentralized wallet address.",
  "When clicking ‘Invest’ in the secondary market, buyers will see multiple quotes posted by token holders, including per unit price and token quantity. Buyers need to select one of these quotes, adjust the quantity as needed, and proceed with the payment. Before proceeding with the payment, users should verify the details entered for the payment.",
  "Upon clicking ‘Proceed to Pay,’ buyers will follow the same payment cycle as in the primary market. However, the difference is that TUTs will be sent to the seller’s main wallet address (Triskel wallet). Once the payment is done, there shall be no alteration or changes.",
  "User Responsibilities:",
  "Users are responsible for accurately listing their tokens for sale and setting the desired price. They should also review other listed sale prices to make informed decisions.",
  "Users who wish to sell tokens should update their sale quotes from the portfolio section or use the ‘Sell’ button on the proposal details page to post their bids.",
  "Buyers need to carefully select quotes, adjust the quantity of tokens as required, and proceed with payment. They may purchase tokens from different sellers if needed, placing separate orders for each quote.",
  "Buyers must ensure that the payment process is completed accurately and that TUTs are sent to the correct wallet address. They should follow the payment cycle as outlined and verify that TUTs are sent to the seller’s main wallet address.",
];

const holdingsTabInfo = [
  "The Holdings tab provides detailed information on the user’s investments, divided into primary and secondary markets. It includes a comprehensive portfolio based on different types of investment funding the user has participated in.",
  "Upon achieving the soft cap and completion of the proposal, the minting admin will mint tokens according to the tokenomics and proportion of investment. Minting fees are covered by the admin.",
  "If the soft cap is not achieved, refunds will be processed to the non-custodial Triskel wallet address listed at the time of investment. Refunds will be communicated via email.",
  "The Monthly Payments/Rental and Proposal History show details of total invested amount and earnings once defined. This section provides investment details, including date, amount, status, type of payment, and addresses involved.",
  "Users have the ability to send their security tokens to other users in case they want to transfer ownership of those shares.",
  "Users initiate the transfer by clicking on the ‘Send Tokens’ button from their portfolio.",
  "Users must provide the following details:",

  <React.Fragment key="3">
    <strong className="strong"> a. Market :</strong>
    Indicate the market in which the tokens were purchased.
  </React.Fragment>,
  <React.Fragment key="3">
    <strong className="strong"> b. Proposal Name :</strong>
    Select the proposal name from a dropdown menu. The dropdown will only
    display proposals for which tokens have been minted.
  </React.Fragment>,
  <React.Fragment key="3">
    <strong className="strong"> c. Recipient Address :</strong>
    Enter or select the recipient’s address from a dropdown list of whitelisted
    addresses. Users can add new addresses, which must be approved by the
    minting admin.
  </React.Fragment>,
  <React.Fragment key="3">
    <strong className="strong"> d. Number of Tokens :</strong>
    Specify the number of tokens to be transferred.
  </React.Fragment>,
  <React.Fragment key="3">
    Upon clicking the ‘Send’ button, the specified amount of TST will be
    deducted from the sender's wallet and credited to the recipient's wallet.
    Ownership of the tokens will be updated at the contract level. Once the
    transfer of amount is confirmed, there shall be no alterations or changes.
  </React.Fragment>,
  <React.Fragment key="3">
    <strong>User Responsibilities</strong>
  </React.Fragment>,
  "Users are responsible for providing the correct email address where the communication regarding the refunds will be carried out.",
  "Users should regularly monitor their investment data, including the status of minted tokens and any transactions related to their holdings.",
  "Users must accurately enter all required details for the token transfer, including market, proposal name, recipient address, and number of tokens.",
  "Users are responsible for verifying that the number of tokens they wish to transfer does not exceed the quantity they hold. They must check their available token balance before initiating the transfer.",
];

const addressWhitelistingInfo = [
  "Users must whitelist addresses before sending their security tokens to any recipient who has not previously invested on the Triskel platform.",
  // "Users can add a new address through the ‘Add New Address’ form, which requires the following details:",
  "Users can add a new address through the ‘Add New Address’ form, which requires the following details: a. Name of the User: Enter the name associated with the wallet address. ii) Wallet Address: Provide the recipient’s wallet address. b. Select Network: Choose the network (currently static as Matic). iv) Submit Request: After filling out the form, users click the ‘Add’ button to submit the address for whitelisting. The request will be sent to the minting admin panel for approval.",

  // <React.Fragment key="a">
  //   <strong className="strong">a. Name of the User :</strong> Enter the name
  //   associated with the wallet address.
  // </React.Fragment>,
  // <React.Fragment key="b">
  //   <strong className="strong">b. Wallet Address :</strong> Provide the
  //   recipient’s wallet address.
  // </React.Fragment>,
  // <React.Fragment key="c">
  //   <strong className="strong">c. Select Network :</strong> Choose the network
  //   (currently static as Matic).
  // </React.Fragment>,
  // <React.Fragment key="d">
  //   <strong className="strong">d. Submit Request:</strong> After filling out the
  //   form, users click the ‘Add’ button to submit the address for
  //   whitelisting. The request will be sent to the minting admin panel for
  //   approval.
  // </React.Fragment>,
  "The status of the address will be displayed on the address card:",
  <React.Fragment key="e">
    <strong className="strong">a. Approved :</strong> Indicated in green.
  </React.Fragment>,
  <React.Fragment key="f">
    <strong className="strong">b. Approval Pending :</strong> Indicated in
    yellow.
  </React.Fragment>,
  <React.Fragment key="g">
    <strong className="strong">c. Declined :</strong> Indicated in red.
  </React.Fragment>,
  "Users can make investments using any external wallet in TUT, but the address for whitelisting must be the Triskel non-custodial wallet address.",
  // <React.Fragment key="g">
  //   <strong className="strong">User Responsibilities</strong>
  // </React.Fragment>,
  "User responsibilities: Users must accurately fill out the ‘Add New Address’ form with the correct name, wallet address, and network information to ensure proper whitelisting.",
  "Users should monitor the status of their whitelisted addresses to confirm whether they have been approved, are pending, or have been declined. They should act accordingly based on the status updates.",
  "Users must follow all platform policies related to address whitelisting and token transfers, including ensuring that only approved addresses are used for transactions.",
  "Users hold full responsibility for the accuracy and completeness of all details provided. It is essential for users to thoroughly review and verify all information before submitting it. Any errors or omissions in the provided details can result in transaction failures, delays, or other complications that could adversely impact the processing of their transactions. Users are strongly advised to exercise diligence and double-check their information to prevent potential issues and ensure the seamless processing of their transactions. Proper attention to detail is crucial to avoid disruptions and to facilitate a smooth and efficient transaction experience.",
];

const cardApplicationInfo = [
  <React.Fragment key="1">
    <strong className="strong">
      {" "}
      Application for Virtual and Physical Cards:
    </strong>{" "}
    A single primary user applies for virtual and physical cards.
  </React.Fragment>,
  <React.Fragment key="1">
    <strong className="strong">Virtual Card Application:</strong> Virtual cards
    are available for online payments. The application involves filling in
    personal details and completing OTP validation, followed by payment. You
    must provide accurate information and secure your OTP credentials.
  </React.Fragment>,
  <React.Fragment key="2">
    <strong className="strong">Physical Card Application:</strong> Physical card
    applications require additional residential information and a detailed KYC
    process. This includes providing extra documentation such as a selfie and
    government ID proof, followed by a fee. By providing this detail, users
    agree to comply with the KYC requirements and the associated fee.
  </React.Fragment>,
  <React.Fragment key="3">
    <strong className="strong">Activation Steps:</strong> Both card types
    require activation steps. You agree to follow the activation process and
    provide the necessary documentation.
  </React.Fragment>,
];

const referralProgramTerms = [
  <React.Fragment key="3">
    {" "}
    <span style={{ color: "#CFFE25" }}>5.1 User Terms</span> "By participating
    in the Triskel Referral Module, users agree to adhere to the Referral
    program's terms and conditions. These include:
  </React.Fragment>,
  "The requirement for users to purchase prepaid cards to earn referral rewards, which are distributed in both fiat and cryptocurrency.",
  "General users can upgrade to franchisees by making 100 referrals while franchisees can achieve premium status by meeting specified referral targets.",
  "The program prioritizes quick approval processes, cost efficiency, and reasonable compliance measures.",
  "Users must comply with all criteria for upgrades and reward distribution protocols as outlined by Triskel's guidelines.",
  "Users are strictly prohibited from engaging in any illegal activities, including but not limited to fraud, money laundering, and unauthorized use of referral links.",
  "Any involvement in illegal activities will result in immediate suspension from the program, forfeiture of all accrued rewards, and potential legal action.",
  <React.Fragment>
    {" "}
    <span style={{ color: "#CFFE25" }}> 5.2 Company Responsibilities</span> The
    admin panel is responsible for overseeing:
  </React.Fragment>,
  "User activities",
  "Approving upgrades",
  "Managing reward distributions",
];

const termsAndConditions = [
  "(a) You are of legal age to enter into a binding contract (at least 18 years old).",
  "(b) You have not been previously suspended or removed from using our Services.",
  "(c) You are not furthering, performing, undertaking, engaging in, aiding or abetting any unlawful activity through your relationship with us or through your use of the Site;",
  "(d) You shall not reverse engineer, decompile or otherwise disassemble the Site;",
  "(e) You have the full power and authority to enter into this agreement without violating any other agreement you are a party to.",
  "(f) You are not identified as a 'Specially Designated National.'",
  "(g) You are not listed on Interpol's Denied Persons List.",
  "(h) You will not use our Services if any applicable laws in your country prohibit you from doing so according to these Terms.",
];

const serviceRestrictions = [
  "When using Triskel's Site or Services, it is strictly prohibited to transmit any worms, viruses, or any other code of a destructive nature that could harm the functionality of the platform. Additionally, Users are not allowed to reproduce, duplicate, sell, resell, or exploit any part of the Service without obtaining express written permission from Triskel. Respecting intellectual property rights is paramount, and users must not infringe upon or violate them. Uploading or transmitting viruses or malicious code that may impact the Service's operation is strictly forbidden. Furthermore, collecting or tracking personal information of other Users, engaging in spam, phishing, or any similar malicious activities is not permitted.",
  "Interfering with the security features of the Service, attempting to circumvent them, or using bots to access the system will result in restrictions, and access may be denied for users suspected of doing so. Moreover, access to Triskel's Services may be limited if it is suspected that a user is attempting to make purchases from a country that is blocked. Any breach or violation of these terms may lead to an immediate termination of Triskel's Services for the offending user. To maintain a safe and secure environment for all users, compliance with these guidelines is essential.",
];

const violations = [
  "The following are the prohibited uses of this platform :",
  "8.1 Using the Site or any Services to violate any applicable laws or regulations.",
  "8.2 Using the Site or any Services to disrupt or undermine the rights or duties of Triskel Innovation or its Affiliates, other Site Users, or any third party.",
  "8.3 Exploiting any technical issues, malfunctions, delays, or security breaches on the Site or any Services.",
  "8.4 Engaging in actions that are harmful to Triskel or its Affiliates, other Site users, or any third party.",
  "8.5 Access to Triskel Services may be restricted if there is suspicion of attempting purchases from blocked countries.",
  "8.6 Users must avoid infringing upon Intellectual Property Rights or violating such rights.",
  "8.7 Intentionally causing harm to Triskel, Site Users, or any third party through your use of the Site or any Services, except as permitted by applicable law regarding free speech rights.",
  "8.8 Pretending to be another Site User or individual.",
  "8.9 Sending any advertising or promotional materials without our prior written consent, including junk mail, chain letters, spam, or similar solicitations.",
  "8.10 Engaging in activities such as collecting personal information of other users, spamming, phishing, or any similar malicious activities is strictly prohibited.",
  "8.11 Using the Site or any Services if you are a resident or citizen of a country where access to the Site or any Service is prohibited by local laws. By accessing the Site, you do so voluntarily and are responsible for complying with local laws.",
  "8.12 Linking any Triskel Marks or Site content on other Sites without our prior written approval.",
  "Engaging in any of these actions constitutes a 'Prohibited Use'. We reserve the right to address such Prohibited Use at our discretion.",
];

const RiskColoser = [
  "10.1. You acknowledge and accept that engaging with the Platform carries inherent risks. These risks include, but are not limited to:",
  "10.1.1.  Price and liquidity fluctuations: Cryptocurrencies, NFTs, and other Digital Assets offered on the Triskel are highly volatile and may experience significant fluctuations.",
  "10.1.2.  Unintended and/or adverse effects: Technical upgrades or forking of the public blockchain on which Digital Assets are minted or hosted may lead to unintended and adverse effects. ",
  "10.1.3. Legislative and regulatory changes: Changes or actions in legislation and regulations may adversely affect the use, transfer, and value of Digital Assets. ",
  "10.1.4.  Lack of recognition as legal tender: Digital Assets are not recognized as legal tender in most jurisdictions and are thus not backed by any government. ",
  "10.1.5. Irreversible transactions: Transactions involving Digital Assets may be irreversible, and losses due to fraudulent or accidental transactions may not be recoverable. ",
  "10.1.6. Market-dependent value: The value of cryptocurrencies, NFTs, and other Digital Assets offered on the Triskel platform may be derived from market participants's continued willingness to exchange fiat currency or Digital Assets for them, thereby subjecting their value to potential permanent or total loss if the digital asset market disappears.",
  // "10.1.7. Intentionally causing harm to Triskel, Site Users, or any third party through your use of the Site or any Services, except as permitted by applicable law regarding free speech rights.",
  // "10.1.8. Pretending to be another Site User or individual.",
  // "10.1.9. Sending any advertising or promotional materials without our prior written consent, including junk mail, chain letters, spam, or similar solicitations.",
  // "10.1.10. Engaging in activities such as collecting personal information of other users, spamming, phishing, or any similar malicious activities is strictly prohibited.",
  // "10.1.11. Using the Site or any Services if you are a resident or citizen of a country where access to the Site or any Service is prohibited by local laws. By accessing the Site, you do so voluntarily and are responsible for complying with local laws.",
  // "10.1.12. Linking any Triskel Marks or Site content on other Sites without our prior written approval.",
  // "10.1.13. Engaging in any of these actions constitutes a 'Prohibited Use'. We reserve the right to address such Prohibited Use at our discretion.",
  // "This outlines some of the key risks associated with our services. Please note that this is not an exhaustive list of all potential risks. Users are strongly advised to refer to the comprehensive Risk Disclosure Note available on our website for detailed information on the full range of risks involved. By using our services, you acknowledge that you have read, understood, and accept all the risks detailed in the Risk Disclosure Note. You acknowledge that you are solely responsible for evaluating the nature, potential value, suitability and appropriateness of these risks for yourself",
  // "We do not offer advice or recommendations regarding Digital Assets. By accessing and using the Platform, you acknowledge and accept that you do so at your own risk. Furthermore, you understand and agree that we will not be held responsible for any communication failures, disruptions, errors, or distortions you may encounter while using the Platform.",
  <React.Fragment key="3">
    This outlines some of the key risks associated with our services. Please
    note that this is not an exhaustive list of all potential risks. Users are
    strongly advised to refer to the comprehensive Risk Disclosure Note
    available on our website for detailed information on the full range of risks
    involved. By using our services, you acknowledge that you have read,
    understood, and accept all the risks detailed in the Risk Disclosure Note.
    You acknowledge that you are solely responsible for evaluating the nature,
    potential value, suitability and appropriateness of these risks for yourself
  </React.Fragment>,
  <React.Fragment key="3">
    We do not offer advice or recommendations regarding Digital Assets. By
    accessing and using the Platform, you acknowledge and accept that you do so
    at your own risk. Furthermore, you understand and agree that we will not be
    held responsible for any communication failures, disruptions, errors, or
    distortions you may encounter while using the Platform.{" "}
  </React.Fragment>,
  <React.Fragment key="3">
    <strong className="strong">10.2. Internet Transmission Risks </strong> You
    acknowledge the risks associated with utilizing an Internet-based trading
    system, including but not limited to hardware failure, software failure, and
    Internet connection failure. Triskel shall not be responsible for any
    communication failures, disruptions, errors, distortions, or delays you may
    experience while using the Services.
  </React.Fragment>,
  <React.Fragment key="3">
    <strong className="strong">
      ALL OF THE POINTS NOTED ABOVE APPLY TO ALL DIGITAL ASSET TRANSACTIONS.
      THIS BRIEF STATEMENT CANNOT, OF COURSE, DISCLOSE ALL THE RISKS AND OTHER
      ASPECTS ASSOCIATED WITH THESE TRANSACTIONS.{" "}
    </strong>
  </React.Fragment>,
];

const termsAndConditionspharas = [
  {
    heading: "11.1. Waiver of Liability",
    paragraphs: [
      "Your use of the Service is at your sole risk. The Services and products on the Triskel platform are provided on an 'as is' and 'as available' basis. The Services and products are provided without any representation, warranties, or conditions of any kind, whether express or implied. This includes, but is not limited to, warranties of merchantability, fitness for a particular purpose, title, durability, and non-infringement. In no event shall we or any affiliated parties be liable for any damages, whether direct, indirect, incidental, punitive, special, or consequential, arising from your use of the Service, products, or Services. This includes, but is not limited to, lost profits, lost revenue, lost savings, data loss, or replacement costs, even if advised of the possibility of such damages. In jurisdictions where limitations on liability are not allowed, our liability shall be limited to the maximum extent permitted by law.",
    ],
  },
  {
    heading: "11.2. No Prospectus or Offer of Securities",
    paragraphs: [
      "These terms do not constitute a prospectus, offer document, or solicitation for investment in securities in any jurisdiction.",
    ],
  },
  {
    heading: "11.3. Service Continuity",
    paragraphs: [
      "We do not guarantee uninterrupted, timely, secure, or error-free use of our Service. We reserve the right to remove or cancel the Service at any time without notice. The Triskel Services may be subject to disruptions beyond our control.",
    ],
  },
  {
    heading: "11.4. Forward-Looking Statements",
    paragraphs: [
      "Statements made in these terms, webpages, press releases, or accessible to the public may include forward-looking statements. Actual future results and achievements may differ from these statements. Triskel does not make any representation or warranty regarding the accuracy or completeness of the information in these terms.",
    ],
  },
  {
    heading: "11.5. No Business, Legal, or Financial Advice",
    paragraphs: [
      "The information contained in these terms is descriptive in nature only and should not be considered as business, legal, or financial advice for contributing or participating in the development of the Triskel ecosystem or its projects. The information provided on the Triskel whitepaper, Site, and other media is for general informational purposes only and should not be considered investment advice. While we strive to maintain accurate and up-to-date information, we make no warranties regarding its completeness, accuracy, reliability, suitability, or availability. You acknowledge that reliance on such information is at your own risk.",
    ],
  },
  {
    heading: "11.6. Wallets Blacklisting",
    paragraphs: [
      "We reserve the right to blacklist any wallets that we suspect are involved in fraudulent activities, money laundering, or any other illegal purposes. This decision will be made at our sole discretion. Blacklisting may result in the immediate suspension of all associated transactions. Our commitment to regulatory compliance and the security of our platform mandates strict measures against any activities that compromise the integrity and legality of our services. Users whose wallets are blacklisted may be required to provide additional documentation and information to verify their activities and potentially reinstate their wallets, subject to our review and approval.",
    ],
  },
];

const limitation = [
  <React.Fragment>
    PLEASE NOTE THAT THE SERVICES PROVIDED BY TRISKEL ARE OFFERED "AS IS''
    WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES OR REPRESENTATIONS CONCERNING THE
    QUALITY OF THE SERVICES AND ASSOCIATED SOFTWARE. THIS INCLUDES
    FUNCTIONALITY, RELIABILITY, USABILITY, EFFICIENCY, MAINTAINABILITY, HARDWARE
    COMPATIBILITY, CONTINUOUS AND ERROR-FREE OPERATION, COMPLIANCE WITH
    REQUIREMENTS, OR SPECIFIC BUSINESS GOALS. YOU ACKNOWLEDGE THAT YOUR DATA ON
    THE SITE OR THROUGH THE SERVICES MAY BE IRRETRIEVABLY LOST, CORRUPTED, OR
    TEMPORARILY UNAVAILABLE DUE TO VARIOUS CAUSES. TO THE MAXIMUM EXTENT
    PERMITTED BY APPLICABLE LAW, TRISKEL WILL NOT BE LIABLE FOR ANY LOSS OR
    DAMAGE RESULTING FROM SUCH OCCURRENCES. IN NO EVENT WILL TRISKEL BE HELD
    LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY, INCIDENTAL,
    CONSEQUENTIAL, OR SIMILAR DAMAGES OR LIABILITIES ARISING FROM YOUR USE OF
    THE Site AND ServiceS OR YOUR INABILITY TO USE THEM. THIS INCLUDES
    INTANGIBLE DAMAGES, LOSS OF DATA, LOSS OF FUNDS, GOODWILL, OR OTHER BUSINESS
    OR FINANCIAL BENEFITS, EVEN IF TRISKEL HAS BEEN ADVISED OF THE POSSIBILITY
    OF SUCH DAMAGES. YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD TRISKEL HARMLESS,
    ALONG WITH ITS AFFILIATES' RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS,
    OFFICERS, EMPLOYEES, ATTORNEYS, AND AGENTS, FROM ANY CLAIM, DEMAND, LAWSUIT,
    ACTION, PROCEEDING,INVESTIGATION, LIABILITY, DAMAGE, LOSS, COST, OR EXPENSE,
    INCLUDING REASONABLE ATTORNEYS' FEES, ARISING FROM YOUR USE OF, OR CONDUCT
    IN CONNECTION WITH, THE SITE AND SERVICES; YOUR VIOLATION OF THESE TERMS;
    YOUR VIOLATION OF APPLICABLE LAWS OR REGULATIONS; OR YOUR INFRINGEMENT OR
    MISAPPROPRIATION OF THE RIGHTS OF ANY OTHER PERSON OR ENTITY.
  </React.Fragment>,
  "If applicable law does not allow the exclusion or limitation of liability to the extent described, Triskel's liability in such states is limited to the extent permitted by law. Triskel does not own or control the underlying software protocols used with supported blockchain networks. These protocols are generally open-source, and Triskel is not responsible for their operation, functionality, security, or availability. Triskel has no control over any distributed ledger technology transactions and payments. Users must ensure they have a sufficient balance of applicable network tokens in their wallets to complete blockchain transactions before initiation. You understand that markets for cryptocurrencies and Digital Assets are highly volatile. The cost and speed of transacting with blockchain-based systems may vary and can drastically increase or decrease at any time. You acknowledge that Triskel is not responsible for these variables or risks associated with blockchain protocols and cannot be held liable for any resulting losses while accessing or using the Interface.",
];

const indemnification = [
  "To the maximum extent permitted by applicable law, you agree to indemnify, defend, and hold harmless Triskel and its current, past, and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, Service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors, and assigns (collectively referred to as the 'Company Parties') from and against any and all claims, demands, actions, damages, losses, costs, and expenses (including attorneys' fees) arising from or related to:",
  "1. Your use of Services.",
  "2. Your obligations and responsibilities under these Terms.",
  "3. Your violation of any of the provisions of these Terms.",
  "4. Your infringement of the rights of any other person or entity.",
  "Triskel reserves the right to take control of the defense of any claim subject to indemnification under this section, at your expense. This indemnification clause is in addition to, and not in substitution for, any other indemnification provisions set forth in any written agreement with you.",
];

const restrictedJurisdictions = [
  "14.1. Triskel intends to operate across the globe except for FATF & OFAC restricted countries which are namely: Democratic People’s Republic of Korea, Iran, Myanmar, Barbados, Bulgaria, Burkina Faso, Cameroon, Croatia, Democratic Republic of Congo, Gibraltar, Haiti, Jamaica, Mali, Mozambique, Nigeria, Philippines, Senegal, South Africa, South Sudan, Syria, Tanzania, Turkiye, Uganda, United Arab Emirates, Vietnam, Yemen.",
  "14.2. OFAC Specially Designated Nationals and Blocked Persons List (SDN List): OFAC does not maintain a specific list of countries that U.S. persons cannot do business with. However, some countries are subject to broad-based sanctions, such as Cuba and Iran, while others have targeted sanctions focused on specific individuals and entities. Lastly, it is prohibited to purchase or use any of our Products or Services if you are a resident or citizen of a country where the use of Triskel may be prohibited by law.",
];

const licenseAndRestrictions = [
  "In accordance with these Terms, the Company provides you with a limited license that is non-transferable, non-exclusive, and subject to revocation, allowing you to access and utilize the Sites and Services solely for your personal or internal business purposes.",
  "The permissions granted to you under these Terms are bound by certain limitations: You must adhere to the Acceptable Use Policy (AUP) as a general rule. Additionally: (a) You are prohibited from engaging in any commercial exploitation, such as licensing, selling, renting, or distributing the Sites or Services, in whole or in part, along with any content displayed therein; (b) Modification, reverse engineering, or disassembly of any part of the Sites or Services is strictly prohibited; (c) You may not use the Sites or Services to develop similar competitive products, monitor their performance, or for any benchmarking purposes; (d) Except as explicitly permitted, no replication or distribution of the Sites or Services or their content is allowed; (e) Usage of the Site or Services for storing or transmitting harmful code or malicious content is prohibited; (f) Content stored or transmitted via the Site or Services must not infringe upon the rights of third parties or contain unlawful, harassing, or obscene material; and (g) You must not disrupt or interfere with the proper functioning of the Site or Services or any third-party data. Any future updates or enhancements to the Sites or Services will also be governed by these Terms. Furthermore, all copyright and proprietary notices on the Sites or Services must be retained on all copies.",
  // "We present Third-Party Integrations as a service for those interested in such content. Following are the third-party platforms that We have partnered with,",
];

const thirdPartyIntegrations = [
  "Triskel partners strategically with various third-party licensed platforms, providing investors with an integrated solution that combines the advantages of decentralization with the dependability of licensed entities. This smooth integration ensures regulatory compliance and equips investors with a strong platform to navigate the intricate regulatory environment.",
  "Triskel Wallet might offer information about third-party products, services, activities, or events, or allow third parties to share their content and information through the services (collectively, “Third-Party Integrations”).",
  "We present Third-Party Integrations as a service for those interested in such content. Following are the third-party platforms that We have partnered with,",
  <React.Fragment key="1">
    <strong className="strong">Alchemy Pay and Transak:</strong>Triskel has
    incorporated Alchemy Pay and Transak for cryptocurrency transactions on the
    platform. Users opting to use Alchemy Pay for crypto transactions will be
    redirected to the Alchemy Pay website to complete the transaction, which
    includes a mandatory KYC (Know Your Customer) verification process required
    by Alchemy Pay.
  </React.Fragment>,
  <React.Fragment key="2">
    <strong className="strong">SWFT.pro:</strong> We also partner with SWFT.pro
    for cross-chain swaps and Uniswap for on-chain swaps.
  </React.Fragment>,
  <React.Fragment key="3">
    <strong className="strong">Ondato:</strong> For Triskel's Over the Counter
    (OTC) and Security Token Offering (STO) services, KYC verification is
    managed through Ondato, a KYC provider. Users accessing these services are
    redirected to the Ondato platform to complete the KYC process, which
    involves filling out the required KYC form to verify their identity. Triskel
    employs Ondato's services to ensure regulatory compliance and verify the
    identity of users involved in OTC trades and STO participation.
  </React.Fragment>,
  <React.Fragment key="4">
    <strong className="strong">Liminal:</strong> Our partnership with Liminal
    plays a crucial role in safeguarding assets and efficiently managing
    Over-the-Counter (OTC) transactions
  </React.Fragment>,
  "Your dealings with third parties and use of any Third-Party Content are solely between you and the third party.",
  "Triskel strongly advises users to be cautious and informed about the practices of any third-party services they engage with through the platform. Users are responsible for reviewing and understanding the terms of service of these external services to ensure they are comfortable with their data collection, usage, and protection practices.",
  "Triskel does not make any warranties or representations regarding these third-party offerings, including their accuracy, completeness, security, or legitimacy.",
  "We have no control over these third-party entities and are not liable for any harm, loss, or damage arising from your use of their services. Accessing any third-party sites, services, or resources is at your own risk and subject to their respective terms and conditions.",
];

const taxMatter = [
  "Users bear full responsibility for assessing and complying with any tax obligations related to the transactions users conduct using the Services. This includes determining the applicability of taxes and ensuring that the correct amount of tax is reported and remitted to the relevant tax authorities. Triskel shall not be held accountable for making determinations on tax matters concerning your transactions, nor is it responsible for collecting, reporting, withholding, or remitting any taxes arising from the transactions.",
  "As a user of the Services, it is crucial to understand and abide by the tax laws and regulations applicable to your specific circumstances and the jurisdictions involved in your transactions. Failure to fulfill your tax responsibilities could result in legal and financial consequences, and it is incumbent upon you to seek appropriate professional advice to ensure compliance with tax requirements. Triskel operates as a facilitator for transactions and does not provide tax advice or take any responsibility for your tax-related decisions.",
];

const forceMajeure = [
  "Force majeure refers to unforeseen circumstances beyond the control, which could not have been prevented through reasonable means at the time of agreement.",
  "Triskel cannot be held responsible for delays, failures in performance, or Service interruptions resulting directly or indirectly from causes beyond our reasonable control. Such causes include, but are not limited to: acts of God, actions by civil or military authorities, terrorism, civil disturbances, industrial disruptions, embargoes, war, strikes, labor disputes, fire, earthquakes, storms, natural disasters, interruptions in electrical, telecommunications, or Internet Services, failures of hardware or software, utility failures, smart contract vulnerabilities, technological changes, changes in interest rates or monetary conditions, changes to blockchain protocols, catastrophes, or any other occurrences beyond our reasonable control. These events do not affect the validity or enforceability of the remaining provisions of this Agreement.",
  // "These events do not affect the validity or enforceability of the remaining provisions of this Agreement.",
  "If we are unable to perform our Services as outlined in the Terms due to factors beyond our control, including the aforementioned force majeure events or changes in applicable laws or sanctions policies, we shall not be liable for the Services provided under these Terms during the period coinciding with the event in question.",
];

const disputeResolution = [
  "In the event of any dispute, all parties should make an effort to resolve their differences related to the Terms of Use & Service or any product or Service provided by Triskel through informal discussion, negotiation, or arbitration, as described below.",
  "You hereby waive your constitutional, statutory, and other rights to go to court and have a trial before a judge or jury. Additionally, all claims must be arbitrated or litigated individually and not on a class basis. Claims of more than one customer cannot be arbitrated or litigated jointly or consolidated with those of any other customer or user.",
  "All claims and disputes that cannot be settled informally shall be resolved by binding arbitration, as described below. This arbitration agreement applies to you, Triskel, its directors, officers, employees, affiliates, agents, contractors, interns, shareholders, suppliers, Service providers, licensees, successors, or assigns.",
  "Before seeking arbitration, the party must send a Notice of Dispute describing the nature and basis of the claim or dispute and the requested relief. A Notice of Dispute to Triskel should be sent by registered mail to its registered office in Panama. If the claim is unresolved within 30 days, either party may initiate an arbitration proceeding. Any dispute, controversy, difference, or claim arising out of or relating to this contract, including its existence, validity, interpretation, performance, breach, or termination, or any dispute regarding non-contractual obligations arising from or relating to it shall be referred to and finally resolved by arbitration in Dubai International Arbitration Centre. The law governing this arbitration clause shall be in accordance with the Law No 131 of 2013. The seat of arbitration shall be in Dubai. The number of arbitrators shall be three. The arbitration proceedings shall be conducted in English. The decisions of the arbitration center shall be binding on each party and final, and all aspects of the arbitration proceeding, including the arbitrator's award, shall be strictly confidential. In any case, any claim against Triskel shall not exceed the amount that you paid to Triskel for the Services and products.",
];

const miscellaneious = [
  <React.Fragment key="27.1">
    <strong className="strong">27.1. Entire Agreement:</strong> These Terms,
    including any additional terms, rules, and conditions of participation that
    may be posted on the Site, along with the Privacy Policy, constitute the
    complete and exclusive agreement regarding the Interface for Triskel, and it
    supersedes any prior agreements, whether oral or written.
  </React.Fragment>,
  <React.Fragment key="27.2">
    <strong className="strong">27.2. Survival:</strong> If these Terms are
    terminated for any reason, all rights and obligations of the parties that,
    by their nature, are continuing will remain in effect and survive such
    termination.
  </React.Fragment>,
  <React.Fragment key="27.3">
    <strong className="strong">27.3. Severability:</strong> If any provision of
    this agreement is found invalid or unenforceable under applicable law, it
    shall be modified to the minimum extent necessary to make it valid and
    enforceable. If it cannot be made valid and enforceable, it shall be severed
    and deleted from this Agreement. However, the change shall not affect the
    validity of the amended provision or the validity of any other provision of
    this agreement, which will continue in full force and effect.
  </React.Fragment>,
  <React.Fragment key="27.4">
    <strong className="strong">27.4. Assignment:</strong> You consent to
    Triskel's ability to assign any of our rights and/or transfer, subcontract,
    or delegate any of our obligations under these Terms without requiring any
    notice or consent from you. These Terms shall bind and benefit the parties,
    their successors, and permitted assigns. Your agreement to these Terms is
    personal to you, and you are not permitted to transfer or assign it to any
    third party.
  </React.Fragment>,
  <React.Fragment key="27.5">
    <strong className="strong">27.5. Confidentiality:</strong> Triskel and its
    Users mutually agree to uphold the confidentiality of all proprietary
    information exchanged during their collaboration. "Confidential Information"
    encompasses non-public business details, technical data, trade secrets, and
    proprietary methodologies provided in any format. Both parties pledge to
    utilize this information solely for purposes pertaining to this agreement
    and will take necessary precautions to prevent its unauthorized disclosure,
    except as mandated by law or with prior written consent from the other
    party. This confidentiality obligation remains in effect beyond the
    termination of this agreement, ensuring the ongoing interests of both
    parties.
  </React.Fragment>,
  <React.Fragment key="27.6">
    <strong className="strong">27.6. No Waiver:</strong> The failure or delay of
    a party to enforce any provision of this Agreement will not be deemed a
    waiver of the right to enforce that provision or any other provision of this
    Agreement at any time. Any waiver of a provision in this Agreement must be
    in writing, specify the provision to be waived, and be signed by the party
    agreeing to the waiver.
  </React.Fragment>,
  <React.Fragment key="27.7">
    <strong className="strong">27.7. Notices:</strong> Any notice given under or
    in relation to this agreement to the User can be made by publishing it at
    the Site. Similarly, any notice made by the user can be sent to the email
    address provided in the Contact details section of Triskel.
  </React.Fragment>,
];

const TermofUse = () => {
  const welcomes = welcome.map((item) => <p>{item}</p>);
  const feature = features.map((item) => <p>{item}</p>);
  const Benefits = BenefitsofRisk.map((item) => <p>{item}</p>);

  const definition = definitions.map((item) => <p>{item}</p>);
  const serviceList = serviceLists.map((item) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }}>{item}</li>
  ));

  const Walletservice = WalletserviceList.map((item) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }}>{item}</li>
  ));

  const SignupProcess = signupProcess.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));

  const SetupProcess = setupProcess.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));

  const featureDescriptions = featureDescription.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));
  const cryptoOnOffFeature = cryptoOnOffRampFeature.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));
  const swapFeature = swapFeatures.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));
  const transactionPortfolio = transactionAndPortfolio.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));
  const AppBrowserDetails = dAppBrowserDetails.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));
  const addressBookAndCurrencyPreference =
    addressBookAndCurrencyPreferenceDetails.map((step, index) => (
      <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
        {step}
      </li>
    ));
  const otcPlatformDetail = otcPlatformDetails.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));
  const buySellPageDetail = buySellPageDetails.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));
  const assetSaleFeatureDetail = assetSaleFeatureDetails.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));
  const bankAccountManagementDetail = bankAccountManagementDetails.map(
    (step, index) => (
      <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
        {step}
      </li>
    )
  );
  const stoVerificationAndInvestmentDetail =
    stoVerificationAndInvestmentDetails.map((step, index) => (
      <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
        {step}
      </li>
    ));
  const investmentProcessDetail = investmentProcessDetails.map(
    (step, index) => (
      <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
        {step}
      </li>
    )
  );
  const paymentProcessDetail = paymentProcessDetails.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));

  const secondaryMarketProces = secondaryMarketProcess.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));

  const holdingsTabInfos = holdingsTabInfo.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));

  const AddressWhitelistingInfo = addressWhitelistingInfo.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));

  const CardApplicationInfo = cardApplicationInfo.map((step, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {step}
    </li>
  ));

  const referralProgramTerm = referralProgramTerms.map((term, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {term}
    </li>
  ));

  const termsAndCondition = termsAndConditions.map((term, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {term}
    </li>
  ));

  const serviceRestriction = serviceRestrictions.map((term, index) => (
    <p style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {term}
    </p>
  ));

  const violation = violations.map((term, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {term}
    </li>
  ));

  const RiskColser = RiskColoser.map((term, index) => (
    <li style={{ marginBottom: "15px", color: "#9ba0a6" }} key={index}>
      {term}
    </li>
  ));

  const termsAndConditionsphara = termsAndConditionspharas.map((section) => (
    <div key={section.heading}>
      <h6>{section.heading}</h6>
      {section.paragraphs.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  ));

  const Limitation = limitation.map((term, index) => <p key={index}>{term}</p>);
  const Indemnification = indemnification.map((term, index) => (
    <p key={index}>{term}</p>
  ));

  const restrictedJurisdiction = restrictedJurisdictions.map((term, index) => (
    <p key={index}>{term}</p>
  ));

  const licenseAndRestriction = licenseAndRestrictions.map((term, index) => (
    <p key={index}>{term}</p>
  ));

  const thirdPartyIntegration = thirdPartyIntegrations.map((term, index) => (
    <p key={index}>{term}</p>
  ));

  const TaxMatter = taxMatter.map((term, index) => <p key={index}>{term}</p>);

  const ForceMajeure = forceMajeure.map((term, index) => (
    <p key={index}>{term}</p>
  ));

  const DisputeResolution = disputeResolution.map((term, index) => (
    <p key={index}>{term}</p>
  ));

  const miscellaneiou = miscellaneious.map((term, index) => (
    <p key={index}>{term}</p>
  ));

  return (
    <div className="termandUse">
      <PageTitle title="Term of Use" />
      <section id="termofUse">
        <h2>Terms Of Use</h2>
        <h3
          style={{
            color: "white",
            fontSize: "22px",
            lineHeight: "33px",
            fontWeight: "400",
            marginBottom: "24px",
          }}
        >
          Welcome to Triskel - Send, receive, buy, sell, tokenize, and engage in
          various financial activities anytime, anywhere, and in any place
          across 150 countries
        </h3>
        {welcomes}
        <p>
          Triskel offers the ability to send, receive, buy, sell, tokenize and
          engage in various financial activities anytime, anywhere and in any
          place across 150 countries. The platform’s main goal is to transform
          investors into the sole owners and operators of their assets and
          investments. Triskel is more than a decentralized wallet; it's a
          comprehensive financial ecosystem tailored to meet the evolving
          demands of modern investors. By leveraging strategic partnerships and
          a client-centric approach, Triskel paves the way for a new era of
          decentralized financial solutions for the B2B sector. The wallet is
          fortified with advanced encryption methods and ensures that no user
          data is stored within the ecosystem, providing a robust layer of
          protection against breaches. Additionally, Triskel departs from
          traditional financial models, where intermediaries often control
          financial transactions, granting investors exclusive and direct access
          to their accounts.
        </p>
      </section>
      <section id="introduction">
        <h4>Introduction</h4>
        {feature}
      </section>
      <section id="benifitOfTriskel">
        <h5
          style={{ color: "#CFFE25", fontSize: "20px", marginBottom: "20px" }}
        >
          Benefits of Triskel
        </h5>
        {Benefits}
      </section>
      <section id="definitions">
        <h4>DEFINITIONS</h4>
        {definition}
      </section>
      <section id="triskelService">
        <h4> TRISKEL SERVICES</h4>
        <p>
          <span className="strong">3.1. Services :</span>
          Triskel emerges as a solution to a myriad of challenges in the
          financial landscape. Triskel addresses the evolving need of users and
          sets a new standard for decentralized financial solutions. Triskel
          offers a wide range of Services (the “Services”) such as :
        </p>
        <ul style={{ listStyle: "none" }}>{serviceList}</ul>
        <p>
          <span className="strong">3.1. Use of Services :</span>
          You and any other users that create an account with us are each a
          “User” whose use of the Service may include: (a) accessing,
          downloading or using any component of the Service; (b) buying or
          receiving any Tokens via any component of the Service; sending
          messages to or otherwise transacting with any component of the
          Service.
        </p>
        <p>
          <span className="strong">
            WHEN UTILIZING TRISKEL SERVICES, YOU ACKNOWLEDGE AND ASSUME FULL
            RESPONSIBILITY FOR ANY POTENTIAL RISKS OR CONSEQUENCES. WE DO NOT
            HOLD ANY LIABILITY FOR ANY HARM, LOSSES, OR DAMAGES THAT MAY RESULT
            FROM YOUR USE OF OUR SERVICES, INCLUDING, BUT NOT LIMITED TO,
            MISUSE, TRANSACTION PROBLEMS, TECHNICAL ISSUES UNAUTHORIZED ACCESS
            IN THE PLATFORM , LEGAL MATTERS, OR FRAUD COMMITTED BY THIRD
            PARTIES. IN ADDITION, YOU AGREE NOT TO EMPLOY OUR PLATFORMS OR
            SERVICES IN A MANNER THAT VIOLATES ANY RELEVANT LAWS OR REGULATIONS.
            THIS INCLUDES REFRAINING FROM USING OUR SERVICES TO FINANCE
            TERRORISM OR ENGAGE IN ANY CRIMINAL ACTIVITIES, BYPASS EXPORT
            RESTRICTIONS OR ECONOMIC SANCTIONS, OR PARTICIPATE IN UNLAWFUL MONEY
            TRANSMISSION, CURRENCY EXCHANGE, OR MONEY LAUNDERING. IT'S IMPORTANT
            TO NOTE THAT IF YOU ARE PROHIBITED OR RESTRICTED FROM USING SUCH
            SERVICES DUE TO APPLIC
          </span>
        </p>
      </section>
      <section id="specificTermforService">
        <h4>SPECIFIC TERMS FOR SERVICES</h4>
        <p>
          When utilizing the specific services enumerated below, users are
          hereby advised to thoroughly read, comprehend, and comply with the
          below mentioned specific terms of use (the “Specific Terms”)
          applicable to each respective service along with these Terms.
        </p>
        <p>
          <strong className="strong">4.1. Triskel Wallet: </strong> Triskel
          Wallet is on a mission to empower individuals worldwide to regain
          control over their finances. With Triskel App, users can manage their
          own savings and financial transactions like sending, receiving, and
          much more within seconds anytime, anywhere. Triskel also enables users
          to build a community of friends, family, and connections for seamless
          interactions and transactions, eliminating the need for third parties.
          As a Triskel Wallet user, you can access the following benefits:
        </p>
        <ul style={{ listStyle: "none" }}>{Walletservice}</ul>
        <p>
          Triskel Wallet’s secure, versatile, and user-friendly platform
          enhances the cryptocurrency management experience by offering advanced
          security measures, broad asset support, intuitive design, and
          convenient features. Triskel wallet enables the users to create a new
          wallet, when creating a new wallet users must adhere to the following
          steps and guidelines to ensure a secure and efficient setup:
        </p>
        <p className="parrotColour">
          4.1.1. Creation of New Wallet:
          <br />
          4.1.1.1. Signup or Login
        </p>
        <ul style={{ listStyle: "none" }}>{SignupProcess}</ul>
        <p className="parrotColour">4.1.1.2. Create and Confirm Secret Pin</p>
        <ul style={{ listStyle: "none" }}>{SetupProcess}</ul>
        <p className="strong">
          USERS ARE SOLELY RESPONSIBLE FOR SAFEGUARDING THEIR PRIVATE KEYS AND
          LOGIN CREDENTIALS. YOU AGREE TO IMMEDIATELY NOTIFY US OF ANY
          UNAUTHORIZED USE OF YOUR PRIVATE KEY OR ANY OTHER BREACH OF SECURITY
          OF YOUR WALLET. YOU ARE STRONGLY ADVISED TO MAINTAIN A BACKUP OF THE
          PRIVATE KEYS OR PASSWORDS. FAILURE TO SECURELY STORE A BACKUP OF ANY
          WALLET ADDRESS AND ITS CORRESPONDING PRIVATE KEY MAY RESULT IN THE
          IRREVERSIBLE LOSS OF ACCESS TO ANY DIGITAL ASSETS ASSOCIATED WITH THAT
          WALLET ADDRESS. CONSEQUENTLY, WE DISCLAIM ANY RESPONSIBILITY OR
          LIABILITY FOR ANY INABILITY TO ACCESS YOUR WALLET, INCLUDING, BUT NOT
          LIMITED TO, CIRCUMSTANCES ARISING FROM YOUR FAILURE TO SECURE YOUR
          WALLET ADDRESS AND PRIVATE KEY INFORMATION.
        </p>
        <p className="parrotColour">4.1.2. Use of Triskel Wallet Features :</p>
        <p>
          <span style={{ color: "#CFFE25" }}>
            4.1.2.1. Send and Receive Cryptocurrency -
          </span>{" "}
          By accessing and using the send and receive cryptocurrency features of
          Triskel Wallet, you agree to comply with these Terms of Use. Please
          read them carefully
        </p>
        <ul style={{ listStyle: "none" }}>{featureDescriptions}</ul>
        <p>
          <span style={{ color: "#CFFE25" }}>
            4.1.2.2. Buy/Sell Cryptocurrency -
          </span>{" "}
          By accessing and using the buy/sell cryptocurrency features of Triskel
          Wallet, you agree to comply with these Terms of Use. Please read them
          carefully.
        </p>
        <ul style={{ listStyle: "none" }}>{cryptoOnOffFeature}</ul>
        <p>
          <span style={{ color: "#CFFE25" }}>
            4.1.2.3. Cross/on chain swap -
          </span>{" "}
          By accessing and using the cross/on chain swap features of Triskel
          Wallet, you agree to comply with these Terms of Use. Please read them
          carefully.
        </p>
        <ul style={{ listStyle: "none" }}>{swapFeature}</ul>
        <p>
          <span style={{ color: "#CFFE25" }}>
            4.1.2.4. Transaction and Portfolio -{" "}
          </span>{" "}
          By using our transaction and portfolio features, you agree to comply
          with and be bound by the following terms of use. Please review these
          terms carefully before using our services.
        </p>
        <ul style={{ listStyle: "none" }}>{transactionPortfolio}</ul>
        <p>
          <span style={{ color: "#CFFE25" }}>4.1.2.5. DApp Browser - </span> By
          accessing or using the DApp feature, you agree to comply with these
          Terms.
        </p>
        <ul style={{ listStyle: "none" }}>{AppBrowserDetails}</ul>
        <p>
          <span style={{ color: "#CFFE25" }}>
            4.1.2.6. Address Book and Currency Preference -{" "}
          </span>
          By accessing or using the Address book and Currency Preference
          feature, you agree to comply with these Terms.
        </p>
        <ul style={{ listStyle: "none" }}>
          {addressBookAndCurrencyPreference}
        </ul>
        <p>
          By adhering to these guidelines, users can ensure a smooth setup of
          their Triskel Wallet, enabling them to securely manage their digital
          assets and access the full range of features offered by the platform.
        </p>
        <p>
          {" "}
          Notwithstanding anything to the contrary herein, we shall have no
          liability whatsoever in connection with activities that occur on your
          wallet with or without your authorization. When you create a wallet,
          you are strongly advised to take precautions in order to avoid loss of
          access to and/or control over your wallet.
        </p>
        <p style={{ color: "#CFFE25" }}>4.2. OTC Platform</p>
        <p>
          We enhance asset security and streamline over-the-counter (OTC)
          transactions, bolstering financial stability with robust protection
          measures and optimized processes through our strategic partnership
          with Liminal.
        </p>
        <p className="parrotColour">4.2.1. Use of OTC Features</p>
        <p className="parrotColour">4.2.1.1. Buy/Sell Assets</p>
        <ul style={{ listStyle: "none" }}>{otcPlatformDetail}</ul>
        <p className="parrotColour">4.2.1.2. Buy Using Crypto/Fiat</p>
        <ul style={{ listStyle: "none" }}>{buySellPageDetail}</ul>
        <p className="parrotColour">4.2.1.3. Receive in Crypto/Fiat</p>
        <ul style={{ listStyle: "none" }}>{assetSaleFeatureDetail}</ul>
        <p className="parrotColour">
          4.2.1.4. Banking details and security settings
        </p>
        <ul style={{ listStyle: "none" }}>{bankAccountManagementDetail}</ul>
        <p>
          Users are entirely responsible for ensuring the accuracy of the
          details they provide, including but not limited to the bank name,
          account numbers, and payment information. It is crucial for users to
          carefully verify and double-check all entered details before
          submission. Providing incorrect or incomplete information may lead to
          transaction errors, delays, or other issues that could affect the
          processing of their transactions. Users are advised to exercise
          caution when entering their details to prevent potential problems and
          ensure smooth processing of the transactions.
        </p>
        <p className="parrotColour">4.3. STO Platform</p>
        <p>
          Triskel is launching a secure Security Token Offering (STO) platform
          that provides instant access to a diverse range of real-world assets.
          By leveraging blockchain technology, this innovative platform
          tokenizes real-world assets, democratizing investment opportunities
          globally while ensuring compliance and security.
        </p>
        <p>
          {" "}
          By accessing or using our services, you agree to comply with and be
          bound by the following Terms. Please read them carefully.
        </p>
        <p className="parrotColour">4.3.1. Use of STO features</p>
        <p className="parrotColour">4.3.1.1. Account Registration:</p>
        <ul style={{ listStyle: "none" }}>
          {stoVerificationAndInvestmentDetail}
        </ul>
        <p className="parrotColour">4.3.1.2. Investment:</p>
        <ul style={{ listStyle: "none" }}>{investmentProcessDetail}</ul>
        <p className="parrotColour">4.3.1.3. Payment on Liminal</p>
        <ul style={{ listStyle: "none" }}>{paymentProcessDetail}</ul>
        <p className="parrotColour">4.3.1.4. Token Selling</p>
        <ul style={{ listStyle: "none" }}>{secondaryMarketProces}</ul>
        <p className="parrotColour">4.3.1.5. Holdings</p>
        <ul style={{ listStyle: "none" }}>{holdingsTabInfos}</ul>
        <p className="parrotColour">4.3.1.6. Whitelist addresses</p>
        <ul style={{ listStyle: "none" }}>{AddressWhitelistingInfo}</ul>
        <p className="parrotColour">4.4. Pre-paid Crypto Cards</p>
        <p>
          By applying for and using a Triskel Capital Prepaid Card, you agree to
          these Terms of Use. Please read them carefully.
        </p>
        <p>
          <span style={{ color: "#CFFE25" }}>4.4.1. Card features :</span>{" "}
          Triskel enhances global spending through its hypercard-backed crypto
          prepaid cards, utilizing MasterCard's network to facilitate
          transactions and payments worldwide. These cards provide universal
          access for both online and offline transactions, allowing users to
          quickly convert cryptocurrency into cash without the need for bank
          transfers. Triskel enables swift, fee-free cross-border transactions.
          Users can also receive payments from clients around the globe,
          avoiding the delays and costs typically associated with traditional
          banking.
        </p>
        <p className="parrotColour">4.4.2. Application for new card</p>

        <ul style={{ listStyle: "none" }}>{CardApplicationInfo}</ul>
        <p>
          <span style={{ color: "#CFFE25" }}>
            4.4.3. User responsibilities :
          </span>{" "}
          By applying for and using a Triskel Capital Prepaid Card, you agree to
          provide accurate, current and complete information during the
          application process and to update this information if it changes. You
          are responsible for maintaining the confidentiality of your card
          details and login credentials. Unauthorized use of your card or
          account is prohibited. Triskel Capital reserves the right to suspend
          or terminate your account at any time for any reason, including if we
          reasonably believe you have violated these Terms. Triskel Capital is
          not liable for any lost profits, revenues, information or opportunity,
          or any incidental, indirect, exemplary, special, punitive or
          consequential damages arising out of these Terms or your use of the
          prepaid card.
        </p>
        <p>
          Upon utilizing the above-mentioned services, users are required to
          refer to the specific terms governing these services. By proceeding to
          use these services, users acknowledge the acceptance and understanding
          of these obligations. Notwithstanding the foregoing, you acknowledge
          and agree that you shall assume all risks related to the use of the
          services. This includes, but is not limited to, risks associated with
          financial transactions, personal data security, and compliance with
          all applicable laws and regulations. You understand that Triskel
          Capital cannot guarantee the absolute security of your information and
          transactions and that you use the services at your own risk.
          Furthermore, you agree to hold Triskel Capital harmless from any and
          all liabilities, damages, and losses arising from your use of the
          services. By continuing to use these services, you accept full
          responsibility for any outcomes resulting from your interactions with
          the platform.
        </p>
      </section>
      <section id="ReferalProgram">
        <h4>REFERRAL PROGRAM</h4>
        <ul style={{ listStyle: "none" }}>{referralProgramTerm}</ul>
      </section>
      <section id="eligibilityToUseService">
        <h4>ELIGIBILITY TO USE THE SERVICE</h4>
        <p>
          Triskel may not offer its Services in all markets and jurisdictions
          and may restrict or prohibit their use from certain foreign
          jurisdictions ("Restricted Jurisdictions"). If you are registering to
          use the Services on behalf of a legal entity, you confirm that the
          entity is duly organized and validly existing under the applicable
          laws of its jurisdiction, and you are authorized to act on its behalf.
          The Services and certain aspects of the Services, may, as applicable,
          be delayed, restricted, fortified, or ultimately unavailable due to
          certain laws and regulations governing our Services as well as certain
          circumstances and conditions associated with your use of the Services.
          Furthermore, you represent and warrant that:
        </p>
        <ul style={{ listStyle: "none" }}>{termsAndCondition}</ul>
        <p>
          We reserve the right to establish eligibility criteria for specific
          Services or features, which will be communicated on our Site.
          Additionally, we may periodically ask you to confirm that you continue
          to meet these criteria. Our determinations regarding eligibility are
          final. Should these statements be violated, Triskel retains the right
          to pursue damages resulting from such violation. Users are strictly
          forbidden from engaging in unlawful activities that could endanger the
          safety and stability of our Services. We also reserve the right to
          restrict the availability of our Services to any individual,
          geographic location, or jurisdiction, at any time and at our sole
          discretion
        </p>
      </section>
      <section id="genralConditions">
        <h4> GENERAL CONDITIONS</h4>
        {serviceRestriction}
      </section>
      <section id="prohabitedUser">
        <h4> PROHIBITED USE</h4>
        <ul style={{ listStyle: "none" }}>{violation}</ul>
      </section>
      <section id="personalInformation">
        <h4> PERSONAL INFORMATION</h4>
        <p>
          Privacy is a non-negotiable aspect of financial transitions,
          especially in a decentralized environment. By using the Site and
          agreeing to these Terms, you also agree to our Privacy Policy [LINK],
          which governs the submission of your personal information. For an
          explanation on how we collect, use and disclose information from the
          users please visit our privacy policy section on our Site for more
          information.
        </p>
      </section>
      <section id="riskDisclousure">
        <h4> RISK DISCLOSURE</h4>
        <p>
          Privacy is a non-negotiable aspect of financial transitions,
          especially in a decentralized environment. By using the Site and
          agreeing to these Terms, you also agree to our Privacy Policy [LINK],
          which governs the submission of your personal information. For an
          explanation on how we collect, use and disclose information from the
          users please visit our privacy policy section on our Site for more
          information.
        </p>
        <ul style={{ listStyle: "none" }}>{RiskColser}</ul>
      </section>
      <section id="Disclaimer">
        <h4> DISCLAIMER</h4>
        {termsAndConditionsphara}
      </section>
      <section id="limitionOflibility">
        <h4>LIMITATION OF LIABILITY</h4>
        {Limitation}
      </section>
      <section id="indermnification">
        <h4>INDEMNIFICATION</h4>
        {Indemnification}
      </section>
      <section id="restrictedJurisdictions">
        <h4>RESTRICTED JURISDICTIONS</h4>
        {restrictedJurisdiction}
      </section>
      <section id="copyRightIntellectual">
        <h4>COPYRIGHTS AND INTELLECTUAL PROPERTY</h4>
        <p>
          {" "}
          Unless otherwise indicated by us, all copyright and other intellectual
          property rights in all content and other materials contained on our
          Site or provided in connection with the Services, including, without
          limitation, the Triskel or Triskel logo and all designs, text,
          graphics, pictures, information, data, software, sound files, other
          files and the selection and arrangement thereof (collectively,
          "Triskel Materials") are the proprietary property of Triskel or our
          licensors or suppliers and are protected by international copyright
          laws and other intellectual property rights laws. We hereby grant you
          a limited, nonexclusive and non-sublicensable license to access and
          use the Triskel Materials for your personal or internal business use.
          Such license is subject to these Terms and does not permit (a) any
          resale of the Triskel Materials; (b) the distribution, public
          performance or public display of any Triskel Materials; (c) modifying
          or otherwise making any derivative uses of the Triskel Materials, or
          any portion thereof; or (d) any use of the Triskel Materials other
          than for their intended purposes. The license granted under this
          Section will automatically terminate if we suspend or terminate your
          access to the Services. It is acknowledged that all rights, titles,
          and interests in, as well as intellectual property rights such as
          copyrights, patents, trademarks, and trade secrets, within the
          Services, Sites, and their content are the property of the company or
          its suppliers. These terms do not transfer to you or any third party
          any rights, titles, or interests in such intellectual property rights
          The company and its suppliers retain all rights not explicitly granted
          in these terms. No implied licenses are provided under these terms.
        </p>
      </section>
      <section id="licenceRestrictions">
        <h4>LICENSE & RESTRICTIONS</h4>
        {licenseAndRestriction}
      </section>
      <section id="thirdPartyService">
        <h4> THIRD-PARTY SERVICES</h4>
        {thirdPartyIntegration}
      </section>
      <section id="discontinuamceService">
        <h4>DISCONTINUANCE OF SERVICES</h4>
        <p>
          Triskel reserves the right, at its sole discretion and without expense
          to you, to modify or terminate any part of our Services at any time,
          with or without prior notice. It is your sole responsibility to
          maintain an external backup of any wallet address and corresponding
          private key pair stored in your wallet, independent of our Services.
          By maintaining such external backups, you ensure access to the
          blockchain network securing your wallet, enabling full restoration of
          your wallet at any time without incurring costs or losing your Digital
          Assets. Failure to maintain an external backup of your wallet data
          outside of our Services will result in the inability to access the
          Digital Assets associated with your wallet. Triskel shall not be held
          responsible or liable for any loss of Digital Assets if we discontinue
          all or any part of our Services.
        </p>
      </section>
      <section id="warrantyDisclimer">
        <h4> WARRANTY DISCLAIMER</h4>
        <p>
          Triskel Services is provided on an "AS IS" and “AS AVAILABLE” basis,
          without any warranties of any kind. To the fullest extent permitted by
          law, we disclaim all representations and warranties, whether express
          or implied, regarding the Services, underlying software, information,
          content, or any aspect related to the Services. This includes
          warranties of merchantability, fitness for a particular purpose,
          title, non-infringement, freedom from computer viruses, and any
          implied warranties arising from course of dealing, course of
          performance, or usage in trade. We expressly disclaim any warranties
          regarding the accuracy, completeness, availability, currentness, or
          absence of viruses or other harmful components in the content
          accessible via the Services. We do not guarantee that your use of
          Triskel Services will be uninterrupted, timely, error-free, or that it
          will meet your requirements.
        </p>
      </section>
      <section id="transitionFees">
        <h4>TRANSACTION FEES</h4>
        <p>
          Upon the transactions by the user, a transaction fee will be
          associated with your transactions that are required by the system or
          blockchain network that you engage with. The transaction fee comprises
          gas fee, network fee and platform fee. You acknowledge and agree that
          Triskel will not be liable for any failed transactions or losses you
          incur due to incorrectly set transaction fees or due to insufficient
          funds or gas associated with your wallet address.
        </p>
      </section>
      <section id="taxMatter">
        <h4>TAX MATTERS</h4>
        {TaxMatter}
      </section>
      <section id="forceMajeure">
        <h4>FORCE MAJEURE</h4>
        {ForceMajeure}
      </section>
      <section id="termination">
        <h4> TERMINATION</h4>
        <p>
          Upon termination of your license to use Triskel Services, your
          obligations under this Terms shall remain in effect. This means that
          even after your access to Triskel Services has ended, you are still
          bound by the responsibilities and commitments outlined in these Terms.
        </p>
      </section>
      <section id="termOrservice">
        <h4>UPDATES TO THE TERMS OR SERVICE</h4>
        <p>
          Triskel reserves the right to update or modify these Terms at any time
          at our sole discretion. If we do so, we’ll let you know by either
          posting the revised Terms on our Site, on our mobile application or
          through other methods of communication which we deem reasonable. Such
          revised Terms as posted will take effect immediately, unless otherwise
          indicated. You should regularly check our Site to inform yourself of
          any such changes and decide whether or not to accept the revised
          version of these Terms. If you continue to use Triskel following any
          update or modification of the Terms you shall be deemed to have
          accepted the revised Terms. If you do not agree to the Terms or any
          update or modification to the Terms, you must cease to access or use
          our Services. Our Services are evolving over time, we may change or
          discontinue all or any part of the Services, at any time and without
          prior notice, and at our sole discretion.
        </p>
      </section>
      <section id="errorOmission">
        <h4>ERRORS, INACCURACIES AND OMISSIONS</h4>
        <p>
          There may be occurrences of typographical errors, inaccuracies, or
          omissions in the information presented on our Site or within the
          Service, including product and Service descriptions, pricing,
          promotions, offers, or availability. We reserve the right to rectify
          any such errors, inaccuracies, or omissions and may modify or update
          information or cancel any details in the Service or on any associated
          Site that is found to be inaccurate without prior notice. While we
          strive to provide accurate and up-to-date information, we are not
          obligated to continually update, amend, or clarify information in the
          Service or on related Sites, including pricing details, except where
          required by law. The absence of a specified update or refresh date
          should not be interpreted as an indication that all information in the
          Service or related Site has been modified or updated.
        </p>
      </section>
      <section id="governingLaw">
        <h4> GOVERNING LAW AND DISPUTES</h4>
        <h6>26.1. Governing Law & Disputes</h6>
        <p>
          These Terms of Use & Service and any separate agreements whereby
          Triskel provides you with Services shall be governed by and construed
          in accordance with the Laws of Panama.
        </p>
        <h6>26.2. Waiver</h6>
        <p>
          Both parties (you and Triskel) waive their rights to go to court and
          have a trial before a judge or a jury. Furthermore, all claims must be
          arbitrated or litigated individually and not on a class basis. Claims
          of more than one customer cannot be arbitrated or litigated jointly or
          consolidated with those of any other customer or user
        </p>
        <h6>26.3. Claims Settlement</h6>
        {DisputeResolution}
      </section>
      <section id="miscellaneous">
        <h4>MISCELLANEOUS</h4>
        {miscellaneiou}
      </section>
      <section id="contactInfo">
        <h4>CONTACT INFORMATION</h4>
        <p>
          For any questions or feedback regarding any concerns relating to the
          Terms, or your use of Triskel Wallet and our Services, please contact
          us at https://www.triskel-wallet.com/contactUs.
        </p>
      </section>
    </div>
  );
};

export default TermofUse;
