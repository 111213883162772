import React from "react";
import "../PolicyMain.scss";
import PageTitle from "../../../Common/PageTitle/PageTitle";

const riskDisclosureNote = [
  <React.Fragment key="1">
    "Welcome to the Triskel platform, a non-custodial decentralized wallet, that
    aims for greater web3 adoption and establishment of a DeFi economy, where
    billions of people can access a wide range of financial opportunities and
    related services. Through our non-custodial crypto wallet, we offer users a
    diverse range of financial services, customized to deliver effortless access
    and comprehensive management of cryptocurrency assets. At Triskel, the
    safety and security of our users' funds and personal data are paramount. We
    are committed to providing a user-friendly platform while ensuring that our
    users understand the associated risks of our services. As part of our
    commitment, we have crafted this Risk Disclosure Note (the{" "}
    <strong className="strong"> “Risk Disclosure Note”</strong>) to educate all
    stakeholders about the potential risks of using a non-custodial wallet &
    investing in cryptocurrencies. This disclosure applies to all aspects of our
    operations.
    <br /> <br />
    This Risk Disclosure Note applies to Triskel Technologies International
    S.A., an International Sociedad Anónima formed and registered in Panama,
    (hereinafter collectively referred to as "Triskel," “Company”, "we," "us,"
    or "our"). This Privacy Policy describes the policies and procedures of
    Triskel pertaining to the collection, use, and disclosure of user’s
    information. This includes but is not limited to the Triskel website, all
    its subdomains, and associated applications [the{" "}
    <strong className="strong">“App”</strong>, together with the website{" "}
    <span style={{ color: "#CFFE25" }}>https://www.triskel-wallet.com/</span>{" "}
    (the <strong className="strong">“Website”</strong>), collectively referred
    to as the <strong className="strong"> Platform</strong> .
  </React.Fragment>,
  <React.Fragment key="1">
    We utilize{" "}
    <span style={{ color: "#CFFE25" }}>https://web3auth.io/index.html</span>{" "}
    (“Web3auth”) for non-custodial wallet creation on our platform. Users
    maintain custody of their wallets by linking them to their social login
    accounts.
  </React.Fragment>,
  "At Triskel, we empower users with unparalleled control over their digital assets, fostering financial autonomy and security in a rapidly evolving landscape. Leveraging blockchain technology, we ensure fully secure transactions without storing sensitive data, setting a new standard for privacy and reliability. Our platform facilitates seamless trade between fiat and cryptocurrencies, integrating decentralized technologies with traditional banking practices for a dynamic hybrid ecosystem. With global acceptance through the MasterCard Network, Triskel supports transactions in both digital and physical marketplaces. Whether you're managing non-custodial digital assets, exploring comprehensive financial services, or benefiting from advanced security measures, Triskel provides a user-friendly interface and robust features designed to meet the diverse needs of all users, from novices to seasoned investors.",
  "BY AVAILING OUR SERVICES OR PARTICIPATING IN OUR OFFERINGS YOU ARE ACKNOWLEDGING AND ACCEPTING THE RISKS ASSOCIATED WITH OUR OFFERINGS AND ARE CONFIDENTLY PARTICIPATING IN OUR ECOSYSTEM TO CONTRIBUTE TO ITS GROWTH AND SUCCESS.",
  "We strongly encourage all our stakeholders, including investors, users, and partners, to read and understand this Risk Disclosure Note carefully. BY PARTICIPATING IN OUR ECOSYSTEM, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND ACCEPTED THE RISKS OUTLINED IN THIS DOCUMENT.",
  "As you become involved in our ecosystem, we would like to remind you that investment in cryptocurrencies projects is subject to risks. WE STRONGLY ENCOURAGE ALL STAKEHOLDERS TO METICULOUSLY DELIBERATE THEIR INVESTMENT DECISIONS AND ACKNOWLEDGE THE POTENTIAL RISKS INVOLVED.",
  "Thank you for joining us on our journey.",
];

const introduction = [
  "At Triskel, we are committed to providing a safe and secure platform for our users to use our non-custodial crypto wallet designed to provide seamless access to a wide range of financial services. However, we recognize that there are inherent risks associated with these technologies, as well as with our business model and operations. As such, we have developed this Risk Disclosure Note to provide transparent and comprehensive information regarding potential risks that stakeholders may face when engaging with our platforms. We encourage all stakeholders to carefully review and understand this information before accessing our Services and making any investment or participation decisions.",
];
const genralRisk = [
  "By accessing and using the Platform and services, you represent that you are financially and technically sophisticated enough to understand the risks associated with using cryptographic and blockchain-based systems, and that you have a working knowledge of the usage and intricacies of digital assets. And further understand that blockchain-based transactions are irreversible and the markets for these digital assets are highly volatile due to factors including but not limited to adoption, speculation, technology, security, and regulation.",
  "You acknowledge and accept that the cost and speed of transacting with cryptographic and blockchain-based systems are variable and may increase dramatically at any time. We may integrate with third-party services or platforms, and the use of such services may be subject to additional risks and terms of service beyond Triskel’s control. Legislative and regulatory changes or actions may adversely affect the use, transfer, and value of the digital assets.",
  "We cannot be held liable for any resulting losses that you experience while accessing or using the website or services. Accordingly, you understand and agree to assume full responsibility for all of the risks of accessing and using the website and services. You understand and agree that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself. We do not give any advice or recommendations regarding digital assets. You understand and agree that we will not be responsible for any communication failures, disruptions, errors, or distortions you may experience when using the Platform.",
];

const accociatedWithCurrencies = [
  "Triskel Wallet supports a wide range of cryptocurrencies and tokens on our mobile platform. By providing extensive support for various digital assets, we aim to foster inclusivity and accessibility within the application.",
  "3.1 Investing in cryptocurrencies carries significant risks. It is essential for you to evaluate whether participation on our platform aligns with your financial situation and risk tolerance. Cryptocurrency values can fluctuate greatly, potentially losing substantial value or becoming worthless. Past performance does not guarantee future results. We have no control over market fluctuations and make no claims or guarantees regarding actual or perceived values.",
  "3.2 Triskel acknowledges that smart contracts, as human-coded programs, may contain bugs or vulnerabilities. To mitigate these risks, Triskel conducts rigorous testing and review procedures to ensure smart contracts are secure and function as intended before deployment, minimizing potential unintended consequences or financial loss.",
  "3.3 Triskel prioritizes the security of digital assets and user data. We implement robust security measures, including secure wallets, multi-factor authentication, and regular security audits, to protect against hacking, phishing, and other cyber threats.",
  "3.4 Aware of the impact of global economic conditions on cryptocurrency markets, Triskel prepares for potential downturns by diversifying strategies and maintaining a stable financial base to withstand market pressures.",
  "3.5 By using the Triskel Wallet app, you acknowledge and accept that Triskel bears no responsibility or liability for any losses, including the loss of cryptocurrencies, resulting from your participation on the app and the use of your Triskel Wallet account.",
];

const wallet = [
  {
    heading: "4.1. Sole Responsibility for Security",
    description:
      "As a user of a non-custodial wallet, you bear the sole responsibility for the security of your private keys and seed phrase. If you lose or compromise these security elements, it will result in the permanent loss of your funds, as there is no method to recover them.",
  },
  {
    heading: "4.2. Potential for Human Error",
    description:
      "Securing your private keys and seed phrases correctly requires a thorough understanding of crypto security best practices. Even minor mistakes, such as storing them insecurely, can lead to theft or loss of funds.",
  },
  {
    heading: "4.3. Higher Technical Knowledge Required",
    description:
      "Non-custodial wallets typically have less user-friendly interfaces compared to custodial wallets. Effective use of these wallets necessitates a higher level of technical knowledge about private keys, seed phrases, and blockchain transactions.",
  },
  {
    heading: "4.4. Potential for Irreversible Mistakes",
    description:
      "Blockchain transactions are irreversible. If you mistakenly send your cryptocurrency to the wrong address or fall victim to a scam, there is no way to recover the funds.",
  },
  {
    heading: "4.5. Potential for Permanent Loss",
    description:
      "If you lose your private keys or seed phrase, your cryptocurrency will be permanently inaccessible, with no means to recover it. This risk is significant and must be taken seriously by all users.",
  },
];

const cyberSecurityRisk = [
  "Storing and transferring cryptocurrencies digitally exposes them to cybersecurity threats such as hacking, phishing, and theft. These risks can result in the misappropriation or loss of tokens and unauthorized interference with transaction data. To mitigate these cybersecurity risks, stakeholders should adopt protective practices, including the use of secure wallets, multi-factor authentication, and careful safeguarding of their private keys.",
];

const financialInstrumentRisks = [
  "Triskel Wallet does not set or influence the prices of financial instruments available for purchase or sale. These prices are subject to numerous external factors and can undergo rapid and unpredictable changes. Consequently, the value of these instruments, as well as the potential profits or losses on your positions, may fluctuate quickly and without warning.",
  "Additionally, the prices of financial instruments may experience phenomena such as gapping, where the opening price of an instrument significantly diverges from its closing price the previous day, and slippage, which occurs when the execution price of an instrument differs from the price displayed at the time you placed your order. These issues are particularly common during times of market volatility.",
];

const liquidityRisk = [
  "Liquidity risks pertain to the ease with which a token can be bought or sold quickly without significant price changes. Cryptocurrencies and tokens are subject to liquidity risks, which may arise from scenarios like reduced trading volumes, insufficient market depth, or the scarcity of trading venues. This lack of liquidity can hinder the ability to purchase or dispose of tokens at fair market rates, potentially causing substantial price fluctuations and wider bid-ask spreads on Triskel Wallet",
];

const smartContect = [
  "Users acknowledge and agree that engaging with smart contracts on the Triskel platform involves various risks, including but not limited to, technical flaws, operational failures, and external exploits. By using any smart contract functionalities provided by Triskel, the user accepts the following risks:",
  <React.Fragment key="1">
    <span style={{ color: "white", marginRight: "5px" }}>
      8.1 Technical Risks :
    </span>
    Smart contracts are entirely software-driven and executed autonomously on
    the blockchain. They are subject to errors, bugs, or vulnerabilities, which
    may result in unexpected outcomes such as the loss or freezing of tokens or
    assets, incorrect transactions, or irreversible operations without the
    possibility of redress.
  </React.Fragment>,
  <React.Fragment key="2">
    <span style={{ color: "white", marginRight: "5px" }}>
      8.2 Operational Risks :
    </span>
    The underlying code of any smart contract on Triskel may contain weaknesses,
    vulnerabilities, or bugs, causing it to function in an unintended manner.
    Despite developers' efforts and audits of smart contract code, there remains
    an inherent risk that smart contracts could fail to perform as intended.
  </React.Fragment>,
  <React.Fragment key="3">
    <span style={{ color: "white", marginRight: "5px" }}>
      8.3 Regulatory Risks :
    </span>
    The regulatory status of blockchain technologies, including smart contracts,
    is unclear or unsettled in many jurisdictions. Changes in laws or
    regulations governing blockchain technology and smart contracts could
    materially and adversely affect the use, transfer, exchange, and value of
    smart contracts.
  </React.Fragment>,
  <React.Fragment key="4">
    <span style={{ color: "white", marginRight: "5px" }}>
      8.4 Third-Party Dependencies :
    </span>
    Certain smart contracts interact with or rely on third-party services, which
    may themselves contain vulnerabilities or be subject to delay, disruption,
    or discontinuation. Such dependencies can lead to failures in the smart
    contract's intended operations.
  </React.Fragment>,
  <React.Fragment key="5">
    <span style={{ color: "white", marginRight: "5px" }}>
      8.5 Network Risks :
    </span>
    Smart contracts operate on the blockchain network and are affected by the
    performance and operational dynamics of the network, including forked
    chains, mining attacks, majority mining power attacks, and congested
    blockchain networks. These issues can potentially lead to major delays or
    faults in processing transactions.
  </React.Fragment>,
  "Triskel is not liable for any malfunction, unintended function, unexpected functioning of, or attack on the blockchain technology that affects transactions with smart contracts. Triskel does not have custody or control over the blockchain networks or smart contracts that operate on them and cannot recover any funds lost or affected in such contexts.",
  "Users are fully responsible for taking the necessary precautions to evaluate the risks and ensure the security of their blockchain transactions. This includes reviewing the smart contract code or seeking external professional advice. By engaging with smart contracts on Triskel, users acknowledge that Triskel is not responsible for the associated operational and security risks and agree that Triskel is not liable for any corresponding losses incurred.",
  "This disclosure does not represent an exhaustive list of risks associated with the use of smart contracts on the Triskel platform. Users are encouraged to conduct comprehensive due diligence and consult with appropriate technical and legal experts before initiating transactions involving smart contracts.",
];

const thirdPartyAndCounterpartyRisks = [
  {
    heading:
      "9.1. Risks associated with third-party service providers and platforms",
    description:
      "Triskel may rely on third-party service providers and platforms to deliver their services. These third-party entities may introduce their own set of risks, which could indirectly impact the operations of Triskel. For instance, disruptions or bankruptcy filings by payment processors or exchanges could disrupt user access and transaction capabilities. To mitigate these risks, Triskel must conduct thorough due diligence on third-party service providers and establish contingency plans to address disruptions effectively.",
  },
  {
    heading: "9.2. Counterparty risks in transactions and trades",
    description:
      "Engaging in transactions and trades on Triskel exposes users to counterparty risks, whereby the other party involved may fail to fulfill their obligations. These risks are particularly pronounced in decentralized transactions, where the absence of a central authority makes enforcing contractual agreements challenging. To mitigate counterparty risks, Triskel may implement escrow services, smart contract-based agreements, and reputation systems to enhance user protection and trust. However, users must remain vigilant and exercise caution when participating in transactions on the platform.",
  },
];

const legalAndRegulatoryRisks = [
  {
    heading: "10.1. Risks related to non-compliance with laws and regulations",
    description:
      "Triskel operates within a complex regulatory landscape, subject to laws and regulations governing various aspects of its operations, including securities, anti-money laundering (AML), data privacy, and consumer protection. Non-compliance with these laws and regulations can result in severe penalties, legal disputes, and reputational damage, undermining user trust and platform credibility. Moreover, tax compliance requirements further compound regulatory complexities, necessitating rigorous adherence to regulatory standards and proactive risk mitigation strategies.",
  },
  {
    heading: "10.2. Risks associated with unclear or undefined activities",
    description:
      "Cryptocurrency and blockchain technology in general are still developing, and the regulatory frameworks governing these activities may be unclear or undefined. This may result in regulatory uncertainty, and the platforms may be subject to legal and regulatory risks as a result. Additionally, the platforms may engage in activities that are not explicitly regulated, which may pose legal and regulatory risks.",
  },
  {
    heading:
      "10.3. Risks associated with changing or unclear legal and regulatory frameworks",
    description:
      "Triskel operates within a complex regulatory landscape, subject to laws and regulations governing various aspects of its operations, including securities, anti-money laundering (AML), data privacy, and consumer protection. Non-compliance with these laws and regulations can result in severe penalties, legal disputes, and reputational damage, undermining user trust and platform credibility. Moreover, tax compliance requirements further compound regulatory complexities, necessitating rigorous adherence to regulatory standards and proactive risk mitigation strategies.",
  },
  {
    heading:
      "10.4. Risks related to government intervention or bans on cryptocurrency",
    description:
      "Government intervention or regulatory bans on cryptocurrency-related activities pose significant risks to Triskel’s operational continuity and market competitiveness. Such interventions may restrict access to cryptocurrency markets, impose regulatory burdens, or undermine investor confidence, adversely impacting platform adoption and user trust. Moreover, regulatory uncertainty surrounding government actions can create market volatility and hinder long-term strategic planning efforts, necessitating agile risk management strategies to navigate regulatory challenges and safeguard platform sustainability.",
  },
];

const softwareAndHardwareRisks = [
  {
    heading: "11.1.  Risks associated with software bugs, glitches and errors",
    description:
      "Software vulnerabilities, including bugs, glitches, and coding errors, pose significant risks to the operational integrity of Triskel. These vulnerabilities could result in financial losses, data breaches, or system outages, undermining user confidence and trust in the platform. Furthermore, smart contracts are susceptible to coding errors and vulnerabilities, which may lead to unintended consequences or security breaches. Triskel must proactively address software vulnerabilities through comprehensive testing protocols, regular security audits, and timely software updates to maintain platform reliability and resilience.",
  },
  {
    heading: "11.2. Risks of hardware failure or malfunction",
    description:
      "Triskel relies on robust hardware infrastructure to support its operations, including servers, data storage systems, and network equipment. Hardware failures or malfunctions pose significant risks to platform stability and data integrity, potentially resulting in service disruptions or data loss. Physical security threats, such as theft or damage to hardware components, further compound the risk landscape, necessitating comprehensive risk mitigation strategies. Triskel must invest in robust hardware redundancy measures, physical security protocols, and disaster recovery plans to safeguard against hardware-related risks effectively.",
  },
  {
    heading:
      "11.3. Risks related to network connectivity and data transmission",
    description:
      "Network connectivity and data transmission vulnerabilities present significant risks to the operational resilience of Triskel. These vulnerabilities may manifest in various forms, including data breaches, network outages, and cyber attacks, which could compromise user data confidentiality and platform availability. Moreover, reliance on third-party service providers introduces additional risks, such as service disruptions or data breaches, which may impact Triskel’s operational continuity. To mitigate network-related risks, Triskel must implement robust network security measures, including intrusion detection systems, data encryption protocols, and network redundancy configurations to ensure uninterrupted service delivery and data protection.",
  },
];

const businessAndCompetitionRisks = [
  {
    heading: "12.1.  Risks associated with business model and strategy",
    description:
      "Introducing new and innovative business models, as envisioned by Triskel, inherently entails greater risks due to their untested nature. Such risks may include consumer acceptance challenges, regulatory compliance complexities, and scalability concerns. Furthermore, revenue models adopted by the platform, including transaction fees and advertising revenue streams, may face uncertainties regarding their effectiveness and sustainability. Competing against established industry players further exacerbates the risk landscape, potentially impacting user acquisition and retention efforts.",
  },
  {
    heading:
      "12.2. Risks related to competition, market saturation, and demand",
    description:
      "The cryptocurrency industry is characterized by intense competition and rapid innovation, with numerous platforms vying for market share and user attention. Triskel may face challenges arising from market saturation, price pressures, and shifting consumer preferences, which could undermine its competitive positioning and revenue generation capabilities. Moreover, macroeconomic factors, such as economic downturns and fluctuations in consumer demand, may exacerbate market uncertainties, necessitating proactive risk management strategies.",
  },
  {
    heading: "12.3. Development risks",
    description:
      "Developing and deploying new products and features inherently involves risks, including delays, cost overruns, and technical challenges. Triskel may encounter difficulties in aligning development timelines with user expectations, potentially limiting the platform's growth trajectory. Moreover, reliance on third-party developers and service providers introduces additional complexities, such as dependency risks and coordination challenges. To mitigate development risks, Triskel must prioritize robust project management practices and cultivate strategic partnerships with reliable technology partners.",
  },
];

const economicAndGeopoliticalRisks = [
  {
    heading:
      "13.1. Risks associated with inflation or deflation of cryptocurrency prices",
    description:
      "Cryptocurrency markets are highly susceptible to price volatility, driven by macroeconomic factors, supply-demand imbalances, and regulatory developments. Fluctuations in cryptocurrency prices can significantly impact Triskel’s financial performance and user sentiment, necessitating proactive risk management strategies. Moreover, the 24/7 nature of cryptocurrency markets amplifies price volatility, requiring continuous monitoring and risk mitigation efforts to safeguard user interests and platform stability.",
  },
  {
    heading: "13.2. Risks related to economic cycles and downturns",
    description:
      "The global economy experiences cyclical fluctuations, including periods of growth and recession, which can profoundly impact the cryptocurrency industry and Triskel’s financial performance. Economic downturns typically result in reduced consumer spending, diminished demand for digital assets, and increased financial uncertainty, posing challenges to revenue generation and user engagement efforts. Moreover, economic cycles influence investor sentiment and market dynamics, necessitating adaptive strategies to mitigate risks and capitalize on emerging opportunities.",
  },
  {
    heading:
      "13.3. Risks related to geopolitical events and global economic conditions",
    description:
      "Geopolitical events, such as trade disputes, political instability, and global crises, have far-reaching implications for the cryptocurrency industry and Triskel’s operational resilience. These events can disrupt global economic stability, undermine investor confidence, and trigger market volatility, posing challenges to platform sustainability and user trust. Furthermore, shifts in global economic conditions, including changes in interest rates, currency exchange rates, and inflation levels, can impact user behavior and market sentiment, necessitating proactive risk management measures to mitigate adverse effects.",
  },
];

const RiskDisclosure = [
  "The risks outlined in this disclosure are not exhaustive, and additional risks may emerge over time that are currently undisclosed or unrecognized. Furthermore, these risks may not apply to all stakeholders or in all jurisdictions. Stakeholders are encouraged to conduct their own research, analysis, and seek independent professional advice. It is important to understand that the cryptocurrency and blockchain sectors are continually evolving and are subject to regulatory, economic, and technological changes that may impact the functionality of our platform, as well as the value and liquidity of any tokens issued or exchanged on our platform. Consequently, unforeseen risks may arise periodically.",
  <>
    {" "}
    <strong className="strong">
      WE STRONGLY ENCOURAGE ALL STAKEHOLDERS TO EXERCISE CAUTION AND DUE
      DILIGENCE WHEN USING OUR PLATFORM AND ENGAGING IN CRYPTOCURRENCY OR
      BLOCKCHAIN-RELATED ACTIVITIES. IT IS EACH STAKEHOLDER'S RESPONSIBILITY TO
      UNDERSTAND THE RISKS ASSOCIATED WITH SUCH ACTIVITIES AND MAKE INFORMED
      DECISIONS BASED ON THEIR INDIVIDUAL RISK TOLERANCE AND CIRCUMSTANCES.
    </strong>{" "}
  </>,
  "Therefore, stakeholders are advised to thoroughly assess the risks before using our platform, investing in any tokens traded or issued on our platform, or participating in other cryptocurrency-related activities. It is important to recognize that these risks may lead to financial losses, and we do not provide any guarantees or warranties regarding the performance or value of any tokens or assets traded on our platform. We are committed to providing a secure and reliable platform for our users to conduct transactions and will make reasonable efforts to mitigate the associated risks. However, stakeholders should acknowledge that the risks outlined in this note are not exhaustive, and there may be other risks in the future that are currently undisclosed or unrecognized.",
];

const riskAcknowledgment = [
  {
    heading: "15.1 Acknowledgement of risks",
    description: (
      <>
        {" "}
        <strong className="strong">
          {" "}
          YOU ACKNOWLEDGE THAT INVESTING OR PARTICIPATING IN THE TRISKEL
          PLATFORMS INVOLVES INHERENT RISKS. THESE RISKS MAY INCLUDE THOSE
          RELATED TO CRYPTOCURRENCIES, NON-CUSTODIAL WALLETS, CYBERSECURITY,
          VOLATILITY, MARKET VARIABILITY, OVERSIGHT, LIQUIDITY, SMART CONTRACTS,
          THIRD-PARTY PROVIDERS, AND GENERAL BUSINESS RISKS. YOU AGREE AND
          ACKNOWLEDGE THAT YOU HAVE CAREFULLY CONSIDERED THESE RISKS BEFORE
          MAKING ANY DECISIONS REGARDING YOUR INVOLVEMENT IN THE PLATFORMS.
        </strong>{" "}
      </>
    ),
  },
  {
    heading: "15.2 Importance of making informed decisions",
    description:
      "It is crucial for stakeholders to have a thorough understanding of the potential risks associated with investing or participating in Triskel, enabling them to make well-informed decisions aligned with their risk tolerance levels. Triskel acknowledges the importance of this understanding and is committed to providing transparent and comprehensive information about the potential risks inherent in its operations. This includes risks related to cryptocurrencies, third-party providers, and general business risks. By carefully reviewing and understanding this information, stakeholders can make informed decisions regarding their engagement with the platform. This involves evaluating their risk tolerance, analyzing potential benefits and drawbacks, and considering any mitigating measures or strategies. Informed decision-making is particularly important within the rapidly evolving blockchain industry that underpins Triskel. These advanced technologies introduce unique risks that may not be widely understood by stakeholders. Therefore, it is essential for stakeholders to take the time to familiarize themselves with these risks and seek advice from trusted advisors as necessary.",
  },
  {
    heading: "15.3 Commitment to providing a safe and secure platform",
    description:
      "Triskel is dedicated to providing a safe and secure platform for users to conduct transactions with ease. This commitment involves implementing robust security measures, regularly monitoring and updating the platform's technology, and complying with applicable laws and regulations. Triskel recognizes the importance of maintaining users' trust and confidence and will continue to prioritize their safety and security.",
  },
];

const RiskDisclaimer = () => {
  const riskDisclosure = riskDisclosureNote.map((item) => <p>{item}</p>);
  const intro = introduction.map((item) => <p>{item}</p>);
  const genralriskMangement = genralRisk.map((item) => <p>{item}</p>);
  const accociatedCurrencies = accociatedWithCurrencies.map((item) => (
    <p>{item}</p>
  ));
  const wallets = wallet.map((item) => (
    <>
      <h6>{item.heading}</h6>
      <p>{item.description}</p>
    </>
  ));
  const cyberSecurity = cyberSecurityRisk.map((item) => <p>{item}</p>);
  const financialInstrument = financialInstrumentRisks.map((item, index) => (
    <p key={index}>{item}</p>
  ));
  const liquidity = liquidityRisk.map((item) => <p>{item}</p>);
  const smart = smartContect.map((item) => <p>{item}</p>);

  const thirdPartyAndCounterparty = thirdPartyAndCounterpartyRisks.map(
    (item, index) => (
      <div key={index}>
        <h6>{item.heading}</h6>
        <p>{item.description}</p>
      </div>
    )
  );

  const legalAndRegulatoryRisk = legalAndRegulatoryRisks.map((item, index) => (
    <div key={index}>
      <h6>{item.heading}</h6>
      <p>{item.description}</p>
    </div>
  ));

  const softwareAndHardwareRisk = softwareAndHardwareRisks.map(
    (item, index) => (
      <div key={index}>
        <h6>{item.heading}</h6>
        <p>{item.description}</p>
      </div>
    )
  );

  const businessAndCompetitionRisk = businessAndCompetitionRisks.map(
    (item, index) => (
      <div key={index}>
        <h6>{item.heading}</h6>
        <p>{item.description}</p>
      </div>
    )
  );

  const economicAndGeopoliticalRisk = economicAndGeopoliticalRisks.map(
    (item, index) => (
      <div key={index}>
        <h6>{item.heading}</h6>
        <p>{item.description}</p>
      </div>
    )
  );

  const Disclosure = RiskDisclosure.map((item) => <p>{item}</p>);

  const RiskAcknowledgment = riskAcknowledgment.map((item, index) => (
    <div key={index}>
      <h6>{item.heading}</h6>
      <p>{item.description}</p>
    </div>
  ));

  return (
    <>
      <div className="riskDisclaimer">
        <PageTitle title="Risk Disclaimer" />
        <h2 style={{ color: "white" }}>Risk Disclaimer</h2>
        {riskDisclosure}
        <section>
          <h4> INTRODUCTION</h4>
          {intro}
        </section>
        <section id="riskAssociated">
          <h4>GENERAL RISK</h4>
          {genralriskMangement}
        </section>
        <section id="riskAssociated">
          <h4>RISKS ASSOCIATED WITH CRYPTOCURRENCIES</h4>
          {accociatedCurrencies}
        </section>
        <section id="nonCustoDialWallet">
          <h4>RISK ASSOCIATED WITH NON-CUSTODIAL WALLETS</h4>
          {wallets}
          <p>
            By using a non-custodial wallet, you acknowledge and accept these
            risks and understand that there is no recourse for lost or stolen
            cryptocurrency due to the nature of non-custodial wallet security.
          </p>
        </section>
        <section id="cyberSecurityRisk">
          <h4>CYBER SECURITY RISKS</h4>
          {cyberSecurity}
        </section>
        <section id="oversightRisk">
          <h4>
            RISK OF VOLATILITY, MARKET VARIABILITY, AND THE IMPORTANCE OF
            OVERSIGHT RISK
          </h4>
          {financialInstrument}
        </section>
        <section id="liquidityRisk">
          <h4>LIQUIDITY RISK</h4>
          {liquidity}
        </section>
        <section id="smartContact">
          <h4>SMART CONTRACT RISK</h4>
          {smart}
        </section>
        <section id="thirdPartRisk">
          <h4>THIRD-PARTY RISKS</h4>
          {thirdPartyAndCounterparty}
        </section>
        <section id="ragulatoryRisk">
          <h4>LEGAL AND REGULATORY RISKS</h4>
          {legalAndRegulatoryRisk}
        </section>
        <section id="technicalRisk">
          <h4>Technical Risks</h4>
          {softwareAndHardwareRisk}
        </section>
        <section id="pureBusiness">
          <h4>PURE BUSINESS RISKS</h4>
          {businessAndCompetitionRisk}
        </section>
        <section id="economicRisk">
          <h4>ECONOMIC RISKS</h4>
          {economicAndGeopoliticalRisk}
        </section>
        <section id="disclaimer">
          <h4>DISCLAIMER</h4>
          {Disclosure}
        </section>
        <section id="consclusion">
          <h4>CONCLUSION</h4>
          {RiskAcknowledgment}
        </section>
      </div>
    </>
  );
};

export default RiskDisclaimer;
