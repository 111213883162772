import React from "react";
import { StoreImages } from "../../../../assets/StoreAsset/StoreImages";
import "./Token.scss"
function Token() {
  const {tstutility,tututility,tokenright} = StoreImages
  return (
    <div className="token"id="token">
      <div className="token_data container">
        <div className="token_data_left ">
          <p className="greentext">Triskel Tokens</p>
          <h2>Secure Your Place in the Decentralized Economy</h2>
          <div className="utilityData">
            <div className="utilityData_left">
              <img src={tututility} alt="imgs" />
              <div>
                <h4>TUT (Triskel Utility Token)</h4>
                <p>
                  Our ERC-20 utility token for efficient transactions and
                  seamless accessibility to various DeFi services.
                </p>
              </div>
            </div>
            <div className="utilityData_left">
              <img src={tstutility} alt="imgs" />
              <div>
                <h4>TST (Triskel Security Token)</h4>
                <p>
                Our ERC-3643 security token for asset tokenization and adherence to security regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="token_data_right">
          <img src={tokenright}alt="token"/>
        </div>
      </div>
    </div>
  );
}

export default Token;
