import React, { useState, useEffect } from "react";
import {
  ImportOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { message, Upload } from "antd";
import "./Uploader.scss";

// Validate before upload
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 1;
  if (!isLt2M) {
    message.error("Image must be smaller than 1MB!");
    return false;
  }
  return true;
};

// Common Upload Component
const ImageUploader = ({
  uploadStyle = "picture-card",
  handleUploadChange,
  previewImage, // New prop for initial image URL
  isDisabled,
  text,
  error,
  label,
}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(previewImage);

  useEffect(() => {
    if (previewImage) {
      setImageUrl(previewImage);
    }
  }, [previewImage]);

  const handleChange = async (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const objectUrl = URL.createObjectURL(info.file.originFileObj);
      setLoading(false);
      setImageUrl(objectUrl);

      // Call the passed handleUploadChange function to handle the file upload
      await handleUploadChange(info);
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload {text}
      </div>
    </button>
  );

  return (
    <div className="uploader">
      <label>
        {label}
        <span style={{ color: "red" }}>*</span>
      </label>

      <Upload
        name="image"
        listType={uploadStyle}
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={({ file, onSuccess }) => {
          setTimeout(() => {
            onSuccess("ok");
          }, 0);
        }}
        onChange={handleChange}
        disabled={isDisabled}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: "100%",
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
      {error && <div style={{ color: "red", marginTop: 8 }}>{error}</div>}
    </div>
  );
};

export default ImageUploader;
